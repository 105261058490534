import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { ReactComponent as LogoBlack } from '../assets/images/velv-black.svg';
import MainContext from "../common/MainContext";
import Button from "../components/Button";
import FooterLayout from "../components/layouts/FooterLayout";
import TextInput from "../components/TextInput";
import { auth } from "../firebase";
import typo from "../typography.module.css";
import style from "./Login.module.css";
import { ButtonStatus } from "../common/constants";

const ChangePassword = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const context = useContext(MainContext)
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [error, setError] = useState(null)
    const [savingStatus, setSavingStatus] = useState(null)

    useEffect(() => {
        if (context.user?.force_change_password === false) {
            navigate("/")
        }
    }, [context])

    const handlePasswordReset = async () => {
        setSavingStatus(ButtonStatus.Loading)
        try {
            const response = await api.patch(`/teacher/`, { password })
            context.setUser((u) => {
                u.force_change_password = false
                return { ...u }
            })
            setSavingStatus(ButtonStatus.Success)
            navigate("/")
        }
        catch (error) {
            setSavingStatus(ButtonStatus.Error)
            setError({ code: -1, message: error.message })
        }
    }

    const onPasswordChange = (value) => {
        setPassword(value)
        setError(checkPasswords() ? null : { code: -1, message: t('errors.passwordNotEquals') })
    }

    const onRepeatPasswordChange = (value) => {
        setRepeatPassword(value)
        setError(checkPasswords() ? null : { code: -1, message: t('errors.passwordNotEquals') })
    }

    const checkPasswords = () => {
        return (password.length > 0 && repeatPassword.length > 0) ? password === repeatPassword : true
    }

    const goToLogin = async () => {
        await auth.signOut()
        context.setUser(null)
        setTimeout(() => {
            navigate("/login")
        }, 200)
    }

    return (
        <FooterLayout>
            <div className={style.container}>
                <div className={style.columnLeft}>

                    <div className={style.innerLeft}>
                        <div className={style.logoContainer}>
                            <LogoBlack className={style.logo} />
                        </div>
                        <div className={style.spacer}></div>
                        <div className={[typo.title, style.title].join(' ')}>{t('resetPassword.resetPassword')}</div>
                        <div className={style.formContainer}>
                            <TextInput type="password" placeholder={t('resetPassword.password')} onKeyUp={onPasswordChange} autocomplete="new-password"></TextInput>
                            <TextInput type="password" placeholder={t('resetPassword.repeatPassword')} onKeyUp={onRepeatPasswordChange} autocomplete="new-password"></TextInput>
                            <div className={style.resetPassword}>

                                <div className={style.error}>
                                    {error &&
                                        <span className={typo.caption}>
                                            {error.message}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>

                        <Button status={savingStatus} onClick={handlePasswordReset} fullWidth>{t('resetPassword.save').toUpperCase()}</Button>
                        <div className={style.spacer}></div>
                        <div className={style.registerContainer}>
                            <Button appearance="text" onClick={goToLogin}>{t('resetPassword.backToLogin')}</Button>
                        </div>


                    </div>
                </div>
                <div className={style.columnRight}>
                    <div className={style.innerRight}>
                        <div className={style.redCircle}></div>
                        <div className={[style.rightTitle, typo.title].join(' ')}>{t('login.contentTitle')}</div>
                        <div className={[typo.body, style.rightBody].join(' ')}>{t('login.contentSubtitle')}</div>
                        <div className={style.mockup}></div>
                    </div>
                </div>
            </div>
        </FooterLayout>
    )
}

export default ChangePassword
