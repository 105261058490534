import { useDaily } from "@daily-co/daily-react"
import { useEffect, useState } from "react"
import api from "../../api"
import { ReactComponent as BackgroundEffectsIcon } from "../../assets/images/icons/ic-background-effects.svg"
import { Each } from "../../common/Each"
import typo from "../../typography.module.css"
import Button from "../Button"
import AlertDialog from "../dialogs/AlertDialog"
import Slider from "../Slider"
import Toggle from "../Toggle"
import styles from "./DailySettings.module.css"

const DailySettings = ({ open, roomName, sideBySide, onLessonEnding = () => {} }) => {

    const call = useDaily()
    const [roomInfo, setRoomInfo] = useState(null)
    const [backgroundBlur, setBackgroundBlur] = useState(false)
    const [blurStrength, setBlurStrength] = useState(50)
    const [backgroundImageSet, setBackgroundImageSet] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState(null)
    const [lockLoading, setLockLoading] = useState(false)
    const [endingCall, setEndingCall] = useState(false)
    const backgrounds = [
        {
            name: 'Conference Room',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/conference%20room.png'
        },
        {
            name: 'Valencia',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/valencia.png'
        },
        // {
        //     name: 'Orange Chair',
        //     url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/orange%20chair.png'
        // },
        {
            name: 'Living Room',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/living%20room.png'
        },
        {
            name: 'Trendy Office',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/trendy%20office.png'
        },
    ]

    useEffect(() => {
        if (roomName) {
            getRoomInfo(roomName)
        }
    }, [roomName])

    const getRoomInfo = async (roomName) => {
        try {
            let roomInfo = await api.get(`/teacher/rooms/${roomName}`)
            setRoomInfo(roomInfo)
        }
        catch (e) {
            console.error(e)
        }
    }

    const lockRoom = async (locked) => {
        setLockLoading(true)
        try {
            const body = { locked: locked }
            await api.put(`teacher/rooms/${roomName}/lock`, body)
            getRoomInfo(roomName)
        }
        catch (e) {
            console.error(e)
        }
        setLockLoading(false)
    }

    return (
        < div className={`${styles.settings} ${open ? styles.open : ''} ${sideBySide ? styles.sideBySide : ''}`} >
            {!sideBySide &&
                <>
                    <div className={styles.settingsHeader}>
                        <div className={typo.subtitle}>Impostazioni</div>
                    </div>
                    <div className={typo.body} style={{ opacity: 0.7, color: 'var(--background-color)' }}>Gestisci le impostazioni della stanza</div>
                </>
            }
            <div className={`${styles.settingsContent} ${sideBySide ? styles.sideBySide : ''}`}>
                <div className={styles.settingsRow}>
                    <div className={styles.settingsRowInner}>
                        <div className={styles.settingsTitle}>ID</div>
                        {roomName}
                    </div>
                </div>
                {roomInfo &&
                    <div className={styles.settingsRow}>
                        <div className={styles.settingsRowInner}>
                            <div className={styles.settingsTitle}>Bloccata</div>
                            <Toggle checked={roomInfo?.privacy === 'private'} loading={lockLoading} onChange={(value) => {
                                lockRoom(value)
                            }} />
                        </div>
                        <div className={styles.settingsRowInner}>
                            <div className={typo.caption}>
                                Quando la stanza è bloccata gli studenti dovranno essere sempre approvati manualmente da un docente o da un tutor.
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.settingsRow}>
                    <div className={styles.settingsRowInner}>
                        <div className={styles.settingsTitle}>Immagine Sfondo</div>
                        <Toggle checked={backgroundBlur} loading={false} onChange={(value) => {
                            if (value) {
                                setBackgroundImageSet(true)
                            } else {
                                call.updateInputSettings({
                                    video: {
                                        processor: {
                                            type: 'none',
                                        },
                                    },
                                });
                                setBackgroundImageSet(false)
                            }
                        }} />
                    </div>
                    {backgroundImageSet &&
                        <div className={styles.settingsRowInner}>
                            <div className={styles.backgrounds}>
                                <Each of={backgrounds} render={(bg, index) => {
                                    return (
                                        <img className={`${styles.background} ${bg.url === backgroundImage ? styles.selected : ''}`} src={bg.url} alt="" onClick={() => {
                                            setBackgroundImage(bg.url)
                                            call.updateInputSettings({
                                                video: {
                                                    processor: {
                                                        type: 'background-image',
                                                        config: {
                                                            source: bg.url,
                                                        },
                                                    },
                                                },
                                            });
                                        }} />
                                    )
                                }} />
                            </div>
                        </div>
                    }
                    <div className={styles.settingsRowInner}>
                        <div className={typo.caption}>
                            Imposta un'immagine come sfondo del tuo video.
                        </div>
                    </div>
                </div>
                <div className={styles.settingsRow}>
                    <div className={styles.settingsRowInner}>
                        <div className={styles.settingsTitle}>Sfocatura Sfondo</div>
                        <Toggle checked={backgroundBlur} loading={false} onChange={(value) => {
                            setBackgroundBlur(value)
                            call.updateInputSettings({
                                video: {
                                    processor: {
                                        type: value ? 'background-blur' : 'none',
                                        config: { strength: value ? (blurStrength > 0 ? blurStrength : 1) / 100 : 0 },
                                    },
                                },
                            });
                        }} />
                    </div>
                    {backgroundBlur &&
                        <div className={styles.settingsRowInner}>
                            <Slider icon={<BackgroundEffectsIcon />} level={blurStrength} onChange={(value) => {
                                setBlurStrength(value < 1 ? 1 : value)
                                call.updateInputSettings({
                                    video: {
                                        processor: {
                                            type: 'background-blur',
                                            config: { strength: (value < 1 ? 1 : value) / 100 },
                                        },
                                    },
                                });
                            }} />
                        </div>
                    }
                    <div className={styles.settingsRowInner}>
                        <div className={typo.caption}>
                            Sfoca lo sfondo del tuo video per una maggiore privacy.
                        </div>
                    </div>
                </div>
                <div className={styles.spacer}></div>
                <div className={styles.settingsRow}>
                    <Button
                        fullWidth
                        onClick={() => {
                            onLessonEnding()
                        }}
                        appearance="default"
                        inverse
                        accentColor={'#F43A3A'}
                        style={{ padding: '.5rem', fontSize: '1rem' }}>
                        TERMINA LEZIONE
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DailySettings;