import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Markdown from "react-markdown"
import { useNavigate } from "react-router-dom"
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { Each } from "../../common/Each"
import { formatDateV2, formatTimeV2 } from "../../utils"
import Button from "../Button"
import Skeleton from "../Skeleton"
import Tag from "../Tag"
import styles from "./ModuleLessonCard.module.css"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { EditionStatus } from "../../common/constants"
import Badge from "../Badge"
import AlertDialog from "../dialogs/AlertDialog"

const ModuleLessonCard = ({ lesson, onEdit = () => { }, loading = false }) => {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const [live, setLive] = useState(false)
    const navigate = useNavigate()
    const [openLesson, setOpenLesson] = useState(null)

    useEffect(() => {
        if (!lesson) {
            return
        }

        const checkLiveStatus = () => {
            let lessonStart = new Date(lesson.starts_at)
            let lessonEnd = new Date(lesson.ends_at)

            let now = new Date()
            let offset = now.getTimezoneOffset();

            lessonStart = new Date(lessonStart.getTime() - (offset * 60 * 1000));
            lessonEnd = new Date(lessonEnd.getTime() - (offset * 60 * 1000));

            if (now >= lessonStart && now < lessonEnd) {
                setLive(true)
            } else {
                setLive(false)
            }
        }

        checkLiveStatus()
    }, [lesson])

    return (
        <div className={styles.container}>
            {
                loading === true &&
                <>
                    <div className={styles.left} >
                        <Skeleton type="rect" width="144px" height="18px" borderRadius="8px" />
                        <Skeleton type="rect" width="96px" height="16px" borderRadius="8px" />
                        <Skeleton type="rect" width="100%" height="16px" borderRadius="8px" />

                        <div className={styles.tags}>
                            <Each
                                of={[130, 140, 86, 44, 112]}
                                render={(tagWidth) => (
                                    <Skeleton type="rect" width={`${tagWidth}px`} height="24px" borderRadius="8px" />
                                )}
                            />
                        </div>
                        <Skeleton type="rect" width="200px" height="40px" borderRadius="200px" />

                    </div>
                    <div className={styles.right}>
                        <Skeleton type="rect" width={width <= 540 ? "100%" : '230px'} height="130px" borderRadius="12px" />
                    </div>
                </>
            }

            {
                !loading &&
                <>
                    <div className={styles.left}>
                        <div className={styles.name}>
                            {lesson.name}
                            {lesson.video_url && <Badge name="REGISTRAZIONE DISPONIBILE" color="var(--sf-green)" />}
                        </div>
                        <div className={styles.time}><CalendarIcon /> {formatTimeV2(lesson.starts_at)}, {formatDateV2(lesson.starts_at)}</div>
                        {lesson.description &&
                            <div className={styles.description}>
                                <Markdown>
                                    {lesson.description}
                                </Markdown>
                            </div>
                        }
                        <div className={styles.tags}>
                            <Each
                                of={lesson.tags ?? []}
                                render={
                                    (tag) => (
                                        <Tag tag={tag} />
                                    )
                                }
                            />
                        </div>
                        {lesson.edition?.status !== EditionStatus.Locked &&
                            <div className={styles.actions}>
                                <Button
                                    fullWidth={width <= 540}
                                    style={{ padding: "0.7rem 1rem" }}
                                    inverse
                                    onClick={() => {
                                        onEdit(lesson)
                                    }}>
                                    <EditIcon /> {t(`lessons.edit`).toUpperCase()}
                                </Button>
                                <Button
                                    fullWidth={width <= 540}
                                    style={{ padding: "0.7rem 1rem" }}
                                    onClick={() => {
                                        if (new Date() > new Date(lesson?.ends_at)) {
                                            navigate(`/lessons/${lesson?.room_name}`)
                                        }
                                        else {
                                            setOpenLesson(lesson?.room_name)
                                        }
                                    }}
                                >
                                    {!live &&
                                        <>
                                            VEDI DETTAGLI
                                            <ArrowIcon className={styles.arrowIcon} />
                                        </>
                                    }
                                    {live &&
                                        <>
                                            VAI ALLA LEZIONE
                                            <ArrowIcon className={styles.arrowIcon} />
                                        </>
                                    }
                                </Button>
                            </div>
                        }
                    </div>
                    <div className={styles.right}>
                        <img className={styles.thumbnail} src={lesson?.thumbnail} alt="" />
                    </div>
                    <AlertDialog
                        open={openLesson}
                        onClose={() => setOpenLesson(null)}
                        title="Nuova Modalità Side by Side"
                        text="La modalità Side by Side ti permette di avere tutti i controlli della chiamata in una finestra separata da poter affiancare al tuo materiale durante la condivisione. Vuoi attivarla o continuare con la modalità classica ?"
                        actions={[
                            {
                                label: "SIDE BY SIDE",
                                onClick: () => {
                                    setOpenLesson(null)
                                    window.open(
                                        `/lessons/${openLesson.room_name}/side`, // URL della finestra da aprire
                                        "_blank", // Apre in una nuova finestra
                                        "width=250,height=900,toolbar=no,location=yes,status=no,menubar=no,scrollbars=no"
                                    );
                                }
                            },
                            {
                                label: "CLASSICA",
                                onClick: () => {
                                    setOpenLesson(null)
                                    navigate(`/lessons/${openLesson.room_name}`)
                                }
                            }
                        ]}
                    />
                </>
            }



        </div>
    )
}

export default ModuleLessonCard
