import { DailyVideo, useAudioLevelObserver, useDaily, useParticipantProperty, useScreenShare } from "@daily-co/daily-react";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as RemoveIcon } from "../../assets/images/icons/ic-person-delete.svg";
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg";
import { ReactComponent as ScreenStopIcon } from "../../assets/images/icons/ic-screenshare-stop.svg";
import { ReactComponent as ScreenShareIcon } from "../../assets/images/icons/ic-screenshare.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/ic-settings.svg";
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png";
import TooltipWrapper from "../TooltipWrapper";
import AudioSlider from "./AudioSlider";
import styles from "./DailyParticipant.module.css";

const DailyParticipant = ({
    session_id,
    isLocal = false,
    style,
    onClick = () => { },
    muted,
    appearance = 'default',
    onHandRaised = () => { },
    pinned = [],
    onPin = () => { },
    audioRef,
    onParticipantRemove = () => { },
    onlyVideo = false
}) => {

    const call = useDaily()
    const [participant, setParticipant] = useState(null)
    const participantProprerty = useParticipantProperty(session_id, ['audio', 'video', 'userData', 'tracks', 'user_name'])
    const { screens, isSharingScreen } = useScreenShare()
    const [settingsMode, setSettingsMode] = useState(false)
    const [screenshare, setScreenshare] = useState(false)
    const [audioLevel, setAudioLevel] = useState(1)
    const [remoteAudioLevel, setRemoteAudioLevel] = useState(0)

    useAudioLevelObserver(
        session_id,
        useCallback((volume) => {
            setRemoteAudioLevel(volume * 100)
        }, []),
        useCallback((error) => {
            console.error("Error in audio level observer:", error);
        }, [])
    );

    useEffect(() => {
        if (participantProprerty) {
            if(isLocal){
                console.log(participantProprerty[3]?.screenVideo)
            }
            let participant = {
                id: session_id,
                name: participantProprerty[4],
                audio: participantProprerty[0],
                video: participantProprerty[1],
                data: participantProprerty[2],
                tracks: participantProprerty[3],
                isLocal: isLocal
            }
            setParticipant(participant)
        }
    }, [participantProprerty])

    useEffect(() => {
        setScreenshare(participant?.tracks?.screenVideo?.state === 'playable')
        if (!participant?.audio) {
            setRemoteAudioLevel(0)
        }
        if (participant?.data?.handRaised) {
            onHandRaised(participant)
        }
    }, [participant]);

    useEffect(() => {
        if (audioRef && audioRef.current) {
            const participantAudio = audioRef.current.getAudioBySessionId(session_id)
            if (participantAudio) {
                participantAudio.volume = audioLevel
                participantAudio.muted = muted
            }
        }
    }, [audioRef, session_id, participant, audioLevel, muted])

    return (
        <>
            {participant && !participant.data?.is_chat && appearance === 'default' &&
                <div
                    className={styles.container}
                    style={{
                        ...style,
                        backgroundColor: participant?.data?.color ?? 'black',
                        boxShadow: !isLocal ? `inset 0px 0px ${remoteAudioLevel > 0 ? `${remoteAudioLevel * 2}px` : '0px'} ${remoteAudioLevel / 2}px rgba(109, 74, 255,0.9)` : 'none',
                        transition: 'all .3s ease'
                    }}
                    onClick={() => {
                        onClick(session_id)
                    }}>
                    {(participant.video || screenshare) &&
                        <>
                            <DailyVideo
                                type={screenshare && !onlyVideo ? 'screenVideo' : 'video'}
                                automirror={participant.isLocal}
                                sessionId={session_id}
                                style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%' }} />
                            {!isLocal && <div className={styles.audioGradient} style={{ boxShadow: `inset 0px 0px ${remoteAudioLevel > 0 ? `${remoteAudioLevel * 2}px` : '0px'} ${remoteAudioLevel / 2}px rgba(109, 74, 255,0.9)` }} />}
                        </>
                    }
                    {!settingsMode &&
                        <>
                            {participant.data &&
                                <div className={styles.name}>
                                    {participant.isLocal ? `${participant.data?.name} ${participant.data?.surname} (Tu)` : `${participant.data?.name} ${isSharingScreen ? participant.data?.surname?.[0] + '.' : participant.data?.surname}`}
                                    {/* {session_id} */}
                                </div>
                            }

                            <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                                {participant.audio ? <MicIcon /> : <MicOffIcon />}
                            </div>

                            {participant && !participant.isLocal &&
                                <div className={`${styles.settingsBadge} ${screens.find(s => s.session_id === session_id) ? styles.sharing : ''}`} onClick={(e) => {
                                    e.stopPropagation()
                                    setSettingsMode(!settingsMode)
                                }}>
                                    <SettingsIcon />
                                </div>
                            }

                            {participant && screens.find(s => s.session_id === session_id) &&
                                <TooltipWrapper text="Interrompi Condivisione">
                                    <div className={styles.screenShareBadge} onClick={() => {
                                        call.updateParticipant(session_id, { setScreenShare: false })
                                    }}>
                                        <ScreenShareIcon />
                                    </div>
                                </TooltipWrapper>
                            }

                            <div className={`${styles.pinBadge} ${pinned.includes(session_id) ? styles.pinned : ''}`} onClick={(e) => {
                                e.stopPropagation()
                                onPin(session_id)
                            }}>
                                <PinIcon />
                            </div>

                            {participant.data?.handRaised &&
                                <div className={styles.handRaised}>✋</div>
                            }
                        </>
                    }
                    {settingsMode &&
                        <div className={styles.overlay}>
                            <div className={styles.overlayHeader}>
                                <div className={styles.overlayName}>{participant.isLocal ? "Tu" : `${participant.data?.name} ${participant.data?.surname}`}</div>
                                <div className={styles.settingsBadge}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setSettingsMode(!settingsMode)
                                    }}>
                                    <CloseIcon />
                                </div>
                            </div>
                            <div className={styles.overlayContent}>
                                <div className={styles.settingsAction} onClick={(e) => {
                                    e.stopPropagation()
                                    call.updateParticipant(session_id, { setAudio: !participant.audio })
                                }}>
                                    {participant.audio ? <MicIcon style={{ color: 'var(--sf-purple)' }} /> : <MicOffIcon style={{ color: 'var(--sf-purple)' }} />}
                                </div>
                                <div className={styles.settingsAction} onClick={(e) => {
                                    e.stopPropagation()
                                    call.updateParticipant(session_id, { setVideo: !participant.video })
                                }}>
                                    {participant.video ? <CameraIcon style={{ color: 'var(--sf-purple)' }} /> : <CameraOffIcon style={{ color: 'var(--sf-purple)' }} />}
                                </div>
                                <div className={`${styles.settingsAction}`} onClick={(e) => {
                                    e.stopPropagation()
                                    onParticipantRemove(participant)
                                }}>
                                    <RemoveIcon style={{ color: 'var(--sf-yellow)' }} />
                                    {/* <div className={styles.actionLabel}>Rimuovi</div> */}
                                </div>
                            </div>
                            <AudioSlider level={audioLevel * 100} onChange={(value) => {
                                setAudioLevel(value / 100)
                            }} />
                        </div>
                    }
                </div>
            }
            {participant && !participant.data?.is_chat && appearance === 'list' &&
                <div className={styles.listContainer}>
                    <div className={styles.listUser}>
                        <img src={participant.data?.picture ?? UserPlaceholder} alt="" className={styles.listAvatar} />
                        <div className={styles.listName}>{`${participant.data?.surname} ${participant.data?.name}`}</div>
                    </div>
                    <div className={styles.listActions}>
                        <TooltipWrapper text={pinned.includes(session_id) ? "Rimuovi Fissato" : "Fissa"}>
                            <div className={styles.pinButton} onClick={() => {
                                onPin(session_id)
                            }}>

                                <PinIcon className={`${styles.pinIcon} ${pinned.includes(session_id) ? styles.pinned : ''}`} />

                            </div>
                        </TooltipWrapper>

                        {screens.find(s => s.session_id === session_id) &&
                            <TooltipWrapper text="Interrompi Condivisione">
                                <div className={styles.screenButton} onClick={() => {
                                    call.updateParticipant(session_id, { setScreenShare: false })
                                }}>
                                    <ScreenStopIcon />
                                </div>
                            </TooltipWrapper>
                        }

                        <TooltipWrapper text={participant.audio ? "Disattiva Microfono" : "Attiva Microfono"}>
                            <div className={styles.micButton} onClick={() => {
                                call.updateParticipant(session_id, { setAudio: !participant.audio })
                            }}>
                                {participant.audio ?
                                    <MicIcon /> : <MicOffIcon />
                                }
                            </div>
                        </TooltipWrapper>

                        <TooltipWrapper text={participant.video ? "Disattiva Videocamera" : "Attiva Videocamera"}>
                            <div className={styles.cameraButton} onClick={() => {
                                call.updateParticipant(session_id, { setVideo: !participant.video })
                            }}>
                                {participant.video ?
                                    <CameraIcon /> : <CameraOffIcon />
                                }
                            </div>
                        </TooltipWrapper>
                    </div>

                </div>
            }

        </>
    )

}

export default DailyParticipant