import styles from "./Badge.module.css"

const Badge = ({name, color}) => {

    return (
        <div className={styles.container} style={{'--badge-color' : color}}>
            {name}
        </div>
    )

}

export default Badge