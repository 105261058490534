import { DailyAudio, DailyAudioTrack, DailyVideo, useDaily, useDevices, useLocalSessionId, useMediaTrack, useMeetingState, useParticipantProperty } from "@daily-co/daily-react"
import { useContext, useEffect, useRef, useState } from "react"
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg"
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg"
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg"
import { ReactComponent as SpeakerIcon } from "../../assets/images/icons/ic-speaker.svg"
import { ReactComponent as SpeakerIcon0 } from "../../assets/images/icons/ic-speaker0.svg"
import { Each } from "../../common/Each"
import MainContext from "../../common/MainContext"
import useSpeechDetection from "../../common/hooks/useSpeechDetection"
import typo from "../../typography.module.css"
import DailyControl from "./DailyControl"
import DailySpinner from "./DailySpinner"
import styles from "./DailyTest.module.css"
import Button from "../Button"
import api from "../../api"
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from "react-i18next"
import { capitalize } from "../../utils"
import Spinner from "../Spinner"

const DailyTest = ({ lesson_id, onTestCompleted = () => { }, sideBySide = false }) => {

    const { t } = useTranslation()
    const call = useDaily()
    const context = useContext(MainContext)
    const localSessionId = useLocalSessionId()
    const devices = useDevices()
    const [loadingMic, setLoadingMic] = useState(null)
    const [loadingCam, setLoadingCam] = useState(null)
    const [loadingSpeaker, setLoadingSpeaker] = useState(null)
    const localParticipant = useParticipantProperty(localSessionId, ['audio', 'video', 'user_name', 'userData', 'audioTrack', 'tracks'])
    const audioTrack = useMediaTrack(localSessionId, 'audio')
    const audioLevel = useSpeechDetection(audioTrack.track)
    const [muted, setMuted] = useState(true)
    const [callQuality, setCallQuality] = useState(null)
    const [testStarted, setTestStarted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [camActivated, setCamActivated] = useState(false)
    const [micActivated, setMicActivated] = useState(false)
    const [speakersActivated, setSpeakersActivated] = useState(false)
    const meetingState = useMeetingState()
    const [showOverlay, setShowOverlay] = useState(true)

    const [steps, setSteps] = useState([
        { title: 'Attiva la videocamera', subtitle: 'Usa il bottone in basso, a seconda dei browser potrebbe volerci qualche secondo.', completed: false },
        { title: 'Attiva il microfono', subtitle: 'Se parli dovresti vedere l\'icona illuminarsi.', completed: false },
        { title: 'Attiva gli altoparlanti', subtitle: 'Solo per questo test dovresti riuscire a sentire te stesso.', completed: false },
        { title: 'Test di connessione', subtitle: 'Verifica lo stato della connessione.', completed: false },
    ])

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .catch(error => {
                console.error(error)
            })
    }, [])

    useEffect(() => {
        const pressedKeys = new Set();

        const handleKeyDown = (event) => {
            pressedKeys.add(event.key.toLowerCase());

            if (
                pressedKeys.has("shift") &&
                pressedKeys.has("s") &&
                pressedKeys.has("k")
            ) {
                console.log("Shift + S + K pressed!");
                onTestCompleted() // Chiama la funzione desiderata
            }
        };

        const handleKeyUp = (event) => {
            pressedKeys.delete(event.key.toLowerCase());
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    useEffect(() => {
        if (['new', 'loaded'].includes(meetingState)) {
            call.setLocalVideo(false)
            call.setLocalAudio(false)
        }
    }, [meetingState])

    useEffect(() => {
        console.log('camState', devices.camState, camActivated)
    }, [devices.camState, camActivated])

    useEffect(() => {
        if (devices.cameras.length > 0 && !devices.currentCam) {
            devices.setCamera(devices.cameras[0].device.deviceId)
        }
        if (devices.speakers.length > 0 && !devices.currentSpeaker) {
            devices.setSpeaker(devices.speakers[0].device.deviceId)

        }
        if (devices.microphones.length > 0 && !devices.currentMic) {
            devices.setMicrophone(devices.microphones[0].device.deviceId)
        }
    }, [devices.cameras, devices.speakers, devices.microphones])

    useEffect(() => {
        if (camActivated && localParticipant[1]) {
            let s = [...steps]
            s[0].completed = true
            setSteps(s)
            resetOverlay()
        }
        if (micActivated && localParticipant[0]) {
            let s = [...steps]
            s[1].completed = true
            setSteps(s)
            resetOverlay()
        }
        if (speakersActivated) {
            let s = [...steps]
            s[2].completed = true
            setSteps(s)
            resetOverlay()
        }
        if (callQuality) {
            let s = [...steps]
            s[3].completed = true
            setSteps(s)
            resetOverlay()
            saveTestResults(callQuality)
        }
    }, [camActivated, micActivated, speakersActivated, callQuality, localParticipant])

    async function checkPermissions(type) {
        try {
            if (!['camera', 'microphone'].includes(type)) {
                throw new Error("Wrong permission type.")
            }

            const permission = await navigator.permissions.query({ name: type });
            const isGranted = permission.state === 'granted';

            if (!isGranted) {
                await requestPermissions()
            }

        } catch (error) {
            console.error('Error checking permissions:', error);
            context.setError({ title: 'Permessi Videocamera e Microfono', message: 'Non hai concesso i permessi della videocamera o del microfono, prova a reimpostarli nelle impostazioni del browser.' })
        }
    }

    async function requestPermissions() {
        try {
            // Richiede l'accesso alla fotocamera e al microfono
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            console.log('Permessi concessi per fotocamera e microfono');
        } catch (error) {
            console.error('Permessi negati o errore nel richiedere i permessi:', error);
            throw error
        }
    }

    async function saveTestResults(qualityResult) {
        let body = {
            metrics: qualityResult,
            participant_id: localSessionId
        }
        try {
            await api.put(`/teacher/lessons/${lesson_id}/participant`, body)
        }
        catch (e) {
            console.error(e)
        }
    }

    function resetOverlay() {
        if (!callQuality && !loading) {
            setShowOverlay(false)
            setTimeout(() => {
                setShowOverlay(true)
            }, 1000)
        }

    }

    const overlayVariants = {
        visible: { opacity: 1, transition: { when: "beforeChildren", duration: 1, ease: 'easeIn' } },
        hidden: { opacity: 0, transition: { when: "afterChildren", duration: 1, ease: 'easeOut' } }
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.videoContainer}>
                    <DailyVideo
                        automirror={true}
                        type={'video'}
                        sessionId={localSessionId}
                        style={{ display: 'flex', objectFit: 'cover', margin: '0 auto', width: '100%', height: '100%', aspectRatio: '16 / 9', maxWidth: '100%' }} />
                </div>

                <AnimatePresence>
                    {showOverlay && !sideBySide &&
                        <motion.div initial="hidden" animate="visible" variants={overlayVariants} className={styles.overlay}>
                            {!testStarted &&
                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                    Per assicurare un corretto svolgimento della lezione, ti invitiamo a verificare il corretto funzionamento del microfono, della videocamera, degli altoparlanti nonchè la qualità della tua connessione.
                                    <Button appearance="text" onClick={() => {
                                        setTestStarted(true)
                                        resetOverlay()
                                    }}>inizia il test</Button>
                                </div>
                            }
                            {testStarted &&
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                                    {!loading &&
                                        <>
                                            <div className={typo.subtitle}
                                                style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                {((camActivated && devices.camState === 'idle') || (micActivated && devices.micState === 'idle')) &&
                                                    <Spinner color="white" />
                                                }
                                                {steps.find(step => !step.completed) ? steps.find(step => !step.completed)?.title : 'Completato!'}
                                            </div>
                                            <div className={typo.caption}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {steps.find(step => !step.completed) ? steps.find(step => !step.completed)?.subtitle : 'Ecco i risultati'}
                                            </div>
                                        </>
                                    }
                                    {steps.findIndex(s => !s.completed) === 3 && !loading &&
                                        <Button
                                            inverse
                                            fullWidth
                                            onClick={async () => {
                                                setLoading(true)
                                                const callQuality = await call.testCallQuality();
                                                setCallQuality({
                                                    quality: callQuality.result,
                                                    band: (callQuality.data?.avgAvailableOutgoingBitrate / 1_000_000).toFixed(2),
                                                    upload: (callQuality.data?.avgSendBitsPerSecond / 1_000_000).toFixed(2),
                                                    ping: Math.round((callQuality.data?.maxRoundTripTime * 1_000).toFixed(2)),
                                                    packet_loss: (callQuality.data?.avgSendPacketLoss * 100).toFixed(2),
                                                    error: callQuality.error
                                                })
                                                setLoading(false)
                                            }}>AVVIA</Button>
                                    }
                                    {loading &&
                                        <div className={typo.headline} style={{ display: 'flex', flexDirection: 'row', gap: '.25rem', color: 'var(--background-color)', alignItems: 'center' }}>
                                            <Spinner color={'white'} /> Eseguo il test di connessione (max 30s)
                                        </div>
                                    }
                                    {callQuality && !loading &&
                                        <>
                                            <div className={styles.results}>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Qualità</div>
                                                    <div className={styles.resultValue}>{capitalize(t(`network.${callQuality?.quality}`))}</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Banda</div>
                                                    <div className={styles.resultValue}>{callQuality?.band} Mbps</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Upload</div>
                                                    <div className={styles.resultValue}>{callQuality?.upload} Mbps</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Ping</div>
                                                    <div className={styles.resultValue}>{callQuality?.ping} ms</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Packet Loss</div>
                                                    <div className={styles.resultValue}>{callQuality?.packet_loss}%</div>
                                                </div>
                                            </div>
                                            {callQuality?.quality === 'good' &&
                                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                                    La tua connessione è di buona qualità, puoi iniziare la lezione.
                                                </div>
                                            }
                                            {callQuality?.quality === 'warning' &&
                                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                                    La tua connessione è di qualità sufficiente, ti consigliamo comunque di verificare la tua connessione e ripetere il test.
                                                </div>
                                            }
                                            {callQuality?.quality === 'bad' &&
                                                <div className={typo.headline} style={{ color: 'var(--sf-red)', textAlign: 'center' }}>
                                                    La tua connessione è di scarsa qualità, verifica la tua connessione e ripeti il test per evitare problemi audio o video all'interno della lezione.
                                                </div>
                                            }
                                            {callQuality?.quality === 'failed' &&
                                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                                    Non è stato possibile completare il test di connessione, ti consigliamo di verificare la tua connessione.
                                                </div>
                                            }

                                            {['good', 'warning'].includes(callQuality?.quality) &&
                                                <div className={styles.actions}>
                                                    <Button appearance="text" onClick={async () => {
                                                        setLoading(true)
                                                        const callQuality = await call.testCallQuality();
                                                        setCallQuality({
                                                            quality: callQuality.result,
                                                            band: (callQuality.data?.avgAvailableOutgoingBitrate / 1_000_000).toFixed(2),
                                                            upload: (callQuality.data?.avgSendBitsPerSecond / 1_000_000).toFixed(2),
                                                            ping: Math.round((callQuality.data?.maxRoundTripTime * 1_000).toFixed(2)),
                                                            packet_loss: (callQuality.data?.avgSendPacketLoss * 100).toFixed(2),
                                                            error: callQuality.error
                                                        })
                                                        setLoading(false)
                                                    }}>ripeti il test</Button>
                                                    <Button inverse fullWidth onClick={async () => {
                                                        onTestCompleted()
                                                    }}>VAI ALLA LEZIONE</Button>

                                                </div>
                                            }
                                            {['bad', 'failed'].includes(callQuality?.quality) &&
                                                <div className={styles.actions}>
                                                    <Button appearance="text" onClick={async () => {
                                                        onTestCompleted()
                                                    }}>vai alla lezione</Button>
                                                    <Button inverse fullWidth onClick={async () => {
                                                        setLoading(true)
                                                        const callQuality = await call.testCallQuality();
                                                        setCallQuality({
                                                            quality: callQuality.result,
                                                            band: (callQuality.data?.avgAvailableOutgoingBitrate / 1_000_000).toFixed(2),
                                                            upload: (callQuality.data?.avgSendBitsPerSecond / 1_000_000).toFixed(2),
                                                            ping: Math.round((callQuality.data?.maxRoundTripTime * 1_000).toFixed(2)),
                                                            packet_loss: (callQuality.data?.avgSendPacketLoss * 100).toFixed(2),
                                                            error: callQuality.error
                                                        })
                                                        setLoading(false)
                                                    }}>RIPETI IL TEST</Button>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            }
                        </motion.div>
                    }
                </AnimatePresence>

                <div className={styles.controls}>
                    <motion.div
                        initial={{ opacity: 0, x: '-1000%' }}
                        animate={{ opacity: (camActivated && (!localParticipant[0] || micActivated)) ? 1 : 0, x: camActivated && (!localParticipant[0] || micActivated) ? 0 : '-1000%' }}
                        transition={{ duration: 1.5, delay: .5, ease: 'easeInOut' }}
                        exit={{ opacity: 0 }}>
                        <DailyControl
                            id="microphones"
                            active={localParticipant[0]}
                            icons={{
                                normal: <MicOffIcon className={styles.toolIcon} />,
                                active: <MicIcon className={styles.toolIcon} />
                            }}
                            onClick={() => {
                                checkPermissions('microphone')
                                if (devices.cameras.length > 0) {
                                    call.setLocalAudio(!localParticipant[0])
                                    setMicActivated(true)
                                }
                                else {
                                    context.setError({ title: 'Nessun Microfono', message: 'Non è stata trovato alcun microfono. Assicurati che sia collegato e funzionante.' })
                                }
                            }}
                            hasOption={devices.microphones.length > 1}
                            level={localParticipant[0] ? audioLevel : 0}
                        >
                            <div className={styles.devices}>
                                <Each of={devices.microphones} render={(microphone) => (
                                    <div className={styles.device} onClick={async (e) => {
                                        e.stopPropagation()
                                        setLoadingMic(microphone.device.deviceId)
                                        await devices.setMicrophone(microphone.device.deviceId)
                                        setLoadingMic(null)
                                    }}>
                                        <div className={styles.deviceSelected}>
                                            {microphone.selected &&
                                                <CheckIcon style={{ width: '12px', height: '12px' }} />
                                            }
                                            {loadingMic === microphone.device.deviceId &&
                                                <DailySpinner />
                                            }
                                        </div>
                                        <div className={styles.deviceLabel}>
                                            {microphone.device.label}
                                        </div>
                                    </div>
                                )} />
                            </div>
                        </DailyControl>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: '-100%', scale: 5, visibility: 'hidden', zIndex: -1 }}
                        animate={{ opacity: testStarted ? 1 : 0, y: testStarted ? 0 : '-100%', scale: testStarted ? 1 : 5, visibility: testStarted ? 'visible' : 'hidden', zIndex: testStarted ? 0 : -1 }}
                        transition={{ duration: 2, delay: 1, ease: 'easeInOut' }}
                        exit={{ opacity: 0 }}>
                        <DailyControl
                            id="cameras"
                            active={localParticipant[1]}
                            icons={{
                                normal: <CameraOffIcon className={styles.toolIcon} />,
                                active: <CameraIcon className={styles.toolIcon} />
                            }}
                            onClick={() => {
                                checkPermissions('camera')
                                if (devices.cameras.length > 0) {
                                    call.setLocalVideo(!localParticipant[1])
                                    setCamActivated(true)
                                }
                                else {
                                    context.setError({ title: 'Nessuna Videocamera', message: 'Non è stata trovata alcuna videocamera. Assicurati che sia collegata e funzionante.' })
                                }
                            }}
                            hasOption={devices.cameras.length > 1}
                        >
                            <div className={styles.devices}>
                                <Each of={devices.cameras} render={(camera) => (
                                    <div className={styles.device} onClick={async (e) => {
                                        e.stopPropagation()
                                        setLoadingCam(camera.device.deviceId)
                                        await devices.setCamera(camera.device.deviceId)
                                        setLoadingCam(null)
                                    }}>
                                        <div className={styles.deviceSelected}>
                                            {camera.selected &&
                                                <CheckIcon style={{ width: '12px', height: '12px' }} />
                                            }
                                            {loadingCam === camera.device.deviceId &&
                                                <DailySpinner />
                                            }
                                        </div>
                                        <div className={styles.deviceLabel}>
                                            {camera.device.label}
                                        </div>
                                    </div>
                                )} />
                            </div>
                        </DailyControl>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: '1000%' }}
                        animate={{ opacity: micActivated ? 1 : 0, x: micActivated ? 0 : '1000%' }}
                        transition={{ duration: 1.5, delay: .5, ease: 'easeInOut' }}
                        exit={{ opacity: 0 }}>
                        <DailyControl
                            id="speakers"
                            active={!muted}
                            icons={{
                                normal: <SpeakerIcon0 className={styles.toolIcon} />,
                                active: <SpeakerIcon className={styles.toolIcon} style={{ color: 'var(--primary)' }} />
                            }}
                            onClick={() => {
                                setMuted(!muted)
                                setSpeakersActivated(true)
                            }}
                            hasOption={devices.speakers.length > 1}
                        >
                            <div className={styles.devices}>
                                <Each of={devices.speakers} render={(speaker) => (
                                    <div className={styles.device} onClick={async (e) => {
                                        e.stopPropagation()
                                        setLoadingSpeaker(speaker.device.deviceId)
                                        await devices.setSpeaker(speaker.device.deviceId)

                                        setLoadingSpeaker(null)
                                    }}>
                                        <div className={styles.deviceSelected}>
                                            {speaker.selected && !loadingSpeaker &&
                                                <CheckIcon style={{ width: '12px', height: '12px' }} />
                                            }
                                            {loadingSpeaker === speaker.device.deviceId &&
                                                <DailySpinner />
                                            }
                                        </div>
                                        <div className={styles.deviceLabel}>
                                            {speaker.device.label}
                                        </div>
                                    </div>
                                )} />
                            </div>
                        </DailyControl>
                    </motion.div>
                    {!muted &&
                        <DailyAudioTrack sessionId={localSessionId} />
                    }

                </div>
                <AnimatePresence>
                    {showOverlay && sideBySide &&
                        <motion.div initial="hidden" animate="visible" variants={overlayVariants} className={`${styles.overlay} ${styles.sideBySide}`}>
                            {!testStarted &&
                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                    Per assicurare un corretto svolgimento della lezione, ti invitiamo a verificare il corretto funzionamento del microfono, della videocamera, degli altoparlanti nonchè la qualità della tua connessione.
                                    <Button appearance="text" onClick={() => {
                                        setTestStarted(true)
                                        resetOverlay()
                                    }}>inizia il test</Button>
                                </div>
                            }
                            {testStarted &&
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                                    {!loading &&
                                        <>
                                            <div className={typo.subtitle}
                                                style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                {((camActivated && devices.camState === 'idle') || (micActivated && devices.micState === 'idle')) &&
                                                    <Spinner color="white" />
                                                }
                                                {steps.find(step => !step.completed) ? steps.find(step => !step.completed)?.title : 'Completato!'}
                                            </div>
                                            <div className={typo.caption}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {steps.find(step => !step.completed) ? steps.find(step => !step.completed)?.subtitle : 'Ecco i risultati'}
                                            </div>
                                        </>
                                    }
                                    {steps.findIndex(s => !s.completed) === 3 && !loading &&
                                        <Button
                                            inverse
                                            fullWidth
                                            onClick={async () => {
                                                setLoading(true)
                                                const callQuality = await call.testCallQuality();
                                                setCallQuality({
                                                    quality: callQuality.result,
                                                    band: (callQuality.data?.avgAvailableOutgoingBitrate / 1_000_000).toFixed(2),
                                                    upload: (callQuality.data?.avgSendBitsPerSecond / 1_000_000).toFixed(2),
                                                    ping: Math.round((callQuality.data?.maxRoundTripTime * 1_000).toFixed(2)),
                                                    packet_loss: (callQuality.data?.avgSendPacketLoss * 100).toFixed(2),
                                                    error: callQuality.error
                                                })
                                                setLoading(false)
                                            }}>AVVIA</Button>
                                    }
                                    {loading &&
                                        <div className={typo.headline} style={{ display: 'flex', flexDirection: 'row', gap: '.25rem', color: 'var(--background-color)', alignItems: 'center' }}>
                                            <Spinner color={'white'} /> Eseguo il test di connessione (max 30s)
                                        </div>
                                    }
                                    {callQuality && !loading &&
                                        <>
                                            <div className={styles.results}>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Qualità</div>
                                                    <div className={styles.resultValue}>{capitalize(t(`network.${callQuality?.quality}`))}</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Banda</div>
                                                    <div className={styles.resultValue}>{callQuality?.band} Mbps</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Upload</div>
                                                    <div className={styles.resultValue}>{callQuality?.upload} Mbps</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Ping</div>
                                                    <div className={styles.resultValue}>{callQuality?.ping} ms</div>
                                                </div>
                                                <div className={styles.result}>
                                                    <div className={styles.resultTitle}>Packet Loss</div>
                                                    <div className={styles.resultValue}>{callQuality?.packet_loss}%</div>
                                                </div>
                                            </div>
                                            {callQuality?.quality === 'good' &&
                                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                                    La tua connessione è di buona qualità, puoi iniziare la lezione.
                                                </div>
                                            }
                                            {callQuality?.quality === 'warning' &&
                                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                                    La tua connessione è di qualità sufficiente, ti consigliamo comunque di verificare la tua connessione e ripetere il test.
                                                </div>
                                            }
                                            {callQuality?.quality === 'bad' &&
                                                <div className={typo.headline} style={{ color: 'var(--sf-red)', textAlign: 'center' }}>
                                                    La tua connessione è di scarsa qualità, verifica la tua connessione e ripeti il test per evitare problemi audio o video all'interno della lezione.
                                                </div>
                                            }
                                            {callQuality?.quality === 'failed' &&
                                                <div className={typo.headline} style={{ color: 'var(--background-color)', textAlign: 'center' }}>
                                                    Non è stato possibile completare il test di connessione, ti consigliamo di verificare la tua connessione.
                                                </div>
                                            }

                                            {['good', 'warning'].includes(callQuality?.quality) &&
                                                <div className={styles.actions}>
                                                    <Button appearance="text" onClick={async () => {
                                                        setLoading(true)
                                                        const callQuality = await call.testCallQuality();
                                                        setCallQuality({
                                                            quality: callQuality.result,
                                                            band: (callQuality.data?.avgAvailableOutgoingBitrate / 1_000_000).toFixed(2),
                                                            upload: (callQuality.data?.avgSendBitsPerSecond / 1_000_000).toFixed(2),
                                                            ping: Math.round((callQuality.data?.maxRoundTripTime * 1_000).toFixed(2)),
                                                            packet_loss: (callQuality.data?.avgSendPacketLoss * 100).toFixed(2),
                                                            error: callQuality.error
                                                        })
                                                        setLoading(false)
                                                    }}>ripeti il test</Button>
                                                    <Button inverse fullWidth onClick={async () => {
                                                        onTestCompleted()
                                                    }}>VAI ALLA LEZIONE</Button>

                                                </div>
                                            }
                                            {['bad', 'failed'].includes(callQuality?.quality) &&
                                                <div className={styles.actions}>
                                                    <Button appearance="text" onClick={async () => {
                                                        onTestCompleted()
                                                    }}>vai alla lezione</Button>
                                                    <Button inverse fullWidth onClick={async () => {
                                                        setLoading(true)
                                                        const callQuality = await call.testCallQuality();
                                                        setCallQuality({
                                                            quality: callQuality.result,
                                                            band: (callQuality.data?.avgAvailableOutgoingBitrate / 1_000_000).toFixed(2),
                                                            upload: (callQuality.data?.avgSendBitsPerSecond / 1_000_000).toFixed(2),
                                                            ping: Math.round((callQuality.data?.maxRoundTripTime * 1_000).toFixed(2)),
                                                            packet_loss: (callQuality.data?.avgSendPacketLoss * 100).toFixed(2),
                                                            error: callQuality.error
                                                        })
                                                        setLoading(false)
                                                    }}>RIPETI IL TEST</Button>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            }
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </>
    )

}

export default DailyTest