import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { useEffect, useRef, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import api from './api';
import MainContext from "./common/MainContext";
import useWindowDimensions from './common/hooks/useWindowDimensions';
import "./common/i18n";
import ErrorBanner from './components/ErrorBanner';
import NotificationBanner from './components/NotificationBanner';
import ProtectedRoute from './components/ProtectedRoute';
import LoginDialog from './components/dialogs/LoginDialog';
import NotificationPermissionDialog from './components/dialogs/NotificationPermissionDialog';
import SideNav from './components/layouts/SideNav';
import ThemeProvider from "./components/layouts/ThemeProvider";
import { ModulePages } from './enums';
import app, { auth } from "./firebase";
import ActionHandler from './pages/ActionHandler';
import ChangePassword from './pages/ChangePassword';
import Courses from './pages/Courses';
import Dashboard from "./pages/Dashboard";
import Exercise from './pages/Exercise';
import Exercises from './pages/Exercises';
import Lesson from './pages/Lesson';
import Lessons from './pages/Lessons';
import Login from './pages/Login';
import Materials from './pages/Materials';
import Messages from './pages/Messages';
import ModuleDrawer from './pages/ModuleDrawer';
import Notifications from "./pages/Notifications";
import PageNotFound from './pages/PageNotFound';
import Profile from './pages/Profile';
import TestResult from './pages/TestResult';
import Tests from './pages/Tests';
import "./themes.css";
import WebSocketClient from './websocket';
import DailyChatPopup from './components/daily/DailyChatPopup';
import LessonSide from './pages/LessonSide';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  const { width } = useWindowDimensions()
  const [user, setUser] = useState(null)
  const [dropdown, setDropdown] = useState(null)
  const [loginDialog, setLoginDialog] = useState(null)
  const [sidenav, setSideNav] = useState(true)
  const [notificationPermissions, setNotificationPermissions] = useState(false)
  const [notificationBanner, setNotificationBanner] = useState(null)
  const [notifications, setNotifications] = useState(null)
  const [unreadMessages, _setUnreadMessages] = useState(0)
  const [whiteboard, setWhiteboard] = useState({ show: false, persistenceKey: null, pdf: null })
  const [websocket, setWebsocket] = useState(null)
  const [error, setError] = useState(null)
  const [sideNavOpen, setSideNavOpen] = useState(width >= 768)

  const setUnreadMessages = data => {
    unreadMessageRef.current = data
    _setUnreadMessages(data)
  }
  const scrollRef = useRef(null)

  const context = {
    user: user,
    setUser: setUser,
    isLogging: null,
    dropdown: dropdown,
    setDropdown: setDropdown,
    loginDialog: loginDialog,
    setLoginDialog: setLoginDialog,
    sidenav: sidenav,
    setSideNav: setSideNav,
    notificationPermissions: notificationPermissions,
    setNotificationPermissions: setNotificationPermissions,
    notificationBanner: notificationBanner,
    setNotificationBanner: setNotificationBanner,
    notifications: notifications,
    setNotifications: setNotifications,
    unreadMessages: unreadMessages,
    setUnreadMessages: setUnreadMessages,
    whiteboard: whiteboard,
    setWhiteboard: setWhiteboard,
    scrollRef,
    error: error,
    setError: setError,
    sideNavOpen: sideNavOpen,
    setSideNavOpen: setSideNavOpen
  }


  const unreadMessageRef = useRef(context.unreadMessages)

  useEffect(() => {

    if (window.location.hostname === "teachers-beta.velv.academy") {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex, nofollow";
      document.head.appendChild(metaTag);
    }

    //Gestisce le notifiche push ricevute con app aperta.
    isSupported().then((supported) => {
      if (supported) {
        let messaging = getMessaging(app)
        if (messaging) {
          onMessage(messaging, (payload) => {
            setNotificationBanner({ title: payload.notification.title, body: payload.notification.body, image: payload.notification.image })
          })
        }
      }
    })

    auth.authStateReady().then(() => {
      checkAuthUser()
    })

    auth.onAuthStateChanged((user) => {
      checkAuthUser(user)
    });

    document.addEventListener('api-error', handleError);

    return () => {
      document.removeEventListener('api-error', handleError)
    }

  }, []);

  const handleError = (event) => {
    context.setError({ title: event.detail.message, message: event.detail.detail })
  }

  const checkAuthUser = async (user) => {
    //Se l'utente è loggato su firebase
    user = user ?? auth.currentUser

    if (user) {
      // //Ottiene le info dell'utente dal backend.
      const getUser = async () => {
        try {
          let u = await api.get("/teacher/")
          if (u) {
            context.setUser(u)
            context.setLoginDialog(false)
          }
        }
        catch (e) {
          console.error(e)
          await auth.signOut()
          context.setUser(null)
        }
      }
      if (!context.isLogging) {
        getUser()
      }
    }
  }

  /**
   * Quando cambia l'utente nel context
   */
  useEffect(() => {
    if (context.user) {
      isSupported().then((supported) => {
        if (supported) {
          if (Notification.permission === 'default' && localStorage.getItem('notifications') !== 'denied') {
            context.setNotificationPermissions(true)
          }
          if (Notification.permission === 'granted') {
            localStorage.setItem('notifications', 'granted')
            getNotificationsToken()
          }
        }
      })

      // //Elimina l'utente anonimo, ottieni il carrello, le notifiche e inizializza la connessione websocket per i messaggi
      getNotifications()
      if (!websocket) {
        const socket = new WebSocketClient('messaging', handleReceivedMessage)
        socket.init()
        setWebsocket(socket)
      }
    }

    return () => {
      // Chiudi la connessione WebSocket quando il componente viene smontato
      if (websocket) {
        websocket.close()
      }
    };
  }, [context.user])

  //Handler per i messaggi del websocket.
  const handleReceivedMessage = (data) => {
    if (data.type === "unread") {
      setUnreadMessages(data.value)
    }
    if (data.type === "received") {
      setUnreadMessages(unreadMessageRef.current + 1)
      if (!window.location.pathname.includes("messages")) {
        context.setNotificationBanner({ title: data.sender_name, body: data.body, url: "/messages", image: data.sender_picture })
      }
    }
    document.dispatchEvent(new CustomEvent('message-received', { detail: data }));
  };

  const getNotifications = async () => {
    try {
      let notifications = await api.get("/teacher/notifications?page=1&per=4")
      context.setNotifications(notifications)
    }
    catch (e) {
      console.error(e)
    }
  }

  //Ottiene il token FCM e lo invia al backend.
  const getNotificationsToken = async () => {
    try {
      let supported = await isSupported()
      if (supported) {
        await navigator.serviceWorker.register('/firebase-messaging-sw.js')
        let messaging = getMessaging(app)
        if (messaging) {
          if (Notification.permission === "granted") {
            console.debug("Getting Token from firebase")
            try {
              let token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID })

              console.debug("Sending token to backend")
              await api.post("/teacher/tokens", { token: token })
            }
            catch (e) {
              console.error(e)
            }
          } else {
            console.debug("Notifications Permission Denied")
          }
        }
      }
    } catch (e) {
      console.error(e)
    }

  }

  return (
    <Router>
      <MainContext.Provider value={context}>
        <ThemeProvider theme={'starting-finance'}>
          {context &&
            <ErrorBoundary>
              <div className="container">
                {!window.location.pathname.includes('/chat') && !window.location.pathname.includes('/actions') && !window.location.pathname.includes('/side') &&
                  <SideNav onChange={(open) => { context.setSideNavOpen(open) }}></SideNav>
                }
                <LoginDialog open={context.loginDialog} onClose={() => { context.setLoginDialog(false) }} />
                <NotificationPermissionDialog open={context.notificationPermissions} onClose={() => {
                  context.setNotificationPermissions(false)
                  getNotificationsToken()
                }} />
                <NotificationBanner notification={context.notificationBanner} />
                <ErrorBanner error={context.error} />

                <div className='content'>
                  <div ref={scrollRef} />
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/actions" element={<ActionHandler />} />

                    <Route path="/" element={<ProtectedRoute component={<><Dashboard /></>} />} />
                    <Route path="/courses" element={<ProtectedRoute component={<><Courses /></>} />} />
                    <Route path="/courses/:moduleId" element={<ProtectedRoute component={<><ModuleDrawer /></>} />} />
                    <Route path="/courses/:moduleId/students" element={<ProtectedRoute component={<><ModuleDrawer defaultSelectedTab={ModulePages.Students} /></>} />} />
                    <Route path="/courses/:moduleId/lessons" element={<ProtectedRoute component={<><ModuleDrawer defaultSelectedTab={ModulePages.Lessons} /></>} />} />
                    <Route path="/courses/:moduleId/materials" element={<ProtectedRoute component={<><ModuleDrawer defaultSelectedTab={ModulePages.Materials} /></>} />} />
                    <Route path="/courses/:moduleId/exercises" element={<ProtectedRoute component={<><ModuleDrawer defaultSelectedTab={ModulePages.Exercises} /></>} />} />
                    <Route path="/courses/:moduleId/tests" element={<ProtectedRoute component={<><ModuleDrawer defaultSelectedTab={ModulePages.Tests} /></>} />} />
                    <Route path="/tests" element={<ProtectedRoute component={<><Tests /></>} />} />
                    <Route path="/tests/:testId" element={<ProtectedRoute component={<><TestResult /></>} />} />
                    <Route path="/lessons" element={<ProtectedRoute component={<><Lessons /></>} />} />
                    <Route path="/lessons/:roomName/side" element={<ProtectedRoute component={<><LessonSide /></>} />} />
                    <Route path="/lessons/:roomName/chat" element={<ProtectedRoute component={<><DailyChatPopup /></>} />} />
                    <Route path="/lessons/:roomName" element={<ProtectedRoute component={<><Lesson /></>} />} />
                    <Route path="/materials" element={<ProtectedRoute component={<><Materials /></>} />} />
                    <Route path="/exercises/" element={<ProtectedRoute component={<><Exercises /></>} />} />
                    <Route path="/exercises/:exerciseId" element={<ProtectedRoute component={<><Exercise /></>} />} />
                    <Route path="/notifications" element={<ProtectedRoute component={<><Notifications /></>} />} />
                    <Route path="/messages/" element={<ProtectedRoute component={<><Messages websocket={websocket} /></>} />} />
                    <Route path="/profile" element={<ProtectedRoute component={<><Profile /></>} />} />
                    <Route path="/changePassword" element={<ProtectedRoute component={<><ChangePassword /></>} />} />
                  </Routes>
                </div>
              </div>
            </ErrorBoundary>
          }
        </ThemeProvider>
      </MainContext.Provider>
    </Router>
  );
}

export default App;
