import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import StudentCard from "../components/cards/StudentCard";
import TextInput from "../components/TextInput";
import { ModulePages } from "../enums";
import styles from "./ModuleStudents.module.css";
import Skeleton from "../components/Skeleton";


const ModuleStudents = ({ onChangeTabValue = () => { }, prefetchedStudents = null }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { moduleId } = useParams()

  const [students, setStudents] = useState(prefetchedStudents)
  const [filteredStudents, setFilteredStudents] = useState([])
  const [studentsLoading, setStudentsLoading] = useState(false)

  useEffect(() => {
    const getStudents = async () => {
      try {
        setStudentsLoading(true)
        const students = await api.get(`/teacher/modules/${moduleId}/students`);
        setStudents(students)
      } catch (e) {
        console.error(e)
      } finally {
        setStudentsLoading(false)
      }
    }

    if (prefetchedStudents === null) {
      getStudents()
    }

    window.scrollTo({
      top: 0,
    })

    return () => {
      onChangeTabValue(ModulePages.Students, null)
    }

  }, [])

  useEffect(() => {
    if (students && students.length > 0) {
      onChangeTabValue(ModulePages.Students, students.length)
      setFilteredStudents(students)
    }
  }, [students])

  const onSearch = useCallback((text) => {
    if (students && students.length > 0) {
      if (text) {
        const filter = students.filter((s) => {
          const target = `${s.name}${s.surname}`.toLowerCase().replaceAll(" ", "")
          const query = text ? text.toLowerCase().replaceAll(" ", "") : text
          return target.includes(query)
        })
        setFilteredStudents(filter)
      } else {
        setFilteredStudents(students)
      }
    }
  }, [students])

  return (
    <div className={styles.container}>
      <TextInput
        onKeyUp={onSearch}
        type="search"
        placeholder={"Cerca..."}
        style={{
          backgroundColor: "var(--background-secondary-color)",
        }} />
      <div className={styles.students}>
        {
          studentsLoading &&
          <Each
            of={Array(10).fill("")}
            render={() => (
              <div className={styles.student}>
                {/* <Skeleton type="rect" width="240px" height="192px" borderRadius="12px" /> */}
              </div>
            )}
          />
        }
        {
          studentsLoading === false &&
          <Each
            of={filteredStudents.sort((a, b) => {
              if (a.surname < b.surname) return -1; // a viene prima di b
              if (a.surname > b.surname) return 1;  // a viene dopo b
              return 0; // a e b sono uguali
            })}
            render={(student) => (
              <div className={styles.student}>
                <StudentCard student={student} />
              </div>
            )}
          />
        }
      </div>
    </div>
  );
};

export default ModuleStudents;
