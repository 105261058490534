import { Each } from "../common/Each"
import styles from "./IconsTabSwitch.module.css"

const IconsTabSwitch = ({ tabs, activeTab, onTabChange = () => { }, showNames = true, style }) => {

    return (
        <div className={styles.container} style={style}>
            <Each of={tabs} render={(tab, index) => {
                return (
                    <div className={`${styles.tab} ${activeTab === index ? styles.active : ''}`}
                        key={index}
                        onClick={() => {
                            onTabChange(index)
                        }}>
                        {tab.icon}
                        {showNames && <div className={styles.name}>{tab.name}</div>}
                        {tab.badge > 0 && <div className={styles.badge}>{tab.badge}</div>}
                    </div>
                )
            }} />
        </div>
    )
}

export default IconsTabSwitch