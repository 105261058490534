import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./VideoPlayer.module.css"
import { ReactComponent as PlayIllustration } from "../assets/images/illustrations/il-play-button.svg";
import { ReactComponent as PlayIcon } from "../assets/images/icons/ic-play.svg";
import { ReactComponent as PauseIcon } from "../assets/images/icons/ic-pause.svg";
import { ReactComponent as FullScreenIcon } from "../assets/images/icons/ic-screen-maximize.svg";
import { ReactComponent as FullScreenOffIcon } from "../assets/images/icons/ic-screen-minimize.svg";
import { ReactComponent as ArrowCircularDashed } from "../assets/images/icons/ic-arrow-circular-dashed.svg";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { isMobile } from 'react-device-detect';

const VideoPlayer = ({ url, thumbnail, max, onProgress = () => { }, progressBlock = true }) => {

    const [maxProgress, setMaxProgress] = useState(0); // Punto massimo raggiunto
    const [currentProgress, setCurrentProgress] = useState(0); // Punto attuale
    const playerRef = useRef(null); // Riferimento al player
    const [fullScreen, setFullScreen] = useState(false)
    const fullScreenHandle = useFullScreenHandle()
    const [showBottomBar, setShowBottomBar] = useState(false)
    const [showResume, setShowResume] = useState(false)
    const [previewClicked, setPreviewClicked] = useState(false)
    const [isMobileFullScreen, setIsMobileFullScreen] = useState(false)
    const [playbackRate, setPlaybackRate] = useState(1)

    const playbackRates = [1, 1.25, 1.5, 1.75, 2];

    const togglePlaybackRate = () => {
        const currentRateIndex = playbackRates.indexOf(playbackRate);
        const nextRateIndex = (currentRateIndex + 1) % playbackRates.length;
        setPlaybackRate(playbackRates[nextRateIndex]);
    };

    const [enableClickToPlay, _setEnableClickToPlay] = useState(false)
    const enableClickToPlayRef = useRef(enableClickToPlay)
    const setEnableClickToPlay = data => {
        enableClickToPlayRef.current = data
        _setEnableClickToPlay(data)
    }

    const [playing, _setPlaying] = useState(true)
    const playingRef = useRef(playing)
    const setPlaying = data => {
        playingRef.current = data;
        _setPlaying(data)
    }
    const timeoutRef = useRef(null);

    const [showOverlay, setShowOverlay] = useState(false)
    const overlayTimeoutRef = useRef(null);

    useEffect(() => {
        if (max > maxProgress) {
            setMaxProgress(max)
        }
    }, [max])

    const resetOverlayTimeout = () => {
        // Se c'è un timeout in corso, cancellalo
        if (overlayTimeoutRef.current) {
            clearTimeout(overlayTimeoutRef.current);
        }

        // Imposta un nuovo timeout per nascondere la bottom bar dopo 5 secondi
        overlayTimeoutRef.current = setTimeout(() => {
            setShowOverlay(false);
        }, 500);
    };

    const resetTimeout = () => {
        // Se c'è un timeout in corso, cancellalo
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Imposta un nuovo timeout per nascondere la bottom bar dopo 5 secondi
        timeoutRef.current = setTimeout(() => {
            setShowBottomBar(false);
            setShowResume(false)
        }, 3000);
    };

    useEffect(() => {

        const onKeyDown = (event) => {
            if (event.key === ' ' && previewClicked) {
                event.preventDefault()
                if (playerRef.current) {
                    setShowBottomBar(true)
                    resetTimeout()
                }
                setPlaying(!playingRef.current)
            }
        }

        document.addEventListener('keydown', onKeyDown)

        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }

    }, [previewClicked])

    // Gestisce l'avanzamento del video
    const handleProgress = (state) => {
        onProgress(state)
        const { playedSeconds } = state;
        setCurrentProgress(playedSeconds);
        if (playedSeconds > maxProgress) {
            setMaxProgress(playedSeconds); // Aggiorna il punto massimo raggiunto
        }
    };

    // Gestisce il cambio manuale del punto nella barra
    const handleSeek = (event) => {
        const target = parseFloat(event.target.value);

        // Controlla se il seek è oltre il punto massimo raggiunto
        if (!progressBlock || target <= maxProgress) {
            setCurrentProgress(target); // Aggiorna lo stato locale
            playerRef.current.seekTo(target, 'seconds'); // Cambia il punto del video
        } else {
            console.warn(`Blocked! Cannot seek beyond ${maxProgress} seconds.`);
        }
    };

    const onFullScreenChange = (fullScreenState) => {
        setFullScreen(fullScreenState)
    }

    const toggleFullScreenChange = () => {
        if (isMobile) {
            if (isMobileFullScreen) {
                document.body.style.backgroundColor = '';
            }
            else {
                document.body.style.backgroundColor = 'black';
            }
            setIsMobileFullScreen(!isMobileFullScreen)
        }
        else {
            fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter()
            setFullScreen(!fullScreen)
        }
    }

    const handleClickPreview = (e) => {
        setPreviewClicked(true)
        setPlaying(true)
        setShowBottomBar(true)

        setTimeout(() => {
            setEnableClickToPlay(true);
        }, 100);
    }

    const handleResume = () => {
        if (playerRef && playerRef.current) {
            playerRef.current.seekTo(maxProgress, 'seconds');
        }
    }

    return (
        <div className={`${styles.container} ${isMobileFullScreen ? styles.mobileFullScreen : ''}`}
            onMouseEnter={() => {
                setShowBottomBar(true)
                if (maxProgress > 15 && maxProgress - currentProgress > 15) {
                    setShowResume(true)
                }
                resetTimeout()
            }}
            onMouseMove={() => {
                setShowBottomBar(true)
                if (maxProgress > 15 && maxProgress - currentProgress > 15) {
                    setShowResume(true)
                }
                resetTimeout()

            }}
            onMouseLeave={() => {
                resetTimeout()
            }}>
            <FullScreen handle={fullScreenHandle} onChange={onFullScreenChange} className={styles.fullscreen}>
                <ReactPlayer
                    onContextMenu={(e) => e.preventDefault()}
                    onClick={() => {
                        if (enableClickToPlayRef.current) {
                            setPlaying(!playingRef.current)
                            setShowOverlay(true)
                            resetOverlayTimeout()
                        }
                    }}
                    ref={playerRef}
                    url={url}
                    className={styles.player}
                    playing={playing}
                    width="100%"
                    height="100%"
                    light={thumbnail}
                    playIcon={<PlayIllustration />}
                    controls={false} // Disattiva i controlli di default
                    playbackRate={playbackRate}
                    onProgress={handleProgress} // Aggiorna il progresso
                    onClickPreview={handleClickPreview}
                    config={
                        {
                            youtube: {
                                playerVars: { showinfo: 0, modestbranding: 0, fs: 0, autoplay: 1 }
                            },
                            file: {
                                attributes: {
                                    playsInline: true,
                                    controlsList: "nodownload", // Disabilita il download
                                },
                            },
                        }
                    }
                />

                <div className={`${styles.playPauseOverlay} ${!showOverlay ? styles.hidden : ''}`}>
                    {playing &&
                        <PlayIcon className={styles.overlayIcon} />
                    }
                    {!playing &&
                        <PauseIcon className={styles.overlayIcon} />
                    }
                </div>

                {/* Barra di progresso personalizzata */}
                {previewClicked &&
                    <>
                        {/* {maxProgress > 15 && maxProgress - currentProgress > 15 && playerRef.current?.getDuration() - maxProgress > 45 &&
                            <div className={`${styles.resume} ${!showResume ? styles.hidden : ''}`} onClick={handleResume}>
                                <ArrowCircularDashed className={styles.resumeIcon} /> RIPRENDI
                            </div>
                        } */}
                        <div className={`${styles.bottomBar} ${!showBottomBar ? styles.hidden : ''}`}>
                            <div className={styles.progressWrapper}>
                                <input
                                    className={styles.progress}
                                    type="range"
                                    min="0"
                                    max={playerRef.current ? playerRef.current.getDuration() : 100}
                                    style={{
                                        '--progress': `${(currentProgress / (playerRef.current?.getDuration() || 100)) * 100}%`,
                                    }}
                                    step="0.1"
                                    value={currentProgress}
                                    onChange={handleSeek} // Cambia il punto del video
                                />

                                {/* {playerRef.current && (
                                    <div
                                        className={styles.progressMarker}
                                        style={{
                                            left: `${(maxProgress / playerRef.current?.getDuration()) * 100}%`,
                                        }}
                                    />
                                )} */}
                            </div>

                            <div className={styles.controls}>
                                <button className={styles.controlButton} onClick={() => setPlaying(!playing)}>
                                    {playing ? <PauseIcon className={styles.controlIcon} /> : <PlayIcon className={styles.controlIcon} />}
                                </button>
                                <div>{formatTime(currentProgress)} / {formatTime(playerRef.current ? playerRef.current.getDuration() : 0)}</div>

                                <div className={styles.rightControls}>
                                    <button className={styles.controlButton} onClick={togglePlaybackRate}>{playbackRate}x</button>
                                    <button className={styles.controlButton} onClick={toggleFullScreenChange}>
                                        {fullScreen ? <FullScreenOffIcon className={styles.controlIcon} /> : <FullScreenIcon className={styles.controlIcon} />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </FullScreen>
        </div>
    );
};

// Funzione di utilità per formattare i secondi in "min:sec"
const formatTime = (seconds) => {
    if (!seconds) return '00:00';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    } else {
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }
};

export default VideoPlayer;