import { capitalize, formatDateV2, formatTimeV2 } from "../../utils"
import Card from "./Card"
import styles from "./LessonCard.module.css"
import CourseBadge from "../CourseBadge"
import Button from "../Button"
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg"
import { useEffect, useState } from "react"
import LiveBadge from "../LiveBadge"
import { useNavigate } from "react-router-dom"
import typo from "../../typography.module.css"
import AlertDialog from "../dialogs/AlertDialog"

const LessonCard = ({ lesson, onClick = () => {} }) => {

    const [live, setLive] = useState(false)
    

    useEffect(() => {
        const checkLiveStatus = () => {
            let lessonStart = new Date(lesson.starts_at)
            let lessonEnd = new Date(lesson.ends_at)

            let now = new Date()
            let offset = now.getTimezoneOffset();

            lessonStart = new Date(lessonStart.getTime() - (offset * 60 * 1000));
            lessonEnd = new Date(lessonEnd.getTime() - (offset * 60 * 1000));

            if (now >= lessonStart && now < lessonEnd) {
                setLive(true)
            } else {
                setLive(false)
            }
        }

        // Esegui la verifica dello stato iniziale
        checkLiveStatus()

        // Avvia un intervallo per controllare lo stato ogni secondo
        // const interval = setInterval(checkLiveStatus, 5000)

        // // Pulisci l'intervallo quando il componente si dismonta
        // return () => clearInterval(interval)

    }, [lesson])

    return (
        <>
            <div className={`${styles.container} ${lesson.comingSoon ? styles.comingSoon : ''}`}>
                <Card style={{
                    display: 'flex',
                    padding: 0,
                    flexDirection: 'column',
                    width: '100%',
                    border: live ? '1px solid var(--primary)' : '',
                    boxShadow: live ? '0px 2px 12px 0 rgba(0,0,0,12%)' : '',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <div className={styles.header}>
                        <img src={lesson.edition.thumbnail} alt="" className={styles.courseImage} />
                        <div className={styles.date}>
                            <div className={styles.day}>
                                {capitalize(formatDateV2(lesson.starts_at, { weekday: 'long', month: 'long', day: 'numeric' }))}
                            </div>
                            <div className={styles.hours}>
                                {formatTimeV2(lesson.starts_at)} - {formatTimeV2(lesson.ends_at)}
                            </div>
                        </div>
                    </div>
                    <div className={styles.course}>
                        <div className={styles.courseTitle}>
                            {lesson.name}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                            <div className={typo.headline}> {lesson.edition.course.name} - {lesson.edition?.module?.name ?? lesson.module?.name} </div>
                            <div className={styles.badges}>
                                <CourseBadge style={{ width: 'fit-content' }} type={lesson.edition.course.type}></CourseBadge>
                                {live && <LiveBadge />}
                            </div>
                        </div>


                    </div>
                    <Button
                        onClick={() => {
                            onClick()
                        }}
                        appearance={live ? '' : 'text'}
                        additionalClass={live ? styles.lessonLiveButton : styles.lessonButton}
                    >
                        {!live &&
                            <>
                                VEDI DETTAGLI
                                <ArrowIcon className={styles.arrowIcon} />
                            </>
                        }
                        {live &&
                            <>
                                VAI ALLA LEZIONE
                                <ArrowIcon className={styles.arrowIcon} />
                            </>
                        }

                    </Button>
                </Card>
            </div>
        </>
    )
}

export default LessonCard
