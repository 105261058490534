import { useCallback, useEffect, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-edit.svg"
import { ReactComponent as ExerciseIcon } from "../assets/images/icons/ic-exercise.svg"
import { ReactComponent as MaterialIcon } from "../assets/images/icons/ic-files-inactive.svg"
import { ReactComponent as LessonIcon } from "../assets/images/icons/ic-lessons-inactive.svg"
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import MaterialButton from "../components/MaterialButton"
import MultiStateSwitch from "../components/MultiStateSwitch"
import Skeleton from "../components/Skeleton"
import Card from "../components/cards/Card"
import ExerciseMaterialCard from "../components/cards/ExerciseMaterialCard"
import AlertDialog from "../components/dialogs/AlertDialog"
import ExerciseDialog from "../components/dialogs/ExerciseDialog"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import { DialogStatus } from "../enums"
import { formatDateV2, formatTimeV2 } from "../utils"
import styles from "./Exercise.module.css"
import Back from "../components/Back"
import Markdown from "react-markdown"

const Exercise = ({ onDelete = () => { } }) => {
  const { t } = useTranslation()
  const { exerciseId } = useParams()
  const navigate = useNavigate()

  const [exercise, setExercise] = useState(null)
  const [loading, setLoading] = useState(true)

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [dialogStatus, setDialogStatus] = useState(DialogStatus.Default)
  const [usersExercises, setUsersExercises] = useState([])
  const [filteredUsersExercises, setFilteredUsersExercises] = useState([])
  const [filter, setFilter] = useState(0)
  const [dialogTimeout, setDialogTimeout] = useState(null)

  const [openAlert, setOpenAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
  const [closeTimeout, setCloseTimeout] = useState(null)

  useEffect(() => {
    getExercise()
  }, [])

  useEffect(() => {
    if (!exercise) {
      return
    }

    const { users_exercises } = exercise
    setUsersExercises(users_exercises)
    setFilteredUsersExercises(users_exercises)

  }, [exercise])

  const getExercise = useCallback(async () => {
    setLoading(true)
    try {
      const exercise = await api.get(`/teacher/exercises/${exerciseId}`)
      setExercise(exercise)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }, [])

  const onSave = useCallback(() => {
    getExercise()
    setDialogTimeout(setTimeout(() => setOpenEditDialog(false), 4000))
  }, [])

  const handleFilterChange = useCallback(
    (index) => {
      let filtered = [];
      switch (index) {
        case 0:
          setFilteredUsersExercises(usersExercises);
          break;
        case 1:
          filtered = usersExercises.filter((e) => (e.corrections && e.corrections.length > 0));
          setFilteredUsersExercises(filtered);
          break;
        case 2:
          filtered = usersExercises.filter((e) => (!e.corrections || e.corrections.length === 0));
          setFilteredUsersExercises(filtered);
          break;
        default:
          setFilteredUsersExercises(usersExercises);
      }
      setFilter(index);
    },
    [usersExercises]
  );

  const deleteExercise = useCallback(async () => {
    try {
      setAlertStatus(DialogStatus.Loading)
      await api.delete(`/teacher/exercises/${exercise.id}`)
      setAlertStatus(DialogStatus.Success)
      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
        navigate("/exercises")
      }, 1000))
    } catch (e) {
      console.error(e)
      setAlertStatus(DialogStatus.Error)

      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 1000))
    }
  }, [exercise])


  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>{t("exercises.title")}</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => {navigate(-1)}}/>
            <div className={styles.header}>
              <div className={styles.title}>{t("exercises.title")}</div>
              <div className={styles.actions}>
                <Button
                  appearance="default"
                  accentColor={"var(--sf-red)"}
                  style={{ padding: ".8rem 1.5rem" }}
                  onClick={() => setOpenAlert(true)}
                >
                  <DeleteIcon className={styles.actionIcon} /> {t("delete").toUpperCase()}
                </Button>
                <Button
                  accentColor={"var(--primary"}
                  inverse
                  style={{ padding: ".8rem 1.5rem" }}
                  onClick={() => setOpenEditDialog(true)}
                >
                  <EditIcon className={styles.actionIcon} /> {t("edit").toUpperCase()}
                </Button>
              </div>
            </div>
            <Card style={{ padding: 0 }}>
              {
                loading === true &&
                <>
                  <div className={styles.top}>
                    <Skeleton type="rect" width="256px" height="18px" borderRadius="8px" />
                  </div>
                  <div className={styles.cardContent}>
                    <div style={{ display: "flex", flexDirection: "column", gap: ".2rem" }}>
                      <Skeleton type="rect" width="70%" height="16px" borderRadius="8px" />
                      <Skeleton type="rect" width="33%" height="16px" borderRadius="8px" />
                    </div>
                    <div className={styles.bottomCard}>
                      {
                        exercise?.expires_at &&
                        <div className={styles.labeled}>
                          <Skeleton type="rect" width="96px" height="16px" borderRadius="8px" />
                          <Skeleton type="rect" width="196px" height="16px" borderRadius="8px" />
                        </div>
                      }
                      <div className={styles.labeled}>
                        <Skeleton type="rect" width="196px" height="16px" borderRadius="8px" />
                        <Skeleton type="rect" width="96px" height="16px" borderRadius="8px" />
                      </div>
                    </div>
                  </div>
                </>
              }

              {
                loading === false &&
                <>
                  <div className={styles.top}>
                    <div className={styles.icon}><ExerciseIcon /></div>
                    {exercise?.name}
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.description}><Markdown>{exercise?.description}</Markdown></div>
                    <div className={styles.bottomCard}>
                      {
                        exercise?.expires_at &&
                        <div className={styles.labeled}>
                          <div className={styles.label}>{t("exercises.expiresAt")}</div>
                          <div className={styles.value}><CalendarIcon /> {formatDateV2(exercise?.expires_at)} {formatTimeV2(exercise?.expires_at)} </div>
                        </div>
                      }
                      <div className={styles.labeled}>
                        <div className={styles.label}>{t("exercises.submitNumber")}</div>
                        <div className={styles.value}><StudentsIcon />{exercise?.users_exercises.length} {t("outOf")} {exercise?.total_users}</div>
                      </div>
                      <div className={styles.labeled}>
                        <div className={styles.label}>{t("exercises.lesson.label")}</div>
                        <div className={styles.value}><LessonIcon />{exercise?.lesson?.name ?? t("exercises.lesson.empty")}</div>
                      </div>
                      <div className={styles.labeled}>
                        <div className={styles.label}>{t("exercises.uploadedMaterials")}</div>
                        <div className={styles.value}><MaterialIcon />{exercise?.medias?.length ?? 0}</div>
                      </div>
                    </div>
                    {
                      exercise?.medias && exercise.medias.length > 0 &&
                      <div className={styles.labeled}>
                        <div className={styles.label}>{t("exercises.materials")}</div>
                        <div className={styles.studentFilesContainer}>
                          <div className={styles.studentFiles}>
                            <Each
                              of={exercise.medias}
                              render={(file) => (
                                <div className={styles.studentFile}>
                                  <MaterialButton
                                    material={file}
                                    downloadable={true}
                                  />
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </>
              }
            </Card>

            <MultiStateSwitch
              states={[
                t("all").toUpperCase(),
                t("exercises.correctExercises").toUpperCase(),
                t("exercises.toBeCorrectedExercises").toUpperCase(),
              ]}
              onStateChange={handleFilterChange}
            />

            {
              loading === true &&
              <Each
                of={[1, 2, 3]}
                render={() => <ExerciseMaterialCard loading />}
              />
            }

            {
              !loading &&
              <Each
                of={filteredUsersExercises}
                render={(ue) => (
                  <ExerciseMaterialCard exercise={{ exercise, ...ue, }} onCorrection={() => getExercise()} />
                )}
              />
            }

          </div>
        </div>
        <ExerciseDialog
          open={openEditDialog}
          onClose={() => {
            if (dialogTimeout) {
              clearTimeout(dialogTimeout)
              setDialogTimeout(null)
            }
            setOpenEditDialog(false)
          }}
          exercise={exercise}
          status={dialogStatus}
          onSave={onSave}
        />

        <AlertDialog
          open={openAlert}
          title={t("exercises.deleteTitle")}
          text={t("exercises.deleteText")}
          status={alertStatus}
          onClose={() => {
            if (dialogStatus === DialogStatus.Success) {
              navigate("/exercises")
            }
            setOpenAlert(false)
            setAlertStatus(DialogStatus.Default)
            if (closeTimeout) {
              clearTimeout(closeTimeout)
            }
          }}
          actions={[
            {
              label: t("exercises.deleteConfirm").toUpperCase(),
              onClick: deleteExercise,
            }
          ]}
        />
      </div>
    </HeaderFooterLayout >
  )

}

export default Exercise
