import { DailyAudio, useActiveSpeakerId, useCPULoad, useDaily, useDailyError, useLocalSessionId, useMeetingSessionState, useMeetingState, useNetwork, useParticipantIds, useRecording } from "@daily-co/daily-react";
import { Excalidraw } from "@excalidraw/excalidraw";
// import { Howl } from "howler";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import api from "../../api";
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg";
import { Each } from "../../common/Each";
import MainContext from "../../common/MainContext";
import typo from "../../typography.module.css";
import Button from "../Button";
import AlertDialog from "../dialogs/AlertDialog";
import LiveBadge from "../LiveBadge";
import NotificationBanner from "../NotificationBanner";
import DailyChat from "./DailyChat";
import DailyControls from "./DailyControls";
import DailyList from "./DailyList";
import DailyParticipant from "./DailyParticipant";
import styles from "./DailyRoom.module.css";
import DailySettings from "./DailySettings";
import DailySpotlighted from "./DailySpotlighted";
import { UAParser } from "ua-parser-js";

const DailyRoom = ({
    token,
    roomName,
    students,
    onStudentsChange,
    websocket,
    whiteboard,
    onWhiteboardChange,
    onCallRejoin,
    lesson_id }) => {

    const context = useContext(MainContext)
    const call = useDaily()
    const localSessionId = useLocalSessionId()
    const participants = useParticipantIds({
        filter: 'remote', sort: ((p1, p2) => {

            if (p1?.owner && !p2?.owner) return -1
            if (!p1?.owner && p2?.owner) return 1
            if (p1?.owner && p2?.owner) {
                if (p1?.userData?.role === 'teacher' && p2?.userData?.role !== 'teacher') return -1
                if (p1?.userData?.role !== 'teacher' && p2?.userData?.role === 'teacher') return 1
                if (p1?.userData?.role === 'teacher' && p2?.userData?.role === 'teacher') return 0
            }

            if (!p1?.owner && !p2?.owner) {
                if (p1?.tracks.audio.state === 'playable' && p2?.tracks.audio.state !== 'playable') return -1
                if (p1?.tracks.audio.state !== 'playable' && p2?.tracks.audio.state === 'playable') return 1
                if (p1?.tracks.audio.state === 'playable' && p2?.tracks.audio.state === 'playable') {
                    if (remoteAudioLevels[p1] > remoteAudioLevels[p2] * 1.5) return -1
                    if (remoteAudioLevels[p2] > remoteAudioLevels[p1] * 1.5) return 1
                    return 0
                }
                if (p1?.tracks.audio.state !== 'playable' && p2?.tracks.audio.state !== 'playable') {
                    if (p1?.userData?.handRaised && !p2?.userData?.handRaised) return -1
                    if (!p1?.userData?.handRaised && p2?.userData?.handRaised) return 1
                    return 0
                }
            }
        })
    })
    const handRaisedParticipants = useParticipantIds({ filter: (p) => { return p?.userData?.handRaised } })
    const { data: sessionData } = useMeetingSessionState()
    const meetingState = useMeetingState()
    const fullScreenHandle = useFullScreenHandle()
    const { getStats, quality, threshold } = useNetwork()

    const activeParticipant = useActiveSpeakerId({
        filter: (p) => {
            let participants = call.participants()
            let participant = participants[p]
            return !participant?.owner
        }, ignoreLocal: true
    })

    const [showParticipants, setShowParticipants] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [excalidrawAPI, setExcalidrawAPI] = useState(null)
    const [chat, setChat] = useState(false)
    const [unread, setUnread] = useState(0)
    const [file, setFile] = useState(null);
    const [overlayMenu, setOverlayMenu] = useState({ open: false, mode: null })
    const [whiteboardController, setWhiteboardController] = useState(null)
    const [muted, setMuted] = useState(false)

    const [spotlighted, _setSpotlighted] = useState([])
    const spotlightedRef = useRef(spotlighted)
    const setSpotlighted = (data) => {
        spotlightedRef.current = data
        _setSpotlighted(data)
    }

    const [pinned, _setPinned] = useState([])
    const pinnedRef = useRef(pinned)
    const setPinned = (data) => {
        pinnedRef.current = data
        _setPinned(data)
    }

    const [remoteAudioLevels, setRemoteAudioLevels] = useState({})
    const [listTab, setListTab] = useState(0)
    const [participantToRemove, setParticipantToRemove] = useState(null)
    const [lessonEnding, setLessonEnding] = useState(false)

    const [audioErrors, _setAudioErrors] = useState([])
    const audioErrorsRef = useRef(audioErrors)
    const setAudioErrors = (data) => {
        audioErrorsRef.current = data;
        _setAudioErrors(data)
    }

    const [lockLoading, setLockLoading] = useState(false)

    const { meetingError, nonFatalError } = useDailyError();
    const { state, reason } = useCPULoad({
        onCPULoadChange: async (event) => {

            if (event.cpuLoadState === 'high') {
                await saveError("High CPU Load", JSON.stringify(event, null, 2))
            }
        }
    })

    useEffect(() => {
        if (meetingError) {
            saveError(meetingError.errorMsg, JSON.stringify(meetingError, null, 2))
        }
        if (nonFatalError) {
            saveError(nonFatalError.errorMsg, JSON.stringify(nonFatalError, null, 2))
        }
    }, [meetingError, nonFatalError])

    const saveError = async (message, stack) => {

        const parser = new UAParser();
        const result = parser.getResult();

        const errorDetails = {
            message: message,
            stack: stack,
            user_id: context.user ? context.user.id : null, // Se non loggato, usa "Guest"
            user_name: context.user ? `${context.user.name} ${context.user.surname}` : null,
            platform: "teachers",
            page: window.location.pathname,
            device: result
        };

        try {
            await api.post("/error", errorDetails);
        } catch (err) {
            console.error("Failed to send error report:", err);
        }
    }

    useEffect(() => {

        call.on('participant-left', (e) => {
            setPinned(pinnedRef.current.filter(p => p !== e.participant.session_id))
            setSpotlighted(spotlightedRef.current.filter(s => s.session_id !== e.participant.session_id))
        })

        const startRemoteAudioObserver = async () => {
            try {
                if (!call.isRemoteParticipantsAudioLevelObserverRunning()) {
                    await call.startRemoteParticipantsAudioLevelObserver(100);
                }
            } catch (e) {
                console.error("Error starting audio observer: ", e)
            }
        }

        startRemoteAudioObserver()

        call.on('remote-participants-audio-level', async (event) => {
            setRemoteAudioLevels(event.participantsAudioLevel)
        })

    }, [])

    useEffect(() => {
        if (localSessionId) {
            addParticipantId()
        }
        if (sessionData) {
            setPinned(sessionData?.pinned ?? [])
            if (sessionData.pinned && sessionData.pinned.length > 0) {
                setSpotlighted([...sessionData.pinned.map(p => { return { session_id: p, pinned: true } })])
            }
            else {
                setSpotlighted([{ session_id: localSessionId, pinned: false }])
            }
        }
        else {
            try {
                let sessionState = call.meetingSessionState()
                if (sessionState.data) {
                    setPinned(sessionState.data.pinned ?? [])
                    if (sessionState.data.pinned && sessionState.data.pinned.length > 0) {
                        setSpotlighted([...sessionState.data.pinned.map(p => { return { session_id: p, pinned: true } })])
                    }
                    else {
                        setSpotlighted([{ session_id: localSessionId, pinned: false }])
                    }
                }
            }
            catch (e) {
                console.error(e)
            }
        }
    }, [sessionData, localSessionId, roomName])

    useEffect(() => {
        if (pinned && pinned.length > 0) {
            let all = Object.values(call.participants())
            let pinnedXSpotlighted = pinned.filter(p => all.find(a => a.session_id === p))
            setSpotlighted(pinnedXSpotlighted.map(p => { return { session_id: all.find(a => a.session_id === p).session_id, pinned: true } }))

            if (pinnedXSpotlighted.length > 0) {
                call.setMeetingSessionData({ pinned: pinnedXSpotlighted }, 'shallow-merge')
            }
            else {
                setSpotlighted([{ session_id: localSessionId, pinned: false }])
            }
        }
        else {
            setSpotlighted([{ session_id: localSessionId, pinned: false }])
        }
    }, [pinned])

    // useEffect(() => {
    //     if (excalidrawAPI) {
    //         if (whiteboard.show && !whiteboard.readOnly) {
    //             websocket.sendMessage({
    //                 type: 'whiteboard_started',
    //                 data: [],
    //                 session_id: localSessionId
    //             })
    //             if (call) {
    //                 const canvas = document.querySelector('.excalidraw__canvas.static')
    //                 if (canvas) {
    //                     const stream = canvas.captureStream(60)
    //                     call.startCustomTrack({ track: stream.getTracks()[0], trackName: 'whiteboard' })
    //                 }
    //             }
    //         }

    //         if (!whiteboard.show && !whiteboard.readOnly) {
    //             websocket.sendMessage({
    //                 type: 'whiteboard_stopped'
    //             })
    //             if (call && !['error', 'left-meeting'].includes(meetingState)) {
    //                 call.stopCustomTrack('whiteboard')
    //             }
    //         }
    //         excalidrawAPI.updateScene({ elements: whiteboard.data })
    //     }

    //     if (whiteboard.show && whiteboard.readOnly && whiteboard.sessionId && call) {
    //         let participants = call.participants()
    //         const p = participants[whiteboard.sessionId]
    //         if (p) {
    //             setWhiteboardController(`${p.userData?.name} ${p.userData?.surname}`)
    //         }
    //     }

    // }, [whiteboard])

    useEffect(() => {

        let all = call.participants()

        let participantArray = Object.values(all)

        let newStudents = students.map(s => {
            s.present = false
            for (let p of participantArray) {
                if (s.id === p.userData?.id && !p.owner) {
                    s.sessionId = p.session_id
                    s.present = true
                }
            }
            return s
        })
        onStudentsChange(newStudents)
    }, [participants])

    useEffect(() => {
        if (['error', 'left-meeting'].includes(meetingState)) {
            onWhiteboardChange(null, null, true)
            setOverlayMenu({ open: false, mode: null })
            setShowParticipants(false)
            setChat(false)
        }
    }, [meetingState])

    const onFullScreenChange = (fullScreenState) => {
        setFullScreen(fullScreenState)
    }

    // const onWhiteboardDataChange = async (event) => {
    //     if (!whiteboard.readOnly) {
    //         websocket.sendMessage({
    //             type: 'whiteboard_changed',
    //             data: event,
    //             session_id: localSessionId
    //         })
    //     }
    // }

    const onRecordingStarted = async (event) => {
        try {
            context.setNotificationBanner({
                title: "🎥 Registrazione Cloud Iniziata",
                body: "La registrazione è iniziata"
            })
        }
        catch (e) {
            console.error(e)
        }
    }

    const onRecordingError = async (event) => {
        try {
            document.dispatchEvent(new CustomEvent('api-error', { detail: { title: "Errore Registrazione Cloud", message: event?.errorMsg ?? '' } }));
            startRecording({
                layout: {
                    preset: "active-participant"
                }
            })
        }
        catch (e) {
            console.error(e)
        }
    }

    const onRecordingStopped = async (event) => {
        try {
            context.setNotificationBanner({
                title: "Registrazione Cloud Terminata",
                body: event.errorMsg
            })
            startRecording({
                layout: {
                    preset: "active-participant"
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    const { isRecording, startRecording } = useRecording({ onRecordingStarted: onRecordingStarted, onRecordingError: onRecordingError, onRecordingStopped: onRecordingStopped })

    const onPlayFailed = useCallback((event) => {
        console.error(event)
        setAudioErrors([...audioErrorsRef.current, event.target])
    }, [])

    const audioRef = useRef(null)

    const retryAudioErrors = () => {
        for (let audioElm of audioErrors) {
            try {
                audioElm.play()
                setAudioErrors(audioErrors.filter(a => a !== audioElm))
            }
            catch {
                //
            }
        }
    }

    const lockRoom = async (locked) => {
        setLockLoading(true)
        try {
            const body = { locked: locked }
            await api.put(`teacher/rooms/${roomName}/lock`, body)
        }
        catch (e) {
            console.error(e)
        }
        setLockLoading(false)
    }

    async function addParticipantId() {
        let body = {
            participant_id: localSessionId
        }
        try {
            await api.put(`/teacher/lessons/${lesson_id}/participant`, body)
        }
        catch (e) {
            console.error(e)
        }
    }

    const splideRef = useRef(null)

    const [netStats, setNetStats] = useState(null)

    useEffect(() => {
        let interval = setInterval(async () => {
            let stats = await call.getNetworkStats()
            setNetStats(stats)
        }, 2000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        console.log('file', file)
    }, [file])

    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChange}>
            {!['error', 'left-meeting'].includes(meetingState) &&
                <div className={styles.container}>
                    {fullScreen &&
                        <NotificationBanner notification={context.notificationBanner} />
                    }
                    <div className={`${styles.main} ${fullScreen ? styles.fullscreen : ''}`}>
                        {/* --------------------------- Spotlighted Stream --------------------------- */}
                        <div className={styles.spotlighted}>
                            {!['error', 'left-meeting'].includes(meetingState) &&
                                <>
                                    {spotlighted && !whiteboard.show &&
                                        <DailySpotlighted
                                            file={file}
                                            pinned={pinned}
                                            spotlighted={spotlighted}
                                            onRemoveSpotlighetd={(session_id) => {
                                                setSpotlighted(spotlighted.filter(s => s.session_id !== session_id))
                                            }} />
                                    }
                                    {/* {whiteboard.show && !file &&
                                        <div style={{ display: 'flex', minHeight: '500px', width: '100%', position: 'relative' }}>
                                            {whiteboardController && whiteboard.readOnly &&
                                                <div className={styles.whiteboardName}>{whiteboardController}</div>
                                            }
                                            <Excalidraw viewModeEnabled={whiteboard.readOnly} onChange={onWhiteboardDataChange} excalidrawAPI={(api) => setExcalidrawAPI(api)} />
                                        </div>
                                    } */}
                                    {/* {file &&
                                    <PDFCanvas file={file} call={call} />
                                    // <PdfViewer file={file} call={call} />
                                } */}

                                    {isRecording &&
                                        <div style={{ display: 'flex', position: 'absolute', top: '.25rem', right: '.25rem', background: 'rgba(0,0,0,0.6)', borderRadius: '8px' }}>
                                            <LiveBadge label={'REC'} />
                                        </div>
                                    }

                                    {quality <= 80 &&
                                        <div className={styles.overlay}>
                                            {quality <= 80 && quality > 70 &&
                                                <div>
                                                    La qualità della tua connessione è scarsa {quality}...
                                                </div>
                                            }
                                            {quality <= 70 &&
                                                <div>
                                                    La qualità della tua connessione è molto scarsa {quality}...
                                                </div>
                                            }
                                        </div>
                                    }
                                    {audioErrors.length > 0 &&
                                        <div className={styles.overlay}>
                                            <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                                                Sembrano esserci stati dei problemi con l'audio di alcuni partecipanti. Prova a risolvere il problema cliccando qui:
                                                <Button appearance="text" accentColor={'var(--sf-red)'} onClick={retryAudioErrors}>riprova</Button>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {meetingState === 'left-meeting' &&
                                <div className={styles.meetingLeft}>
                                    Hai abbandonato la lezione.
                                    <Button onClick={() => {
                                        onCallRejoin()
                                    }}>RIENTRA</Button>
                                </div>
                            }
                            {meetingState === 'error' &&
                                <div className={styles.meetingLeft}>
                                    Non sei più all'interno della lezione. È possibile che un altro docente o tutor abbia terminato la lezione.
                                    <Button onClick={() => {
                                        onCallRejoin()
                                    }}>RIENTRA</Button>
                                    <Button onClick={() => { window.location.href = '/' }}>TORNA ALLA DASHBOARD</Button>
                                </div>
                            }
                        </div>

                        <DailyAudio maxSpeakers={200} onPlayFailed={onPlayFailed} ref={audioRef} playLocalScreenAudio={true} />

                        {/* ---------------------------------- Chat ---------------------------------- */}
                        <DailyChat token={token} open={chat} unread={unread} onUnreadChange={(number) => {
                            setUnread(number)
                        }} lesson_id={lesson_id} />


                        <DailySettings
                            open={overlayMenu.open && overlayMenu.mode === 'settings'}
                            onLessonEnding={() => {
                                setLessonEnding(true)
                            }}
                            roomName={roomName}
                        />

                        <DailyList
                            lesson_id={lesson_id}
                            open={overlayMenu.open && overlayMenu.mode === 'list'}
                            tab={listTab}
                            roomName={roomName}
                            students={students}
                            onStudentsChange={onStudentsChange}
                            pinned={pinned}
                            onPinnedChange={(session_id) => {
                                if (!pinned?.includes(session_id) && pinned?.length < 4) {
                                    setPinned([...pinned, session_id])
                                    call.setMeetingSessionData({ pinned: [...pinned, session_id] }, 'shallow-merge')
                                    setSpotlighted([...spotlighted, { session_id: session_id, pinned: true }])
                                }
                                else {
                                    setPinned(pinned.filter(p => p !== session_id))
                                    call.setMeetingSessionData({ pinned: pinned.filter(p => p !== session_id) }, 'shallow-merge')
                                    setSpotlighted(spotlighted.map(s => {
                                        return { session_id: s.session_id, pinned: s.session_id === session_id ? false : s.pinned }
                                    }))
                                }
                            }}
                            onWaitingNotification={() => {
                                setOverlayMenu({ open: true, mode: 'list' })
                                setListTab(2)
                            }} />
                    </div>

                    <div className={`${styles.participants} ${!showParticipants ? styles.close : ''}`}>
                        <div className={styles.arrowLeft} onClick={() => {
                            if (splideRef && splideRef.current) {
                                splideRef.current.splide.go('<')
                            }
                        }}>
                            <ChevronIcon />
                        </div>
                        <div className={styles.arrowRight} onClick={() => {
                            if (splideRef && splideRef.current) {
                                splideRef.current.splide.go('>')
                            }
                        }}>
                            <ChevronIcon />
                        </div>
                        <Splide ref={splideRef} className={`${styles.participantsSplide} ${context.sideNavOpen ? styles.sideNavOpen : ''} ${fullScreen ? styles.fullscreen : ''}`}
                            hasTrack={false}
                            options={{
                                autoWidth: true,
                                gap: '1rem',
                                pagination: false,
                                arrows: true,
                                drag: false
                            }}>
                            <SplideTrack className={styles.participantsTrack}>
                                <SplideSlide>
                                    <DailyParticipant
                                        key={localSessionId}
                                        pinned={pinned}
                                        isLocal={true}
                                        muted={true}
                                        session_id={localSessionId}
                                        style={{ backgroundColor: 'black' }}
                                        onClick={() => {
                                            if (spotlighted && spotlighted.length < 4 && !spotlighted.find(s => s.session_id === localSessionId)) {
                                                setSpotlighted([...spotlighted, { session_id: localSessionId, pinned: false }])
                                            }
                                        }}
                                        onPin={() => {
                                            if (!pinned?.includes(localSessionId) && pinned?.length < 4) {
                                                setPinned([...pinned, localSessionId])
                                                call.setMeetingSessionData({ pinned: [...pinned, localSessionId] }, 'shallow-merge')
                                                setSpotlighted([...spotlighted, { session_id: localSessionId, pinned: true }])
                                            }
                                            else {
                                                setPinned(pinned.filter(p => p !== localSessionId))
                                                call.setMeetingSessionData({ pinned: pinned.filter(p => p !== localSessionId) }, 'shallow-merge')
                                                setSpotlighted(spotlighted.map(s => {
                                                    return { session_id: s.session_id, pinned: s.session_id === localSessionId ? false : s.pinned }
                                                }))
                                            }
                                        }}
                                    />
                                </SplideSlide>
                                <Each of={participants} render={(p, index) => {
                                    return (
                                        <>
                                            <SplideSlide>
                                                <DailyParticipant
                                                    audioRef={audioRef}
                                                    key={p}
                                                    pinned={pinned}
                                                    muted={muted}
                                                    session_id={p}
                                                    onClick={() => {
                                                        if (spotlighted && spotlighted.length < 4 && !spotlighted.find(s => s.session_id === p)) {
                                                            setSpotlighted([...spotlighted, { session_id: p, pinned: false }])
                                                        }
                                                    }}
                                                    onHandRaised={(name) => {
                                                        context.setNotificationBanner({
                                                            title: `${name} ha alzato la mano ✋`,
                                                            body: "Clicca qui e apri il menù utenti.",
                                                            onClick: () => {
                                                                setShowParticipants(true)
                                                            }
                                                        })
                                                    }}
                                                    onPin={(session_id) => {
                                                        if (!pinned?.includes(session_id) && pinned?.length < 4) {
                                                            setPinned([...pinned, session_id])
                                                            call.setMeetingSessionData({ pinned: [...pinned, session_id] }, 'shallow-merge')
                                                            setSpotlighted([...spotlighted, { session_id: session_id, pinned: true }])
                                                        }
                                                        else {
                                                            setPinned(pinned.filter(p => p !== session_id))
                                                            call.setMeetingSessionData({ pinned: pinned.filter(p => p !== session_id) }, 'shallow-merge')
                                                            setSpotlighted(spotlighted.map(s => {
                                                                return { session_id: s.session_id, pinned: s.session_id === session_id ? false : s.pinned }
                                                            }))

                                                        }
                                                    }}
                                                    onParticipantRemove={(participant) => {
                                                        setParticipantToRemove(participant)
                                                    }}
                                                />
                                            </SplideSlide>
                                        </>
                                    )
                                }} />
                            </SplideTrack>
                        </Splide>
                    </div>

                    <DailyControls
                        localSessionId={localSessionId}
                        showParticipants={showParticipants}
                        onShowParticipantsChange={() => {
                            setShowParticipants(!showParticipants)
                        }}
                        fullScreen={fullScreen}
                        onFullScreenChange={() => {
                            fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter()
                            setFullScreen(!fullScreen)
                        }}
                        whiteboard={whiteboard.show}
                        onWhiteboardChange={() => {
                            onWhiteboardChange(localSessionId, whiteboardController)
                        }}
                        chat={chat}
                        onChatChange={() => {
                            if (!chat) {
                                setUnread(0)
                            }
                            setChat(!chat)
                        }}
                        unread={unread}
                        handsRaised={handRaisedParticipants.length}
                        overlayMenu={overlayMenu}
                        onOverlayMenuChange={(mode) => {
                            let newOverlayState = { open: false, mode: null }
                            if (overlayMenu.open) {
                                if (overlayMenu.mode !== mode) {
                                    newOverlayState.mode = mode
                                    newOverlayState.open = true
                                }
                                else {
                                    newOverlayState.open = false
                                }
                            } else {
                                newOverlayState.mode = mode;
                                newOverlayState.open = true
                            }
                            setOverlayMenu(newOverlayState)
                        }}
                        pdf={file}
                        onPdfChange={(value) => {
                            setFile(value)
                        }}
                        muted={muted}
                        onMutedChange={(value) => {
                            setMuted(value)
                        }}
                    />
                </div>
            }
            {meetingState === 'left-meeting' &&
                <div className={styles.container}>
                    <div className={`${styles.main} ${fullScreen ? styles.fullscreen : ''}`}>
                        <div className={styles.meetingLeft}>
                            Hai abbandonato la lezione.
                            <Button onClick={() => {
                                window.location.reload()
                            }}>RIENTRA</Button>
                        </div>
                    </div>
                </div>
            }
            {meetingState === 'error' &&
                <div className={styles.container}>
                    <div className={`${styles.main} ${fullScreen ? styles.fullscreen : ''}`}>
                        <div className={styles.meetingLeft}>
                            Non sei più all'interno della lezione. È possibile che un altro docente o tutor abbia terminato la lezione.
                            <Button onClick={() => {
                                window.location.reload()
                            }}>RIENTRA</Button>
                            <Button onClick={() => { window.location.href = '/' }}>TORNA ALLA DASHBOARD</Button>
                        </div>
                    </div>
                </div>
            }
            <AlertDialog
                style={{ color: 'black' }}
                open={participantToRemove}
                title={`Rimuovere ${participantToRemove?.name} ?`}
                text={`${participantToRemove?.name} sarà rimosso dalla lezione e avvisato. Potrà comunque rientrare immediatamente.`}
                onClose={() => {
                    setParticipantToRemove(null)
                }}
                actions={[
                    {
                        label: "Rimuovi",
                        onClick: async () => {
                            call.updateParticipant(participantToRemove?.id, { eject: true })
                            setParticipantToRemove(null)
                        }
                    }
                ]}
            />

            <AlertDialog
                style={{ color: 'black' }}
                open={lessonEnding}
                title={`Terminare la lezione ?`}
                text={`Tutti i partecipanti saranno rimossi. Se hai sbloccato la stanza, questa sarà bloccata nuovamente.`}
                onClose={() => {
                    setLessonEnding(false)
                }}
                actions={[
                    {
                        label: "Termina",
                        onClick: async () => {
                            let updateList = {};
                            for (let id in call.participants()) {
                                if (id === 'local') {
                                    continue;
                                }
                                updateList[id] = { eject: true };
                            }
                            await lockRoom(true)
                            call.updateParticipants(updateList);
                            call.leave()
                            setLessonEnding(false)
                        }
                    }
                ]}
            />

        </FullScreen >
    )
}

export default DailyRoom
