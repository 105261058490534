
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Each } from "../../common/Each"
import { CourseStatus, CourseType, EditionStatus } from "../../common/constants"
import typo from "../../typography.module.css"
import Button from "../Button"
import CourseBadge from "../CourseBadge"
import styles from "./TeacherCourseCard.module.css"
import { ReactComponent as PeopleIcon } from "../../assets/images/icons/ic-people.svg"
import Badge from "../Badge"

const TeacherCourseCard = ({ edition }) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={`${edition.course.type === CourseType.Masterclass ? styles.noWrapper : styles.wrapper} ${edition.status === EditionStatus.Locked ? styles.locked : ''}`}>
      {edition.course.type === CourseType.Professional &&
        <div className={[typo.subheadline, styles.courseName].join(' ')}>
          {edition.course.name} - {edition.name}
          {[EditionStatus.Ended, EditionStatus.Locked].includes(edition.status) &&
            <Badge name={t(`editions.status.${edition.status}`)} color={edition.status === EditionStatus.Ended ? 'var(--sf-red)' : 'gray'} />
          }
        </div>
      }
      <Each
        of={edition.modules.sort((a,b) => {
          return a.position - b.position
        })}
        render={(module, index) => (
          <div className={styles.container}>
            <div className={styles.left}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                {
                  edition.course.type === CourseType.Professional &&
                  <div className={typo.subheadline}>{module.name}</div>
                }
                {
                  edition.course.type === CourseType.Masterclass &&
                  <div className={typo.subheadline}>{edition.course.name} - {edition.name}</div>
                }
                {[EditionStatus.Ended, EditionStatus.Locked].includes(edition.status) && edition.course.type === CourseType.Masterclass &&
                  <Badge name={t(`editions.status.${edition.status}`)} color={edition.status === EditionStatus.Ended ? 'var(--sf-red)' : 'gray'} />
                }
                {![EditionStatus.Ended, EditionStatus.Locked].includes(edition.status) &&
                  <CourseBadge type={edition.course.type} style={{ width: 'fit-content' }} />
                }
              </div>
              <div className={`${typo.body} ${styles.description}`} style={{ opacity: 0.4 }}>{module.description}</div>
              {edition.status !== EditionStatus.Locked &&
                <Button
                  disabled={edition.status === EditionStatus.Locked}
                  inverse={edition.course.status !== CourseStatus.ToStart} onClick={() => {
                    navigate(`/courses/${module.id}`)
                  }}>
                  {t('courses.enter').toUpperCase()}
                </Button>
              }
            </div>
            <div className={styles.right}>
              <div className={styles.thumbnail}>
                <img src={edition.course.thumbnail} alt="course-thumbnail"></img>
              </div>
              <div className={styles.students}>
                <PeopleIcon />
                <div className={styles.studentsCounter}>
                  {edition.students}
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )

}

export default TeacherCourseCard
