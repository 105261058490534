
const Linkify = ({children})=> {
    const isUrl = word => {
        const urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9]+([-\.][a-z0-9]+)*\.[a-z]{2,}(:\d{1,5})?(\/.*)?$/gm;
        return word.match(urlPattern)
    }

    const addMarkup = word => {
        return isUrl(word) ? 
            `<a target="_blank" style="color:rgb(53, 134, 255);" href="${word.includes('http') ? word : `https://${word}`}">${word}</a>`:  
            word
    }

    const words = children.split(' ')
    const formatedWords = words.map( w => addMarkup(w))
    const html = formatedWords.join(' ')
    return (<span dangerouslySetInnerHTML={{__html: html}} />)
}

export default Linkify