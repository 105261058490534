import Daily from "@daily-co/daily-js";
import { DailyProvider } from "@daily-co/daily-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import api from "../api";
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg";
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png";
import { darkPalette, ProfileType } from "../common/constants";
import { Each } from "../common/Each";
import MainContext from "../common/MainContext";
import Button from "../components/Button";
import TestCard from "../components/cards/TestCard";
import CourseBadge from "../components/CourseBadge";
import DailyRoom from "../components/daily/DailyRoom";
import AlertDialog from "../components/dialogs/AlertDialog";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import LiveBadge from "../components/LiveBadge";
import MaterialButton from "../components/MaterialButton";
import Spinner from "../components/Spinner";
import TextInput from "../components/TextInput";
import { DialogStatus } from "../enums";
import typo from "../typography.module.css";
import { capitalize, formatDateV2, formatTimeV2, getLocalDate } from "../utils";
import WebSocketClient from "../websocket";
import styles from "./Lesson.module.css";
// import { Howl } from 'howler'
import useBeforeUnload from "../common/hooks/useBeforeUnload";
import usePrompt from "../common/hooks/usePrompt";
import VideoPlayer from "../components/VideoPlayer";
import DailyTest from "../components/daily/DailyTest";

var interval = null
// var socket = null

const Lesson = () => {
  const context = useContext(MainContext)
  const location = useLocation(); // Ottieni la posizione corrente
  const { roomName } = useParams();
  const [lesson, setLesson] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [minutesDiff, setMinutesDiff] = useState(null)
  const [token, setToken] = useState(null)
  const [call, setCall] = useState(null)
  const [students, setStudents] = useState([])
  const [roomError, setRoomError] = useState(null);

  const [duration, setDuration] = useState(null)
  const [testStarted, setTestStarted] = useState(false)

  const [reconnecting, setReconnecting] = useState(false)

  const [alert, setAlert] = useState({ open: false, status: DialogStatus.Error, title: '', text: '', actions: [] })
  const [callQualityTestCompleted, setCallQualityTestCompleted] = useState(false)
  const [callJoined, setCallJoined] = useState(false)
  const [callPreAuthorized, setCallPreAuthorized] = useState(false)

  const [tests, _setTests] = useState([])
  const testsRef = useRef(tests)
  const setTests = data => {
    testsRef.current = data;
    _setTests(data);
  }


  //Uso un ref allo stato per renderlo accessibile all'interno dell'event handler del messaggio (onMessageReceived)
  const [selectedTest, _setSelectedTest] = useState(null)
  const selectedTestRef = useRef(selectedTest)
  const setSelectedTest = data => {
    selectedTestRef.current = data;
    _setSelectedTest(data);
  }

  const [whiteboard, setWhiteboard] = useState({ show: false, readOnly: false, data: [], sessionId: null })
  const [screenSharingRequests, _setScreenSharingRequests] = useState([])
  const screenSharingRequestsRef = useRef(screenSharingRequests)
  const setScreenSharingRequests = data => {
    screenSharingRequestsRef.current = data;
    _setScreenSharingRequests(data);
  }

  // const requestSound = new Howl({
  //   src: ['/sounds/waiting.mp3'],
  //   autoplay: false,
  //   loop: false,
  //   volume: 0.5,
  //   html5: true
  // });

  usePrompt('Sei sicuro di voler abbandonare la lezione ?', !!call)
  useBeforeUnload('Sei sicuro di voler abbandonare la lezione ?', !!call)
  useEffect(() => {
    document.body.style['overscroll-behavior-x'] = 'none';
  }, [])

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
      if (call) {
        call.leave();
        call.destroy();
      }
      // if (socket) {
      //   socket.close();
      // }
    };
  }, [call]);

  useEffect(() => {
    const getLesson = async (roomName) => {
      try {
        const lesson = await api.get(`/teacher/lessons/${roomName}`);
        setLesson(lesson)
      } catch (e) {
        console.error(e)
      }
    };

    if (roomName) {
      getLesson(roomName);
    }
  }, [roomName]);

  useEffect(() => {

    const getLessonMaterial = async (lessonId) => {
      try {
        const materials = await api.get(`/teacher/lessons/${lessonId}/materials`);

        setMaterials(materials);
      } catch (e) {
        console.error(e);
      }
    };

    const getToken = async (lessonId) => {
      try {
        const token = await api.get(`/teacher/lessons/${lessonId}/token`)
        setToken(token.token)
      }
      catch (e) {
        console.error(e)
      }
    }

    const getStudents = async (moduleId) => {
      try {
        let students = await api.get(`teacher/modules/${moduleId}/students`)
        setStudents(students)
      }
      catch (e) {
        console.error(e)
      }
    }

    if (lesson) {
      getLessonMaterial(lesson.id)
      getToken(lesson.id)
      getStudents(lesson.module.id)
      getTests(lesson.id)
      getLessonParticipant(lesson.id)
      interval = setInterval(() => {
        calculateMinutesDiff(lesson.starts_at)
      }, 1000)
    }
  }, [lesson])

  const joinCall = async (call) => {
    console.debug('joinCall')
    try {
      await call.join()
      const randomColor = darkPalette[Math.floor(Math.random() * darkPalette.length)];
      context.setUser((prev) => {
        prev.color = prev.color ?? randomColor
        return { ...prev }
      })
      call.setUserName(`${context.user?.name} ${context.user?.surname}`)
      call.setUserData({ ...context.user, color: context.user?.color ?? randomColor })
      setCall(call)
      setCallJoined(true)
    }
    catch (e) {
      console.error(e)
      setRoomError(e.errorMsg)
    }
  }

  const preAuthCall = async (call) => {
    console.debug('preAuthCall')
    try{
      await call.preAuth({ url: `https://startingfinance.daily.co/${lesson.room_name}`, token: token })
      setCall(call)
      setCallPreAuthorized(true)
    }
    catch (e) {
      console.error(e)
      setRoomError(e.errorMsg)
    }
  }

  const getLessonParticipant = async (lessonId) => {
    try {
      const participant = await api.get(`/teacher/lessons/${lessonId}/participant`)
      setCallQualityTestCompleted(!!participant)
    }
    catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {

    if (lesson && token && !callJoined) {
      try {
        const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${lesson.room_name}`, token: token, allowMultipleCallInstances: true })
        if (callQualityTestCompleted) {
          joinCall(call);
        }
        else {
          preAuthCall(call);
        }
        // if (!socket) {
        //   let ws = new WebSocketClient(`lessons/${roomName}`, onSocketMessageReceived)
        //   ws.init()
        //   socket = ws
        //   document.addEventListener(`lessons/${roomName}-websocket-reconnecting`, onSocketReconnecting);
        //   document.addEventListener(`lessons/${roomName}-websocket-connected`, onSocketConnected);
        // }
      }
      catch (e) {
        console.error(e)
      }
    }

  }, [lesson, token, callQualityTestCompleted])

  const calculateMinutesDiff = (dateString) => {
    let now = new Date();
    let date = new Date(dateString);
    const offset = date.getTimezoneOffset()
    const userDate = new Date(date.getTime() + (offset * 60 * 1000))
    const diff = now - userDate
    const mDiff = parseInt(diff / (1000 * 60))
    setMinutesDiff(mDiff)
  }

  const getTests = useCallback(async (lessonId) => {
    try {
      const tests = await api.get(`/teacher/lessons/${lessonId}/tests`)
      setTests(tests)
    } catch (e) {
      console.error(e)
    }
  }, [])

  // const onSocketMessageReceived = (data) => {
  //   if (data.type === 'test_start' && data.testId === selectedTestRef?.current?.id) {
  //     setTestStarted(true)
  //     let t = structuredClone(selectedTestRef.current)
  //     t.expires_at = data.expires_at
  //     t.status = 'public'
  //     setSelectedTest(t)
  //   }
  //   if (data.type === 'test_submitted' && data.test_id) {
  //     setTests(
  //       testsRef.current.map(t => {
  //         if (t.id === data.test_id) {
  //           if (!t.users.map(u => u.id).includes(data.user.id)) {
  //             t.users.push(data.user)
  //           }
  //         }
  //         return t
  //       })
  //     )
  //   }
  //   if (data.type === 'error') {
  //     setAlert({
  //       open: true,
  //       title: "Errore",
  //       text: data.code === 1 ? 'Esiste già un test live, aspetta che sia completato per pubblicarne un altro.' : data.message,
  //     })
  //     if (data.code === 1) {
  //       setSelectedTest(null)
  //     }
  //   }
  //   if (data.type === 'whiteboard_started') {
  //     setWhiteboard({ show: true, readOnly: true, data: [], sessionId: data.session_id })
  //   }
  //   if (data.type === 'whiteboard_history') {
  //     setWhiteboard((prev) => {
  //       prev.data = data.data
  //       return { ...prev }
  //     })
  //   }
  //   if (data.type === 'whiteboard_changed') {
  //     setWhiteboard({ show: true, readOnly: true, data: data.data, sessionId: data.session_id })
  //   }
  //   if (data.type === 'whiteboard_stopped') {
  //     setWhiteboard((prev) => {
  //       prev.show = false
  //       prev.sessionId = null
  //       return { ...prev }
  //     })
  //   }

  // }

  // const onSocketReconnecting = () => {
  //   setReconnecting(true)
  // }

  // const onSocketConnected = () => {
  //   setReconnecting(false)
  // }

  useEffect(() => {
    if (selectedTest) {
      setTests(testsRef.current.map(t => {
        if (t.id === selectedTest.id) {
          t.expires_at = selectedTest.expires_at
          t.status = selectedTest.status
        }
        return t
      }))
    }
  }, [selectedTest])

  const renderNoLessonMessage = (message) => (
    <div className={styles.noLesson}>
      <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
        {message}
      </div>
    </div>
  );

  const getLessonComponent = () => {
    if (lesson) {
      if(!minutesDiff){
        return(
          <div className={styles.noLesson}>
            <div className={typo.headline} style={{ color: 'var(--background-color)', display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }}>
              <Spinner />
              Caricando...
            </div>
          </div>
        )
      }

      if (lesson.video_url) {
        return (
          <div className={styles.playerWrapper}>
            <VideoPlayer
              url={lesson.video_url}
              thumbnail={lesson.thumbnail}
              max={0}
              progressBlock={false}
              onProgress={() => { }}
            />
          </div>
        );
      }

      if (lesson.ended_at) {
        return renderNoLessonMessage(
          `La lezione è terminata ${formatDateV2(lesson.ended_at)} alle ${formatTimeV2(lesson.ended_at)}. La registrazione sarà disponibile a breve.`
        );
      }

      if (minutesDiff < -120) {
        return renderNoLessonMessage(
          `La lezione è programmata per le ore ${formatTimeV2(lesson.starts_at)} di ${formatDateV2(lesson.starts_at)} e sarà accessibile due ore prima dell'inizio.`
        );
      }

      if (roomError) {
        return renderNoLessonMessage(roomError);
      }

      if (!callQualityTestCompleted && callPreAuthorized) {
        return (
            <div className={styles.noLesson} style={{padding: '1rem'}}>
              <DailyProvider callObject={call}>
                <DailyTest lesson_id={lesson.id} onTestCompleted={() => {
                  setCallQualityTestCompleted(true);
                }} />
              </DailyProvider>
            </div>
        );
      }

      if (!callJoined) {
        return (
          <div className={styles.noLesson}>
            <div className={typo.headline} style={{ color: 'var(--background-color)', display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }}>
              <Spinner />
              Caricando...
            </div>
          </div>
        );
      }

      return (
        <DailyProvider callObject={call}>
          <DailyRoom
            token={token}
            lesson_id={lesson.id}
            roomName={roomName}
            students={students}
            onStudentsChange={setStudents}
            whiteboard={whiteboard}
            onWhiteboardChange={(localSessionId, whiteboardController, close) => {
              if (whiteboard.sessionId && whiteboard.sessionId !== localSessionId) {
                console.error("Lavagna occupata");
                setAlert({
                  open: true,
                  title: 'Lavagna Occupata',
                  status: DialogStatus.Default,
                  text: `La lavagna è attualemente controllata da ${whiteboardController}. Vuoi prendere forzatamente il controllo della lavagna?`,
                  actions: [{
                    label: "CONTROLLA",
                    onClick: () => {
                      setWhiteboard({ show: true, readOnly: false, data: [], sessionId: localSessionId });
                      setAlert({ open: false, title: null, text: null, actions: null });
                    }
                  }]
                });
              } else {
                setWhiteboard(prev => ({
                  ...prev,
                  show: close ? false : !prev.show,
                  readOnly: whiteboard.show ? false : prev.readOnly,
                }));
              }
            }}
            onCallRejoin={async () => call && await joinCall(call)}
            screenSharingRequests={screenSharingRequests}
            onScreenSharingRequestChange={(sessionId) => {
              setScreenSharingRequests(screenSharingRequestsRef.current.filter(s => s.sessionId !== sessionId));
            }}
          />
        </DailyProvider>
      );
    };
  }

  return (
    <HeaderFooterLayout>
      <div className={styles.container}>
        {lesson && (
          <>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-color)" }}
            >
              <div className={styles.sectionInner} style={{ paddingBottom: 0 }}>
                <div className={styles.videoPlaceholder}>
                  {getLessonComponent()}
                </div>
              </div>
            </div>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-color)" }}
            >
              <div className={styles.sectionInner} style={{ paddingBottom: 0 }}>
                <div className={styles.lessonHeader}>
                  <div className={styles.lessonTitle}>
                    <div className={typo.title}>
                      <CourseBadge type={lesson?.module.edition.course.type} />
                      {lesson?.module.name} - {lesson?.name}
                    </div>
                    <div className={styles.lessonDate}>
                    </div>
                    <div className={styles.lessonTime}>
                      {capitalize(formatDateV2(lesson.starts_at, { weekday: 'short', day: 'numeric', month: 'short' }))}
                      {', '}
                      {formatTimeV2(lesson.starts_at)} -{" "}
                      {formatTimeV2(lesson.ends_at)}
                      <ClockIcon style={{ minWidth: '16px', minHeight: '16px' }} />
                    </div>
                  </div>
                  {lesson?.description &&
                    <div className={styles.lessonDescription}>
                      <ReactMarkdown children={lesson.description} remarkPlugins={[remarkGfm]} />
                    </div>
                  }
                </div>
              </div>

              {materials.length > 0 &&
                <div className={styles.sectionInner} style={{ flexDirection: "column", paddingTop: "1rem" }}>
                  <div className={typo.subheadline}>
                    Materiali didattici
                  </div>
                  <div className={styles.materials}>
                    <Each of={materials} render={(item) =>
                      <div className={styles.material}>
                        <MaterialButton material={item} />
                      </div>
                    } />
                  </div>
                </div>
              }
              {/* 
              {tests.filter(t => t.status === 'draft').length > 0 && minutesDiff > -120 && !roomError && call &&
                <div className={styles.sectionInner} style={{ flexDirection: "column", paddingTop: "1rem" }}>
                  <div className={typo.subheadline}>
                    Test
                  </div>
                  {!selectedTestRef.current &&
                    <>
                      <div className={typo.body}>
                        Scegli un test da far svolgere ai tuoi studenti durante la lezione.
                      </div>
                      <div className={styles.tests} style={{ height: '350px' }}>
                        <div className={styles.testsTrack}>
                          <Each of={tests.filter(t => t.status === 'draft')} render={(item) =>
                            <div className={styles.material}>
                              <TestCard hideMenu selectable={item.publishable} test={item} onSelect={() => {
                                setSelectedTest(item)
                              }} />
                            </div>
                          } />
                        </div>
                      </div>
                    </>
                  }
                  {selectedTestRef.current &&
                    <div className={styles.tests} style={{ minHeight: '420px', height: 'auto' }}>
                      <div className={styles.testsTrack}>
                        <div className={styles.selectedTestCard}>
                          <TestCard hideMenu test={selectedTest} changeable={!testStarted} useVariant onChange={() => { setSelectedTest(null) }} />
                          <div className={styles.testInfo}>
                            <>
                              <div style={{ height: '100%' }}>
                                Seleziona una durata in minuti per il test.
                                Premendo il bottone agli studenti presenti nella lezione verrà mostrato direttamente il test da eseguire.
                                Alla scadenza preimpostata, tutti i test risulteranno consegnati.
                              </div>
                              <TextInput type="number" value={duration} placeholder={"Durata(m)"} max={999} onKeyUp={(value) => { setDuration(Number(value)) }} />
                              <Button
                                disabled={!duration}
                                fullWidth
                                onClick={() => {
                                  setTestStarted(true)

                                  if (socket) {
                                    socket.sendMessage({
                                      sender_type: ProfileType.Teacher,
                                      type: 'test_start',
                                      testId: selectedTestRef.current.id,
                                      duration: duration
                                    })
                                  }
                                }}
                              >
                                PUBBLICA
                              </Button>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }

              {tests.filter(t => t.status === 'public' && getLocalDate(t.expires_at) > new Date()).length > 0 &&
                <div className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                  <div className={typo.subheadline} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
                    Test in corso <LiveBadge />
                  </div>
                  <div className={styles.tests}>
                    <div className={styles.testsTrack}>
                      <Each of={tests.filter(t => t.status === 'public' && getLocalDate(t.expires_at) > new Date())} render={(test) => {
                        return (
                          <div className={styles.selectedTestCard}>
                            <TestCard hideMenu test={test} style={{ minWidth: '300px', pointerEvents: 'none' }} />

                            <div className={styles.testInfo}>
                              <div className={styles.testInfoTitle}>Consegnati {test.users.length}/{students.length}</div>
                              <div className={styles.testInfoStudents}>
                                <Each of={test.users} render={(student) => {
                                  return (
                                    <div className={styles.userRow}>
                                      <img src={student.picture ?? UserPlaceholder} alt="" />
                                      <div>{student.name} {student.surname}</div>
                                    </div>
                                  )
                                }} />
                              </div>
                            </div>
                          </div>
                        )
                      }} />
                    </div>
                  </div>
                </div>
              }
              {tests.filter(t => ['public', 'completed'].includes(t.status) && getLocalDate(t.expires_at) <= new Date()).length > 0 &&
                <div className={styles.sectionInner}>
                  <div className={typo.subheadline}>
                    Test Svolti
                  </div>
                  <div className={styles.tests}>
                    <div className={styles.testsTrack}>
                      <Each of={tests.filter(t => ['public', 'completed'].includes(t.status) && getLocalDate(t.expires_at) <= new Date())} render={(test) => {
                        return (
                          <div className={styles.selectedTestCard}>
                            <TestCard hideMenu test={test} style={{ minWidth: '300px', pointerEvents: 'none' }} />

                            <div className={styles.testInfo}>
                              <div className={styles.testInfoTitle}>
                                Consegnati {test.users.length}/{students.length}
                                <Button appearance="text"
                                  onClick={() => {
                                    window.open(`/tests/${test.id}`, "_blank")
                                  }}
                                >vedi tutto</Button>
                              </div>
                              <div className={styles.testInfoStudents}>
                                <Each of={test.users} render={(student) => {
                                  return (
                                    <div className={styles.userRow}>
                                      <img src={student.picture ?? UserPlaceholder} alt="" />
                                      <div>{student.name} {student.surname}</div>
                                    </div>
                                  )
                                }} />
                              </div>
                            </div>

                          </div>
                        )
                      }} />
                    </div>
                  </div>
                </div>
              } */}
            </div>
          </>
        )}
      </div>
      <AlertDialog
        open={alert.open}
        title={alert.status !== DialogStatus.Error ? alert.title : null}
        errorMessage={alert.status === DialogStatus.Error ? alert.title : null}
        status={alert.status}
        text={alert.text} onClose={() => {
          setAlert((prev) => {
            prev.open = false
            return { ...prev }
          })
        }} actions={alert.actions} />
    </HeaderFooterLayout >
  );
};

export default Lesson;
