import styles from "./DropdownSelection.module.css"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { useEffect, useMemo, useRef, useState } from "react"
import { Each } from "../common/Each"
import useClickOutside from "../common/hooks/useClickOutside"

const DropdownSelection = ({ disabled = false, options = [], placeholder = "", defaultOptionIndex = null, onSelect = () => { }, appereance = "default" }) => {
    const [value, setValue] = useState(null)
    const [open, setOpen] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        if (defaultOptionIndex !== null && options.length > 0 && defaultOptionIndex < options.length) {
            if (!value || (value && value.id !== defaultOptionIndex)) {
                setValue({
                    id: defaultOptionIndex,
                    label: options[defaultOptionIndex].label ?? options[defaultOptionIndex],
                    subtitle: options[defaultOptionIndex].subtitle
                })
            }
        } else {
            setValue(null)
        }
    }, [options, defaultOptionIndex])

    useEffect(() => {
        if (value !== null && value.id !== defaultOptionIndex) {
            onSelect(value?.id)
        }
    }, [value])

    useClickOutside(ref, () => setOpen(false))


    const className = useMemo(() => {
        switch (appereance) {
            case "default": return {
                container: styles.container,
                optionsContainer: styles.options,
                option: styles.option,
            }
            case "transparent": return {
                container: styles.transparent,
                optionsContainer: styles.inverseOptions,
                option: styles.inverseOption,
            }
            case "embedded": return {
                container: styles.embedded,
                optionsContainer: styles.inverseOptions,
                option: styles.inverseOption,
            }
            default: console.error("Invalid appereance"); break;
        }
    }, [])

    return (
        <div ref={ref} className={className.container}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            onClick={() => {
                if (!disabled) {
                    setOpen((open) => !open)
                }
            }}
            style={{ opacity: disabled ? "0.5" : "1", cursor: disabled ? "default" : "pointer" }}
        >
            {value &&
                <div className={styles.selectedOption}>
                    <div className={styles.title}>{value.label ?? "missing label"}</div>
                    {value.subtitle && <div className={styles.subtitle}>{value.subtitle ?? "missing label"}</div> }
                </div>
            }
            {
                !value && <div className={styles.placeholder}>{placeholder}</div>
            }
            <ChevronIcon className={`${styles.arrow} ${open ? styles.up : styles.down}`} />
            <div className={`${className.optionsContainer} ${open ? styles.open : styles.hidden}`}>
                <Each
                    of={options}
                    render={(option, index) => (
                        <>
                            <div className={className.option} onClick={() => {
                                setValue({
                                    id: index,
                                    label: option?.label ?? option,
                                    subtitle: option?.subtitle
                                })
                            }}>
                                <div className={styles.title}>
                                    {option?.label ?? option}
                                </div>
                                <div className={styles.subtitle}>
                                    {option?.subtitle ?? ""}
                                </div>
                            </div>
                            {index !== options.length - 1 && <div className={styles.divider} />}
                        </>
                    )}
                />
            </div>
        </div>
    )
}

export default DropdownSelection
