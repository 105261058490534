import { useState } from "react"
import Cropper from "react-easy-crop"
import Compressor from "compressorjs"
import Button from "./Button"
import { useTranslation } from "react-i18next"
import styles from "./ImageCropper.module.css"

const ImageCropper = ({ src, onSave, onCancel, compression, shape = 'round', aspect = 1 }) => {

    const { t } = useTranslation()
    const [crop, setCrop] = useState({ x: 0.5, y: 0.5 })
    const [croppedArea, setCroppedArea] = useState(null)
    const [zoom, setZoom] = useState(1)


    const onCropChange = (crop) => {
        setCrop(crop)
    }

    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels)
    }

    const onZoomChange = (zoom) => {
        setZoom(zoom)
    }

    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })

    function getRadianAngle(degreeValue) {
        return (degreeValue * Math.PI) / 180
    }

    function rotateSize(width, height, rotation) {
        const rotRad = getRadianAngle(rotation)

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    async function getCroppedImg(
        imageSrc,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return null
        }

        const rotRad = getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        const croppedCanvas = document.createElement('canvas')

        const croppedCtx = croppedCanvas.getContext('2d')

        if (!croppedCtx) {
            return null
        }

        // Set the size of the cropped canvas
        croppedCanvas.width = pixelCrop.width
        croppedCanvas.height = pixelCrop.height

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
            canvas,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )

        // As Base64 string
        // return croppedCanvas.toDataURL('image/jpeg');

        // As a blob
        return await new Promise((resolve, reject) => {
            croppedCanvas.toBlob((file) => {
                new Compressor(file, {
                    quality: compression.quality ?? 1, // Adjust the desired image quality (0.0 - 1.0)
                    maxWidth: compression.maxWidth ?? 1000, // Adjust the maximum width of the compressed image
                    maxHeight: compression.maxHeight ?? 1000, // Adjust the maximum height of the compressed image
                    mimeType: "image/png", // Specify the output image format
                    success(result) {
                        resolve(URL.createObjectURL(result));
                    },
                    error(error) {
                        reject(error);
                    },
                })
            }, 'image/png')
        })

    }

    const handleCancel = () => {
        if (onCancel && typeof onCancel === 'function') {
            onCancel()
        }
    }

    const handleSave = async () => {
        if (onSave && typeof onSave === 'function') {
            if (onSave && typeof onSave === 'function') {
                let img = await getCroppedImg(src, croppedArea)
                onSave(img)
            }
        }
    }

    return (
        <>
            <div className={styles.container}>
                <Cropper
                    image={src}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect}
                    cropShape={shape}
                    showGrid={false}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Button appearance="text" style={{ color: 'var(--tertiary)' }}
                    onClick={handleCancel}>
                    {t('profile.goBack')}
                </Button>
                <Button
                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                    additionalClass={styles.saveButton}
                    onClick={handleSave}
                >
                    {t('profile.confirmImage').toUpperCase()}
                </Button>
            </div>
        </>
    )

}

export default ImageCropper