import styles from "./FileUpload.module.css"
import { formatFileSize } from "../utils"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import { useState } from "react"
import Spinner from "./Spinner"

const FileUpload = ({ file, url, progress, onDelete = async () => { } }) => {

    const [loading, setLoading] = useState(false)

    return (
        <div className={styles.container}>
            <div className={styles.progress} style={{ width: `${progress}%` }} />
            <div className={styles.file}>
                <div className={styles.fileName}>
                    {file.name}
                </div>
                <div className={styles.fileSize}>
                    ({formatFileSize(file.size)})
                </div>
            </div>
            {progress < 100 &&
                <div className={styles.percent}>{progress}%</div>
            }
            {progress >= 100 && url && !loading &&
                <div className={styles.delete}>
                    <DeleteIcon className={styles.deleteIcon} onClick={async(e) => {
                        e.stopPropagation()
                        setLoading(true)
                        await onDelete(url)
                        setLoading(false)
                    }} />
                </div>
            }
            {loading && 
                <Spinner color="#000000"/>
            }

        </div>
    )
}

export default FileUpload