import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoBlack } from '../assets/images/velv-black.svg';
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import { auth } from "../firebase";
import typo from "../typography.module.css";
import style from "./Login.module.css";
import FooterLayout from "../components/layouts/FooterLayout";
import { passwordRegex } from "../common/constants";

const ActionHandler = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [mode, setMode] = useState(null)
    const [oobCode, setOobCode] = useState(null)
    const [apiKey, setApiKey] = useState(null)
    const [continueUrl, setContinueUrl] = useState(null)
    const [lang, setLang] = useState(null)

    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [error, setError] = useState(null)
    const [linkNotValid, setLinkNotValid] = useState(null)
    const [passwordReset, setPasswordReset] = useState(null)


    useEffect(() => {

        if(auth.currentUser){
            auth.signOut()
        }

        // Ottieni la query string dai parametri dell'URL
        const queryParams = new URLSearchParams(window.location.search);

        if (!queryParams.get('mode') || !queryParams.get('oobCode')) {
            setLinkNotValid(true)
            return
        }

        setMode(queryParams.get('mode'))
        setOobCode(queryParams.get('oobCode'))
        setApiKey(queryParams.get('apiKey'))
        setContinueUrl(queryParams.get('continueUrl'))
        setLang(queryParams.get('lang'))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function verifyCode() {
            if (auth && oobCode) {
                try {
                    await verifyPasswordResetCode(auth, oobCode)
                    setLinkNotValid(false)
                }
                catch (error) {
                    console.error(error)
                    setLinkNotValid(true)
                }
            }
        }
        verifyCode()
    }, [auth, oobCode])

    const handlePasswordReset = async () => {
        try {
            if (!checkPasswords()) {
                setError({ code: 3, message: t('errors.passwordNotEquals') });
                return;
            }
            if (!validatePassword(password)) {
                setError({ code: 2, message: t('errors.invalidPassword') });
                return;
            }
            await verifyPasswordResetCode(auth, oobCode)
            await confirmPasswordReset(auth, oobCode, password)
            setPasswordReset(true)
        }
        catch (error) {
            setError({ code: -1, message: error.message })
            setPasswordReset(false)
        }
    }

    const onPasswordChange = (value) => {
        setPassword(value);
        if (password.length > 0 || repeatPassword.length > 0) {
            if (!validatePassword(value)) {
                setError({ code: 2, message: t('errors.invalidPassword') });
            } else if (!checkPasswords()) {
                setError({ code: 3, message: t('errors.passwordNotEquals') });
            } else {
                setError(null);
            }
    }
    };

    const onRepeatPasswordChange = (value) => {
        setRepeatPassword(value);
        if (password.length > 0 || repeatPassword.length > 0) {
            if (!validatePassword(password)) {
                setError({ code: 2, message: t('errors.invalidPassword') });
            }
            else if (!checkPasswords()) {
                setError({ code: 3, message: t('errors.passwordNotEquals') });
            } else {
                setError(null);
            }
    }
    };

    const checkPasswords = () => {
        return (password.length > 0 && repeatPassword.length > 0) ? password === repeatPassword : true
    }

    const validatePassword = (password) => {
        return passwordRegex.test(password);
    };

    const goToLogin = () => {
        navigate('/login')
    }


    return (
        <FooterLayout>
            <div className={style.container}>
                <div className={style.columnLeft}>

                    <div className={style.innerLeft}>
                        <div className={style.logoContainer}>
                            <LogoBlack className={style.logo} />
                        </div>
                        <div className={style.spacer}></div>

                        {linkNotValid === true &&
                            <>
                                <div className={[typo.title, style.title].join(' ')}>{t('errors.linkNotValid')}</div>
                            </>
                        }
                        {!linkNotValid && !passwordReset &&

                            <>
                                <div className={[typo.title, style.title].join(' ')}>{t('resetPassword.resetPassword')}</div>

                                <div className={style.formContainer}>
                                    <TextInput error={error?.code === 2} type="password" placeholder={t('resetPassword.password')} onKeyUp={onPasswordChange} autocomplete="new-password"></TextInput>
                                    <TextInput error={[2,3].includes(error?.code)} type="password" placeholder={t('resetPassword.repeatPassword')} onKeyUp={onRepeatPasswordChange} autocomplete="new-password"></TextInput>
                                    <div className={style.resetPassword}>

                                        <div className={style.error}>
                                            {error &&
                                                <span className={typo.caption}>
                                                    {error.message}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <Button onClick={handlePasswordReset} fullWidth>{t('resetPassword.save').toUpperCase()}</Button>
                            </>
                        }
                        {passwordReset === true &&
                            <>
                                <div className={[typo.title, style.title].join(' ')}>Password reimpostata correttamente</div>
                                <span className={typo.caption}>La tua password è stata reimpostata correttamente, puoi procedere alla login.</span>
                                <Button onClick={() => { navigate('/login') }} style={{ padding: '1rem 1.5rem' }} fullWidth>VAI ALLA LOGIN</Button>
                            </>
                        }
                        <div className={style.spacer}></div>
                        <div className={style.registerContainer}>
                            <Button appearance="text" onClick={goToLogin}>{t('resetPassword.backToLogin')}</Button>
                        </div>


                    </div>
                </div>
                <div className={style.columnRight}>
                    <div className={style.innerRight}>
                        <div className={style.redCircle}></div>
                        <div className={[style.rightTitle, typo.title].join(' ')}>{t('login.contentTitle')}</div>
                        <div className={[typo.body, style.rightBody].join(' ')}>{t('login.contentSubtitle')}</div>
                        <div className={style.mockup}></div>
                    </div>
                </div>
            </div>
        </FooterLayout>
    )
}

export default ActionHandler
