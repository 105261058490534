import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg";
import { Each } from "../common/Each";
import typo from "../typography.module.css";
import Button from "./Button";
import Card from "./cards/Card";
import LessonCard from "./cards/LessonCard";
import styles from "./LessonsCarousel.module.css";
import Loader from "./Loader";
import AlertDialog from './dialogs/AlertDialog';

const LessonsCarousel = ({ lessons, loading }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const splideRef = useRef(null)
    const [openLesson, setOpenLesson] = useState(null)

    return (
        <div>
            <Card style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                <div className={styles.header}>
                    <div className={typo.subtitle} style={{ display: 'flex', fontSize: '1rem' }}>{t('courses.nextLessons')}</div>
                    {lessons.length > 0 &&
                        <div className={styles.arrows}>
                            <button
                                className={`${styles.arrowButton} ${styles.left}`}
                                onClick={() => {
                                    if (splideRef && splideRef.current) {
                                        // let index = splideRef.current.splide.index
                                        splideRef.current.splide.go('<')
                                    }
                                }}
                            >
                                <ChevronIcon />
                            </button>
                            <button
                                className={`${styles.arrowButton} ${styles.right}`}
                                onClick={() => {
                                    if (splideRef && splideRef.current) {
                                        // let index = splideRef.current.splide.index
                                        splideRef.current.splide.go('>')
                                    }
                                }}>
                                <ChevronIcon />
                            </button>
                        </div>
                    }
                </div>
                <div className={styles.content}>
                    {lessons.length > 0 && !loading &&
                        <Splide
                            id={styles.lessonCarousel}
                            ref={splideRef}
                            options={
                                {
                                    pagination: true,
                                    perPage: 4,
                                    perMove: 4,
                                    gap: '1rem',
                                    breakpoints: {
                                        1440: {
                                            perPage: 3,
                                            perMove: 3,
                                        },
                                        1120: {
                                            perPage: 2,
                                            perMove: 2,
                                        },
                                        830: {
                                            perPage: 1,
                                            perMove: 1,
                                        },
                                        768: {
                                            perPage: 2,
                                            perMove: 2,
                                        },
                                        540: {
                                            perPage: 1,
                                            perMove: 1,
                                        }
                                    }
                                }
                            }
                        >
                            <Each of={lessons} render={(lesson) => {
                                return (
                                    <SplideSlide className={styles.lessonSlide}>
                                        <LessonCard lesson={lesson} onClick={() => {
                                            setOpenLesson(lesson)
                                        }}/>
                                    </SplideSlide>
                                )
                            }} />
                        </Splide>
                    }
                    {lessons.length === 0 && !loading &&
                        <div className={styles.noLessons}>
                            <div>{t('courses.noLessons')}<br></br>{t('courses.noLessonsAdvice')}</div>
                            <Button
                                onClick={() => { navigate("/messages") }}
                                style={{ display: 'flex', alignItems: 'center', padding: '.75rem 3rem' }} inverse>
                                {t('courses.contact').toUpperCase()}
                            </Button>

                        </div>
                    }
                    {loading &&
                        <div className={styles.loaderContainer}>
                            <Loader />
                        </div>
                    }
                </div>
            </Card>
            <AlertDialog
                open={openLesson}
                onClose={() => setOpenLesson(null)}
                title="Nuova Modalità Side by Side"
                text="La modalità Side by Side ti permette di avere tutti i controlli della chiamata in una finestra separata da poter affiancare al tuo materiale durante la condivisione. Vuoi attivarla o continuare con la modalità classica ?"
                actions={[
                    {
                        label: "SIDE BY SIDE",
                        onClick: () => {
                            setOpenLesson(null)
                            window.open(
                                `/lessons/${openLesson.room_name}/side`, // URL della finestra da aprire
                                "_blank", // Apre in una nuova finestra
                                "width=250,height=900,toolbar=yes,location=yes,status=yes,menubar=no,scrollbars=yes"
                            );
                        }
                    },
                    {
                        label: "CLASSICA",
                        onClick: () => {
                            setOpenLesson(null)
                            navigate(`/lessons/${openLesson.room_name}`)
                        }
                    }
                ]}
            />
        </div>
    )

}

export default LessonsCarousel