import axios from "axios";
import { auth } from "./firebase"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: "30000"
})

api.interceptors.request.use(async (config) => {
  // Ottieni il token

  if (!auth.currentUser && !config.url.includes("reset-password")) {
    console.error(`Current User is null during ${config.url}`)
  }
  if (!config.url.includes("reset-password")) {
    const tokenResult = await auth.currentUser.getIdTokenResult()
    const token = tokenResult.token

    // Aggiungi il token all'header Authorization
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  // Gestisci errori nell'interceptor
  return Promise.reject(error);
});

api.interceptors.response.use(async (response) => {
  if (![200, 201, 204].includes(response.status)) {
    return Promise.reject(response.data)
  }
  return response.data
}, async (error) => {
  if (error.response.data) {
    document.dispatchEvent(new CustomEvent('api-error', { detail: error.response.data }));
    if (error.response.data.code === 9) {
      console.debug("Profile suspended. Force logout")
      await auth.signOut()
      if (window.location.pathname !== "/login") {
        window.location.reload()
      }
    }
    return Promise.reject(error.response.data)
  }


  return Promise.reject(error)

})

export default api;
