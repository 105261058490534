import { useParams } from "react-router"
import styles from "./LessonSide.module.css"
import { useContext, useEffect, useState } from "react"
import Daily from "@daily-co/daily-js";
import api from "../api";
import { darkPalette } from "../common/constants";
import MainContext from "../common/MainContext";
import typo from "../typography.module.css"
import { formatDateV2, formatTimeV2 } from "../utils";
import Spinner from "../components/Spinner";
import { DailyProvider } from "@daily-co/daily-react";
import DailyRoomSide from "../components/daily/DailyRoomSide";
import usePrompt from "../common/hooks/usePrompt";
import DailyTest from "../components/daily/DailyTest";

var interval = null

const LessonSide = () => {

    const { roomName } = useParams()
    const location = window.location

    const context = useContext(MainContext)

    const [lesson, setLesson] = useState(null)
    const [token, setToken] = useState(null)
    const [call, setCall] = useState(null)
    const [roomError, setRoomError] = useState(null)
    const [secondsDiff, setSecondsDiff] = useState(null)
    const [loadingRoom, setLoadingRoom] = useState(true)
    const [students, setStudents] = useState([])
    const [callQualityTestCompleted, setCallQualityTestCompleted] = useState(false)
    const [callJoined, setCallJoined] = useState(false)
    const [callPreAuthorized, setCallPreAuthorized] = useState(false)

    usePrompt('Sei sicuro di voler uscire?', true)

    useEffect(() => {
        const getLesson = async (roomName) => {
            try {
                const lesson = await api.get(`/teacher/lessons/${roomName}`);
                setLesson(lesson)
            } catch (e) {
                console.error(e)
            }
        };

        if (roomName) {
            getLesson(roomName);
        }
    }, [roomName]);

    useEffect(() => {
        const getToken = async (lessonId) => {
            try {
                const token = await api.get(`/teacher/lessons/${lessonId}/token`)
                setToken(token.token)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getStudents = async (moduleId) => {
            try {
                let students = await api.get(`teacher/modules/${moduleId}/students`)
                setStudents(students)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (lesson) {
            getToken(lesson.id)
            getStudents(lesson.module.id)
            getLessonParticipant(lesson.id)

            interval = setInterval(() => {
                calculateDiff(lesson.starts_at)
            }, 1000)
        }

    }, [lesson])

    useEffect(() => {
        return () => {
            if (interval) {
                clearInterval(interval);
                interval = null;
            }
            if (call) {
                call.leave();
                call.destroy();
            }
        };
    }, [call, location]);

    useEffect(() => {

        if (lesson && token && !call) {
            try {
                console.log('lesson enter')
                const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${lesson.room_name}`, token: token })
                if (callQualityTestCompleted) {
                    console.log('lesson join')
                    joinCall(call);
                }
                else {
                    console.log('lesson preAuth')
                    preAuthCall(call);
                }
            }
            catch (e) {
                console.error(e)
            }
        }

    }, [lesson, token])

    useEffect(() => {
        if (secondsDiff < -120 && !call && token) {
            try {
                console.log('secondsDiff enter')
                const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${lesson.room_name}`, token: token })
                if (callQualityTestCompleted) {
                    console.log('secondsDiff join')
                    joinCall(call);
                }
                else {
                    console.log('secondsDiff preAuth')
                    preAuthCall(call);
                }
            }
            catch (e) {
                console.error(e)
            }
        }
    }, [secondsDiff])

    useEffect(() => {

        if(callQualityTestCompleted && call){
            joinCall(call)
        }

    }, [callQualityTestCompleted])

    const joinCall = async (call) => {
        try {
            await call.join()
            const randomColor = darkPalette[Math.floor(Math.random() * darkPalette.length)];
            context.setUser((prev) => {
                prev.color = prev.color ?? randomColor
                return { ...prev }
            })
            call.setUserName(`${context.user?.name} ${context.user?.surname}`)
            call.setUserData({ ...context.user, color: context.user?.color ?? randomColor })
            setCall(call)
            setCallJoined(true)
        }
        catch (e) {
            console.error(e)
            setRoomError(e.error)
        }
        setLoadingRoom(false)
    }

    const preAuthCall = async (call) => {
        console.debug('preAuthCall')
        try {
            await call.preAuth({ url: `https://startingfinance.daily.co/${lesson.room_name}`, token: token })
            setCall(call)
            setCallPreAuthorized(true)
            setLoadingRoom(false)
        }
        catch (e) {
            setLoadingRoom(false)
            console.error(e)
            setRoomError(e.error)
        }
    }

    const getLessonParticipant = async (lessonId) => {
        try {
            const participant = await api.get(`/teacher/lessons/${lessonId}/participant`)
            setCallQualityTestCompleted(!!participant)
        }
        catch (e) {
            console.error(e)
        }
    }

    const calculateDiff = (dateString) => {
        let now = new Date();
        let date = new Date(dateString);
        const offset = date.getTimezoneOffset()
        const userDate = new Date(date.getTime() + (offset * 60 * 1000))
        const diff = parseInt((userDate.getTime() - now.getTime()) / 1000)
        setSecondsDiff(diff)
    }

    const formatSeconds = (seconds) => {
        if (!seconds) return '00:00';

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);

        if (hours > 0) {
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        } else {
            return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        }
    };


    return (
        <>
            {lesson && !loadingRoom &&
                <div className={styles.container}>
                    <div className={styles.section}>
                        {/* <div className={styles.header}>
                            <div className={typo.subtitle}>{lesson.name}</div>
                            <div className={typo.headline} style={{ color: 'var(--background-color)' }}>{formatTimeV2(lesson?.starts_at)} - {formatTimeV2(lesson?.ends_at)}</div>
                        </div> */}
                        {roomError &&
                            <>
                                {roomError.type === 'exp-room' &&
                                    <div className={[typo.headline, styles.roomError].join(' ')}>
                                        Questa lezione è terminata il {formatDateV2(lesson.ended_at ?? lesson.ends_at, {}, 'it', false)} alle {formatTimeV2(lesson.ended_at ?? lesson.ends_at)}
                                    </div>
                                }
                                {roomError.type === 'nbf-room' &&
                                    <div className={[typo.headline, styles.roomError].join(' ')}>
                                        Questa lezione è programmata per il {formatDateV2(lesson.starts_at, {}, 'it', false)} alle {formatTimeV2(lesson.starts_at)} e sarà disponibile tra {formatSeconds(secondsDiff)}
                                    </div>
                                }
                            </>
                        }
                        {!roomError &&
                            <>
                                {call && callJoined && callQualityTestCompleted &&
                                    <DailyProvider callObject={call}>
                                        <DailyRoomSide
                                            lesson_id={lesson.id}
                                            roomName={lesson.room_name}
                                            students={students}
                                            onStudentsChange={(students) => {
                                                setStudents(students)
                                            }}
                                        />
                                    </DailyProvider>
                                }
                                {call && !callJoined && !callQualityTestCompleted &&
                                    <DailyProvider callObject={call}>
                                        <DailyTest lesson_id={lesson.id} sideBySide={true} onTestCompleted={() => {
                                            setCallQualityTestCompleted(true);
                                        }} />
                                    </DailyProvider>}
                            </>
                        }
                    </div>
                </div>
            }
            {(!lesson || loadingRoom) &&
                <div className={styles.container}>
                    <div className={styles.section}>
                        <div className={styles.loading}>
                            <Spinner color="white"></Spinner>
                            <div className={typo.headline} style={{ color: 'var(--background-color)' }}>connessione...</div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}

export default LessonSide