import { useEffect, useState } from "react";
import styles from "./Audio.module.css";
import { Each } from "../../common/Each";

const Audio = ({ level }) => {

    const [randoms, setRandoms] = useState([Math.random(), Math.random(), Math.random(), Math.random(), Math.random()])

    useEffect(() => {
        setRandoms([Math.random(), Math.random(), Math.random(), Math.random(), Math.random()])
    }, [level])

    return (
        <div id={styles.bars}>
            <Each of={Array(5).fill(0)} render={(item, index) => {
                return (
                    <div className={styles.bar} style={{ height: (level * 300 * randoms[index] > 40 ? 40 : level * 300 * randoms[index]) }}></div>
                )
            }} />
        </div>
    )

}

export default Audio;