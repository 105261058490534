import { DailyVideo, useDaily, useScreenShare } from "@daily-co/daily-react";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as CamOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg";
import { Each } from "../../common/Each";
import styles from "./DailySpotlighted.module.css";
import PDFCanvas from "./PDFCanvas";

const DailySpotlighted = ({ file, spotlighted, onRemoveSpotlighetd, sideBySide = false }) => {

    const call = useDaily()
    const [participants, setParticipants] = useState(null)
    const [host, setHost] = useState(null)
    const [showSelfView, setShowSelfView] = useState(false)
    const [expanded, setExpanded] = useState(null)
    const [innerSpotlighted, _setInnerSpotlighted] = useState(spotlighted)
    const innerSpotlightedRef = useRef(innerSpotlighted)
    const setInnersSpotlighted = data => {
        innerSpotlightedRef.current = data
        _setInnerSpotlighted(data)
    }
    const { isSharingScreen } = useScreenShare()

    useEffect(() => {
        if (call) {
            call.on('participant-updated', () => {
                retrieveParticipants()
            })
            call.on('participant-left', () => {
                retrieveParticipants()
            })
        }
    }, [call])

    const retrieveParticipants = () => {
        let all = call.participants()
        let callParticipants = Object.values(all)
        let updated_participants = []
        for (let s of innerSpotlightedRef.current) {
            if (callParticipants.find(p => p.session_id === s.session_id)) {
                let participant = {
                    ...callParticipants.find(p => p.session_id === s.session_id),
                    pinned: s.pinned,
                    selfViewExpanded: false
                }

                updated_participants.push(participant)
            }
        }
        setParticipants(updated_participants)
        setHost(updated_participants.find(p => p.tracks?.screenVideo?.state === 'playable'))
    }

    useEffect(() => {
        setInnersSpotlighted(spotlighted)
        retrieveParticipants()
    }, [spotlighted])


    return (
        <>
            {participants && participants.length > 0 &&
                <div className={`${styles.container} ${sideBySide ? styles.sideBySide : ''}`}>
                    {!host &&
                        <Each of={participants} render={(participant, index) => {
                            return (
                                <div className={`${styles.stream} ${participants.length > 1 ? styles.multi : ''} ${participants.length > 2 ? styles.half : ''}`}
                                    style={{ backgroundColor: participant.userData?.color && (participant.tracks?.video?.state === 'off' && participant.tracks?.screenVideo?.state === 'off') ? (participant.userData?.color ?? 'black') : 'black' }}>
                                    {(participant.tracks?.video?.state === 'playable' || participant.tracks?.screenVideo?.state === 'playable') && !file &&
                                        <DailyVideo
                                            automirror={participant.local}
                                            type={participant.tracks?.screenVideo?.state === 'playable' ? 'screenVideo' : 'video'}
                                            sessionId={participant.session_id}
                                            style={{ display: 'flex', objectFit: 'contain', margin: '0 auto', maxWidth: '100%' }} />
                                    }
                                    {file &&
                                        <PDFCanvas file={file} call={call} />
                                    }

                                    {!participant.video && !(participant.tracks?.screenVideo?.state === 'playable') &&
                                        <CamOffIcon className={styles.cameraOff} />
                                    }
                                    {participant.userData &&
                                        <div className={styles.participantName}>{participant.local ? `${participant.userData?.name ?? ''} ${participant.userData?.surname ?? ''} (Tu)` : `${participant.userData?.name} ${participant.userData?.surname}`}</div>
                                    }
                                    <div className={`${styles.micBadge} ${participant.tracks?.audio?.state === 'off' ? styles.disabled : ''}`}>
                                        {participant.tracks?.audio?.state === 'playable' ? <MicIcon /> : <MicOffIcon />}
                                    </div>

                                    {participant.pinned &&
                                        <div className={styles.pinBadge}>
                                            <PinIcon />
                                        </div>
                                    }
                                    {!participant.pinned && participants.length > 1 &&
                                        <div className={styles.closeStream} onClick={() => {
                                            if (!participant.pinned && participants.length > 1) {
                                                onRemoveSpotlighetd(participant.session_id)
                                            }
                                        }}>
                                            <CloseIcon />
                                        </div>
                                    }
                                </div>
                            )
                        }} />
                    }
                    {host &&
                        <>
                            <div className={styles.stream}
                                style={{ backgroundColor: host.userData?.color && (!host.video && host.tracks?.screenVideo?.state === 'off') ? (host.userData?.color ?? 'black') : 'black' }}>
                                {!file &&
                                    <DailyVideo
                                        automirror={false}
                                        type={'screenVideo'}
                                        sessionId={host.session_id}
                                        style={{ display: 'flex', objectFit: 'contain', width: '100%', height: '100%' }} />
                                }
                                {file &&
                                    <PDFCanvas file={file} call={call} />
                                }

                                <div className={styles.hostScreenshareName}>
                                    {host.userData?.name} {host.userData?.surname} - Condivisione
                                </div>
                            </div>

                            {!expanded &&
                                <div className={`${styles.userTip} ${showSelfView ? styles.open : ''}`} onClick={() => {
                                    setShowSelfView(!showSelfView)
                                }}>
                                    <ChevronIcon className={`${styles.chevron} ${showSelfView ? styles.open : ''}`} />
                                </div>
                            }

                            {host.tracks?.screenVideo?.state === 'playable' &&
                                <div className={`${styles.selfViews} ${expanded ? styles.expanded : ''} ${showSelfView ? styles.open : ''}`}>
                                    <Each of={participants.sort((a, b) => {
                                        if (a.session_id === host.session_id) return 1; // Porta l'host in cima
                                        if (b.session_id === host.session_id) return -1;
                                        return 0; // Mantieni l'ordine degli altri
                                    })} render={(participant, index) => {
                                        return (
                                            <>
                                                <div className={`${styles.selfViewWrapper} ${expanded === participant.session_id ? styles.expanded : ''} ${expanded && expanded !== participant.session_id ? styles.hidden : ''}`}>
                                                    <div className={`${styles.selfView} ${expanded === participant.session_id ? styles.expanded : ''}`}
                                                        onClick={() => {
                                                            setExpanded(participant.session_id)
                                                        }}>
                                                        {participant.video &&
                                                            <DailyVideo
                                                                automirror={participant.local}
                                                                type={'video'}
                                                                sessionId={participant.session_id}
                                                                style={{
                                                                    display: 'flex',
                                                                    objectFit: 'cover',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    background: participant.video ? 'black' : participant.userData?.color ?? 'black'
                                                                }} />
                                                        }
                                                        {!participant.video &&
                                                            <>
                                                                <div style={{ backgroundColor: participant.userData?.color ?? 'black', width: '100%', height: '100%' }}></div>
                                                                <CamOffIcon className={styles.cameraOffSmall} />
                                                            </>
                                                        }

                                                    </div>
                                                    <div className={styles.name}>
                                                        {participant.local ? `${participant.userData?.name ?? ''} ${participant.userData?.surname ?? ''} (Tu)` : `${!participant.owner ? `${participant.userData?.name ?? ''} ${isSharingScreen ? participant.userData?.surname?.[0] + '.' : participant.userData?.surname ?? ''}` : `${participant.userData?.name} ${participant.userData?.surname}`}`}
                                                        <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                                                            {participant.audio ? <MicIcon /> : <MicOffIcon />}
                                                        </div>
                                                    </div>
                                                    {expanded &&
                                                        <div
                                                            className={styles.closeSelfView}
                                                            onClick={() => {
                                                                setExpanded(null)
                                                            }}>
                                                            <CloseIcon />
                                                            Torna alla condivisione
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        )
                                    }} />
                                </div>
                            }
                        </>
                    }
                </div>
            }
        </>
    )
}

export default DailySpotlighted