import React from "react";
import api from "../api";
import Error from "../pages/Error";
import { UAParser } from "ua-parser-js";
import MainContext from "../common/MainContext";
import * as StackTrace from "stacktrace-js";

class ErrorBoundary extends React.Component {

    static contextType = MainContext;

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    async componentDidCatch(error, errorInfo) {
        console.log("Error caught in Error Boundary:", error, errorInfo, this.context);

        const parser = new UAParser();
        const result = parser.getResult();

        // Cattura lo stack trace dettagliato
        const stackFrames = await StackTrace.fromError(error);
        const detailedStack = stackFrames.map(frame => frame.toString()).join('\n');

        const errorDetails = {
            message: error.message,
            stack: detailedStack,
            user_id: this.context.user ? this.context.user.id : null,
            user_name: this.context.user ? `${this.context.user.name} ${this.context.user.surname}` : null,
            platform: "teachers",
            page: window.location.pathname,
            device: result
        };

        try {
            await api.post("/error", errorDetails);
        } catch (err) {
            console.error("Failed to send error report:", err);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Error />
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;