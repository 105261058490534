import { useTranslation } from "react-i18next"
import Card from "./Card"
import styles from "./MaterialFeedCard.module.css"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import MaterialCard from "./MaterialCard"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as FolderIcon } from "../../assets/images/icons/ic-folder.svg"
import { calcLastUpdate } from "../../utils"
import { Each } from "../../common/Each"
import { useEffect, useState } from "react"
import api from "../../api"
import Markdown from "react-markdown"

const MaterialFeedCard = ({ activity, onChange = () => { } }) => {

  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const [modules, setModules] = useState([])
  const [loadingModules, setLoadingModules] = useState(false)

  useEffect(() => {
    const getLessons = async () => {
      setLoadingModules(true)
      try {
        const lessons = await api.get("/teacher/lessons");
        const lessonsPerModule = {}

        // group lessons by moduleId
        for (const lesson of lessons) {
          if (lesson.module.id in lessonsPerModule) {
            lessonsPerModule[lesson.module.id].lessons.push(lesson)
          } else {
            lessonsPerModule[lesson.module.id] = {
              id: lesson.module.id,
              label: lesson.module.name,
              lessons: [lesson]
            }
          }
        }
        const modules = Object.values(lessonsPerModule)
        setModules(modules)
      } catch (e) {
        console.error(e);
      }
      setLoadingModules(false)
    }

    getLessons()
  }, [])

  return (
    <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
      <div className={styles.cardHeader}>
        <div className={`${styles.icon} ${styles.material}`}>
          <FolderIcon />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>
            {t("materials.title")}
          </div>
          <div className={styles.subtitle}>


            {activity.object.length} file
          </div>
        </div>
        <div className={styles.date}>
          {calcLastUpdate(activity.created_at, width > 768 ? false : true, true)}
          <ClockIcon className={styles.clockIcon} />
        </div>
      </div>
      <div className={styles.content} style={{ padding: '1rem' }}>
        {
          activity.info?.message &&
          <div className={styles.message}>
            <Markdown>
              {activity.info.message}
            </Markdown>
          </div>
        }
        <Each
          of={activity.object}
          render={(material) => (
            <MaterialCard
              material={{ ...material, module: { id: activity.module_id } }}
              modules={modules}
              loadingModules={loadingModules}
              onDelete={() =>
                onChange({ activity_id: activity.id, deleted: activity.object.length === 1 }) // se elimini l'ultimo materiale allora elimini la card
              }
              onEdit={() => onChange({ activity_id: activity.id })} />
          )}
        />
      </div>
    </Card>
  )
}

export default MaterialFeedCard
