import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import TextInput from "../components/TextInput";
import { ModulePages } from "../enums";
import styles from "./ModuleMaterials.module.css";
import MaterialCard from "../components/cards/MaterialCard";


const ModuleMaterials = ({ onChangeTabValue = () => { }, prefetchedMaterials = null }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { moduleId } = useParams()

  const [materials, setMaterials] = useState(prefetchedMaterials)
  const [filteredMaterials, setFilteredMaterials] = useState(prefetchedMaterials)
  const [loading, setLoading] = useState(false)
  const [modules, setModules] = useState([])
  const [loadingModules, setLoadingModules] = useState(false)

  useEffect(() => {
    const getMaterials = async () => {
      setLoading(true)
      try {
        const materials = await api.get(`/teacher/modules/${moduleId}/materials`)
        setMaterials(materials.map(m => {
          return {
            ...m,
            module: { id: parseInt(moduleId) }
          }
        }))
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
    }

    const getLessons = async () => {
      setLoadingModules(true)
      try {
        const lessons = await api.get("/teacher/lessons");
        const lessonsPerModule = {}

        // group lessons by moduleId
        for (const lesson of lessons) {
          if (lesson.module.id in lessonsPerModule) {
            lessonsPerModule[lesson.module.id].lessons.push(lesson)
          } else {
            lessonsPerModule[lesson.module.id] = {
              id: lesson.module.id,
              label: lesson.module.name,
              lessons: [lesson]
            }
          }
        }
        const modules = Object.values(lessonsPerModule)
        setModules(modules)
      } catch (e) {
        console.error(e);
      }
      setLoadingModules(false)
    }

    window.scrollTo({
      top: 0,
    })

    if (!prefetchedMaterials) {
      getMaterials()
    }
    getLessons()
  }, [])

  useEffect(() => {
    if (materials && materials.length > 0) {
      onChangeTabValue(ModulePages.Materials, materials.length);
      setFilteredMaterials(materials);
    }
  }, [materials]);

  useEffect(() => {
    return () => onChangeTabValue(ModulePages.Materials, null)
  }, [])


  const onSearch = useCallback(
    (text) => {
      if (materials && materials.length > 0) {
        if (text) {
          const filter = materials.filter((s) => {
            const target = `${s.name}`.toLowerCase().replaceAll(" ", "");
            const query = text ? text.toLowerCase().replaceAll(" ", "") : text
            return target.includes(query);
          });
          setFilteredMaterials(filter);
        } else {
          setFilteredMaterials(materials);
        }
      }
    },
    [materials]
  );

  const onDelete = useCallback((materialId) => {
    setLoading(true)
    const newMaterials = materials.filter((m) => m.id !== materialId)
    setMaterials([...newMaterials])
    setFilteredMaterials([...newMaterials])
    setLoading(false)

  }, [materials])

  const onEdit = useCallback((material) => {
    setLoading(true)
    const idx = materials.findIndex((m) => m.id === material.id)

    if (idx >= 0) {
      let newMaterials = [...materials]
      if (materials[idx].module.id !== material.module.id) {
        newMaterials = newMaterials.filter((m) => m.id !== material.id)
      } else {
        newMaterials[idx] = material
      }
      setMaterials(newMaterials)
      setFilteredMaterials(newMaterials)
    }
    setLoading(false)
  }, [materials])

  return (
    <div className={styles.container}>
      <TextInput
        onKeyUp={onSearch}
        type="search"
        placeholder={"Cerca..."}
        style={{
          backgroundColor: "var(--background-secondary-color)",
        }}
      />
      <div className={styles.materials}>
        {
          loading &&
          <Each
            of={[1, 2, 3]}
            render={() => (
              <MaterialCard loading={true} />
            )}
          />
        }
        {
          loading === false &&
          <Each
            of={filteredMaterials}
            render={(material) => (
              <MaterialCard
                material={{ ...material, module: { id: parseInt(moduleId) } }}
                modules={modules}
                loadingModules={loadingModules}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            )}
          />
        }
      </div>
    </div>
  );
};

export default ModuleMaterials;
