import { useEffect } from 'react';

function useBeforeUnload(message, when = true) {

    useEffect(() => {
        if (!when) return;

        const handleBeforeUnload = (event) => {
            event.returnValue = message;
            return message;
        };

        const handlePopState = (event) => {
            const confirmLeave = window.confirm(message);
            if (confirmLeave) {
                window.history.go(-1)
            }
            event.preventDefault();
            window.history.pushState({ noBack: true }, document.title, window.location.href);
        };

        window.history.pushState({ noBack: true }, document.title, window.location.href);

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [message, when]);

}

export default useBeforeUnload;
