import { useDaily, useMeetingSessionState, useWaitingParticipants } from '@daily-co/daily-react';
import { useContext, useEffect, useState } from 'react';
import api from '../../api';
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg";
import { ReactComponent as NotificationActiveIcon } from "../../assets/images/icons/ic-notification-active.svg";
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg";
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png";
import { palette } from "../../common/constants";
import { Each } from '../../common/Each';
import useWindowDimensions from '../../common/hooks/useWindowDimensions';
import MainContext from '../../common/MainContext';
import typo from "../../typography.module.css";
import Button from '../Button';
import DropdownSelection from '../DropdownSelection';
import MultiStateSwitch from '../MultiStateSwitch';
import TooltipWrapper from "../TooltipWrapper";
import styles from './DailyList.module.css';
import DailyParticipant from './DailyParticipant';


const DailyList = ({ open, roomName, pinned, students, onPinnedChange, onStudentsChange, onWaitingNotification, sideBySide, websocket, lesson_id }) => {

    const call = useDaily()
    const context = useContext(MainContext)
    const [roomInfo, setRoomInfo] = useState(null)
    const { width } = useWindowDimensions()
    const { waitingParticipants, grantAccess } = useWaitingParticipants()
    const [parsedWaitingParticipants, setParsedWaitingParticipants] = useState([])
    const { data: sessionData } = useMeetingSessionState()
    const [screenSharingRequests, setScreenSharingRequests] = useState([])

    const [listTab, setListTab] = useState(0)
    const dropdownSelectionOptions = [
        {
            id: 0,
            label: 'Presenti'
        },
        {
            id: 1,
            label: 'Assenti'
        },
        {
            id: 2,
            label: 'In attesa'
        },
        {
            id: 3,
            label: 'Richieste'
        }
    ]

    const setScreenSharingRequestsFromDict = async (dict) => {
        let requests = []
        let all = call.participants()
        for (let session_id in dict) {
            if (dict[session_id] === 'pending' && all[session_id]) {
                requests.push(all[session_id])
            }
        }
        if (requests.length > 0) {
            context.setNotificationBanner({
                title: requests.length === 1 ? `${requests[0].userData?.surname} ${requests[0].userData?.name} vuole condividere lo schermo` : `Alcuni studenti vogliono condividere lo schermo`,
                body: "Clicca qui e apri il menù richieste per accettare.",
                onClick: () => {
                    onWaitingNotification()
                    onListStateChanged(3)
                }
            })
        }
        setScreenSharingRequests(requests)
    }

    useEffect(() => {
        if (sessionData) {
            setScreenSharingRequestsFromDict(sessionData.screenSharingRequests ?? {})
        }
        else {
            let sessionState = call.meetingSessionState()
            if (sessionState.data) {
                setScreenSharingRequestsFromDict(sessionState.data?.screenSharingRequests ?? {})
            }
        }
    }, [sessionData])

    useEffect(() => {
        const getRoomInfo = async (roomName) => {
            try {
                let roomInfo = await api.get(`/teacher/rooms/${roomName}`)
                setRoomInfo(roomInfo)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (roomName) {
            getRoomInfo(roomName)
        }
    }, [roomName])

    useEffect(() => {
        if (waitingParticipants) {
            const parsed = waitingParticipants.map(wp => {
                const parts = wp.name.includes('-') ? wp.name.split('-') : [null, wp.name];
                return { id: wp.id, name: parts[1].trim() || 'Uno studente', user_id: parts[0] ? parseInt(parts[0]) : null };
            });

            setParsedWaitingParticipants(parsed);

            let name = parsed.length === 1 ? `${parsed[0].name} è in attesa di entrare` : 'Alcuni studenti sono in attesa di entrare';

            if (parsed.length > 0 && !open) {
                if (!context.notificationBanner) {
                    context.setNotificationBanner({
                        title: name,
                        body: "Clicca qui e apri il menù lista d'attesa per accettare.",
                        onClick: () => {
                            onListStateChanged(2);
                            if (!open) {
                                onWaitingNotification();
                            }
                        }
                    });
                }
            }
        }
    }, [waitingParticipants]);

    const onListStateChanged = (index) => {
        setListTab(index)
    }

    const notifyAbsents = async (absents) => {
        try {
            await api.post(`/teacher/rooms/${roomName}/notify-absents`, { absents: absents })
            let newStudents = students.map(s => {
                for (let a of absents) {
                    if (s.id === a) {
                        s.notified = true
                    }
                }
                return s
            })
            onStudentsChange(newStudents)
        }
        catch (e) {
            console.error(e)
        }
    }

    const getInitials = (input) => {
        let words = input
        if (input.includes(' ')) {
            words = input.split(' ');
        }

        // Prendi la prima lettera di ciascuna parola e uniscile
        const initials = words.map(word => word.charAt(0)).join('');

        return initials.slice(0, 2);
    }

    function muteAll() {
        let updateList = {};
        let participants = call.participants()
        for (let id in participants) {
            if (id === 'local' || participants[id].owner) {
                continue;
            }
            updateList[id] = { setAudio: false };
        }
        call.updateParticipants(updateList);
    }

    const acceptBackendParticipant = async (participant_id) => {
        try {
            await api.post(`/teacher/lessons/${lesson_id}/participants/${participant_id}/accept`)
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <div className={`${styles.list} ${open ? styles.open : ''} ${sideBySide ? styles.sideBySide : ''}`}>
            {width > 540 &&
                <MultiStateSwitch selected={listTab} states={['PRESENTI', 'ASSENTI', waitingParticipants.length > 0 ? `IN ATTESA (${waitingParticipants.length})` : 'IN ATTESA', screenSharingRequests.length > 0 ? `RICHIESTE (${screenSharingRequests.length})` : 'RICHIESTE']} onStateChange={onListStateChanged} style={{ '--not-selected-color': 'rgba(255,255,255,0.72)' }} />
            }
            {width <= 540 &&
                <DropdownSelection appereance="transparent" options={dropdownSelectionOptions} defaultOptionIndex={listTab} onSelect={(value) => {
                    onListStateChanged(value)
                }} />
            }
            {listTab === 0 &&
                <div className={styles.presents}>
                    <div className={styles.presentsHeader}>
                        <div className={typo.subheadline}>Presenti</div>
                        <div className={typo.subheadline}>{students.filter(s => s.present).length}</div>
                    </div>
                    <div className={styles.presentsParticipants}>
                        <Each of={students.filter(s => s.present).sort((a, b) => {
                            if (a.surname && b.surname) {
                                return a.surname.localeCompare(b.surname)
                            }
                            return 0
                        })} render={(participant, index) => {
                            return (
                                <DailyParticipant
                                    key={participant.sessionId}
                                    pinned={pinned}
                                    session_id={participant.sessionId}
                                    appearance="list"
                                    activateAudio={false}
                                    onPin={(session_id) => {
                                        onPinnedChange(session_id)
                                    }}
                                />
                            )
                        }} />
                    </div>
                    {students.filter(s => s.present).length > 0 &&
                        <div>
                            <Button disabled={!students.find(s => !s.present && !s.notified)} appearance="default" fullWidth accentColor={'#FDB323'} onClick={async () => {
                                muteAll()
                            }} style={{ padding: '.5rem', fontSize: '1rem' }}>MUTA TUTTI</Button>
                        </div>
                    }
                </div>
            }
            {listTab === 1 &&
                <div className={styles.absents}>
                    <div className={styles.absentsHeader}>
                        <div className={typo.subheadline}>Assenti</div>
                        <div className={typo.subheadline}>{students.filter(s => !s.present).length}</div>
                    </div>
                    <div className={styles.absentsParticipants}>
                        <Each of={students.filter(s => !s.present).sort((a, b) => {
                            return a.surname - b.surname
                        })} render={(absent, index) => {
                            return (
                                <div className={styles.absentParticipant}>
                                    <img src={absent.picture ?? UserPlaceholder} alt="" className={styles.presentAvatar} />
                                    <div className={styles.participantName}>{`${absent.surname} ${absent.name}`}</div>
                                    <Button disabled={absent.notified} appearance="default" fullWidth accentColor={'#FDB323'} onClick={async () => {
                                        await notifyAbsents([absent.id])
                                    }} style={{ padding: '.5rem 0', minWidth: '32px', width: '32px', height: '32px' }}> <NotificationActiveIcon /> </Button>
                                </div>
                            )
                        }} />
                    </div>
                    <div>
                        <Button disabled={!students.find(s => !s.present && !s.notified)} appearance="default" fullWidth accentColor={'#FDB323'} onClick={async () => {
                            let absents = students.filter(s => !s.present && !s.notified).map(s => s.id)
                            await notifyAbsents(absents)
                        }} style={{ padding: '.5rem', fontSize: '1rem' }}> <NotificationActiveIcon /> NOTIFICA TUTTI</Button>
                    </div>
                </div>
            }
            {listTab === 2 &&
                <div className={styles.waiting}>
                    <div className={styles.waitingHeader}>
                        <div className={typo.subheadline}>In attesa</div>
                        <div className={typo.subheadline}>{waitingParticipants.length}</div>
                    </div>
                    <div className={styles.waitingParticipants}>
                        <Each of={parsedWaitingParticipants} render={(participant, index) => {
                            const colorIndex = (index) % palette.length
                            const color = palette[colorIndex]
                            return (
                                <div className={styles.waitingParticipant}>
                                    {participant.picture &&
                                        <img src={participant.picture} alt="" className={styles.waitingAvatar} />
                                    }
                                    {!participant.picture &&
                                        <div style={{ backgroundColor: color }} className={styles.waitingAvatar}>{getInitials(participant.name)}</div>
                                    }
                                    {participant.name}
                                    <div style={{ display: 'flex', flex: 1 }} />
                                    {roomInfo?.privacy === 'private' &&
                                        <Button style={{ padding: '0.5rem' }} onClick={async () => {
                                            try {
                                                grantAccess(participant.id)
                                                call.updateParticipant(participant.id, { setAudio: false, setVideo: false })
                                                await acceptBackendParticipant(participant.id)
                                            }
                                            catch (e) {
                                                console.error("Error accepting participant", e)
                                            }
                                        }}>ACCETTA</Button>
                                    }
                                </div>
                            )
                        }} />
                    </div>
                </div>
            }
            {listTab === 3 &&
                <div className={styles.presents}>
                    <div className={styles.presentsHeader}>
                        <div className={typo.subheadline}>Richieste di condivisione</div>
                        <div className={typo.subheadline}>{0}</div>
                    </div>
                    <div className={typo.caption}>
                        Potrai interrompere le condivisioni accettate dal menù 'presenti'.
                    </div>
                    <div className={styles.presentsParticipants}>
                        <Each of={screenSharingRequests.sort((a, b) => {
                            if (a.userData?.surname && b.userData?.surname) {
                                return a.userData?.surname.localeCompare(b.userData?.surname)
                            }
                            return 0
                        })} render={(request, index) => {
                            return (
                                <div className={styles.screenSharingRequest}>
                                    <img src={request.userData?.picture ?? UserPlaceholder} alt="" className={styles.requestAvatar} />
                                    <div className={styles.requestName}>{`${request.userData?.surname} ${request.userData?.name}`}</div>

                                    <TooltipWrapper text="Rifiuta">
                                        <div className={styles.declineButton} onClick={() => {
                                            call.setMeetingSessionData({ screenSharingRequests: { ...sessionData?.screenSharingRequests, [request.session_id]: null } }, 'shallow-merge')
                                        }}>
                                            <CloseIcon className={styles.requestIcon} />
                                        </div>
                                    </TooltipWrapper>

                                    <TooltipWrapper text="Accetta">
                                        <div className={styles.acceptButton} onClick={() => {
                                            call.setMeetingSessionData({ screenSharingRequests: { ...sessionData?.screenSharingRequests, [request.session_id]: 'accepted' } }, 'shallow-merge')
                                        }}>
                                            <CheckIcon className={styles.requestIcon} />
                                        </div>
                                    </TooltipWrapper>
                                    {!pinned.includes(request.session_id) &&
                                        <TooltipWrapper text="Accetta e Fissa">
                                            <div className={styles.acceptPinButton} onClick={() => {
                                                call.setMeetingSessionData({ screenSharingRequests: { ...sessionData?.screenSharingRequests, [request.session_id]: 'accepted' } }, 'shallow-merge')
                                                onPinnedChange(request.session_id)
                                            }}>
                                                <PinIcon className={styles.requestIcon} />
                                            </div>
                                        </TooltipWrapper>
                                    }
                                </div>
                            )
                        }} />
                    </div>
                </div>
            }
        </div>
    )

}

export default DailyList;