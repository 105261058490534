import { useNavigate } from "react-router-dom"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as PlayIcon } from "../../assets/images/icons/ic-play.svg"
import { ReactComponent as LessonIcon } from "../../assets/images/icons/ic-video-lesson.svg"
import { FeedType } from "../../common/constants"
import { capitalize, formatDateV2, formatTimeV2, hourDiff } from "../../utils"
import LiveBadge from "../LiveBadge"
import Loader from "../Loader"
import Skeleton from "../Skeleton"
import Card from "./Card"
import CommunicationFeedCard from "./CommunicationFeedCard"
import ExerciseFeedCard from "./ExerciseFeedCard"
import styles from "./FeedCard.module.css"
import MaterialFeedCard from "./MaterialFeedCard"
import TestFeedCard from "./TestFeedCard"
import { useState } from "react"
import AlertDialog from "../dialogs/AlertDialog"
import Markdown from "react-markdown"

const FeedCard = ({ activity, onChange = () => { } }) => {

    const now = new Date()
    const navigate = useNavigate();
    const [openLesson, setOpenLesson] = useState(null)

    return (
        <>
            {
                activity.loading === true &&
                <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div className={styles.cardHeader}>
                        <div className={styles.icon}>
                            <Skeleton type="circle" width="32px" height="32px" />
                        </div>
                        <div className={styles.info} style={{ gap: ".3rem" }}>
                            <div className={styles.title}>
                                <Skeleton type="rect" width="246px" height="16px" borderRadius="8px" />
                            </div>
                            <div className={styles.subtitle}>
                                <Skeleton type="rect" width="144px" height="14px" borderRadius="8px" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.content} style={{ height: "256px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Loader />
                    </div>
                </Card>
            }

            {activity.type === FeedType.Lesson && !activity.loading &&
                <Card style={{ display: 'flex', flexDirection: 'column', padding: 0, cursor: 'pointer' }} onClick={() => {
                    if (new Date() > new Date(activity.object.ends_at)) {
                        navigate(`/lessons/${activity.object.room_name}`)
                    }
                    else {
                        setOpenLesson(activity.object.room_name)
                    }
                }}>
                    <div className={styles.cardHeader}>
                        <div className={`${styles.icon} ${styles.lesson}`}>
                            <LessonIcon />
                        </div>
                        <div className={styles.info}>
                            <div className={styles.title}>
                                {activity.object.name}
                            </div>
                            <div className={styles.subtitle}>
                                <ClockIcon className={styles.clockIcon} /> {hourDiff(activity.object.starts_at, activity.object.ends_at)} ore
                            </div>
                        </div>
                        <div className={styles.date}>
                            {now > new Date(activity.object.starts_at) && now < new Date(activity.object.ends_at) &&
                                <LiveBadge style={{ padding: '.3rem .5rem' }} />
                            }
                            {now < new Date(activity.object.starts_at) &&
                                <>
                                    {formatTimeV2(activity.object.starts_at)} {capitalize(formatDateV2(activity.object.starts_at, { weekday: 'short', day: 'numeric', month: 'short' }))}
                                    <CalendarIcon />
                                </>
                            }
                            {now > new Date(activity.object.ends_at) &&
                                <div className={styles.lessonEnded}>TERMINATA</div>
                            }
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.thumbnailContainer}>
                            <img src={activity.object.thumbnail} className={`${styles.thumbnail} ${now > new Date(activity.object.ends_at) ? styles.ended : ''}`} alt="lesson" />
                            <div className={styles.playButton}>
                                <PlayIcon />
                            </div>
                        </div>
                        <div className={styles.description}>
                            <Markdown>
                                {activity.object.description}
                            </Markdown>
                        </div>
                    </div>
                </Card>
            }

            {activity.type === FeedType.Communication && !activity.loading &&
                <CommunicationFeedCard
                    activity={activity}
                    onDelete={(activity_id) => onChange({ activity_id, deleted: true })}
                />
            }

            {activity.type === FeedType.Material && !activity.loading &&
                <MaterialFeedCard
                    activity={activity}
                    onChange={onChange}
                />
            }

            {activity.type === FeedType.Exercise && !activity.loading &&
                <ExerciseFeedCard
                    activity={activity}
                    onEdit={onChange}
                    onDelete={(activity_id) => onChange({ activity_id, deleted: true })}
                />
            }

            {
                activity.type === FeedType.Test && !activity.loading &&
                <TestFeedCard
                    activity={activity}
                    onDelete={(activity_id) => onChange({ activity_id, deleted: true })}
                    onComplete={onChange}
                />
            }

            <AlertDialog
                open={openLesson}
                onClose={() => setOpenLesson(null)}
                title="Nuova Modalità Side by Side"
                text="La modalità Side by Side ti permette di avere tutti i controlli della chiamata in una finestra separata da poter affiancare al tuo materiale durante la condivisione. Vuoi attivarla o continuare con la modalità classica ?"
                actions={[
                    {
                        label: "SIDE BY SIDE",
                        onClick: () => {
                            setOpenLesson(null)
                            window.open(
                                `/lessons/${openLesson}/side`, // URL della finestra da aprire
                                "_blank", // Apre in una nuova finestra
                                "width=250,height=900,toolbar=no,location=yes,status=no,menubar=no,scrollbars=no"
                            );
                        }
                    },
                    {
                        label: "CLASSICA",
                        onClick: () => {
                            setOpenLesson(null)
                            navigate(`/lessons/${openLesson}`)
                        }
                    }
                ]}
            />
        </>
    )
}

export default FeedCard
