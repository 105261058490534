import { DailyAudio, useActiveSpeakerId, useDaily, useLocalSessionId, useMeetingSessionState, useMeetingState, useParticipantIds, useRecording, useScreenShare, useWaitingParticipants } from "@daily-co/daily-react"
import { useCallback, useEffect, useRef, useState } from "react"
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg"
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg"
import { ReactComponent as ChatIcon } from "../../assets/images/icons/ic-chat.svg"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { ReactComponent as ListIcon } from "../../assets/images/icons/ic-list.svg"
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg"
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg"
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/ic-notification-active.svg"
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg"
import { ReactComponent as ScreenStopIcon } from "../../assets/images/icons/ic-screenshare-stop.svg"
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/ic-settings.svg"
import { ReactComponent as UsersIcon } from "../../assets/images/icons/ic-users.svg"

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import api from "../../api"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { Each } from "../../common/Each"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import typo from "../../typography.module.css"
import Button from "../Button"
import IconsTabSwitch from "../IconsTabSwitch"
import LiveBadge from "../LiveBadge"
import TextInput from "../TextInput"
import TooltipWrapper from "../TooltipWrapper"
import Audio from "../animated/Audio"
import AlertDialog from "../dialogs/AlertDialog"
import DailyChat from "./DailyChat"
import DailyControls from "./DailyControls"
import DailyParticipant from "./DailyParticipant"
import styles from "./DailyRoomSide.module.css"
import DailySettings from "./DailySettings"
import DailySpotlighted from "./DailySpotlighted"

const DailyRoomSide = ({ lesson_id, roomName, students, onStudentsChange }) => {

    const { width } = useWindowDimensions()

    const localSessionId = useLocalSessionId()

    const [unreadMessages, setUnreadMessages] = useState(0)
    const [muted, setMuted] = useState(false)
    const [roomInfo, setRoomInfo] = useState(null)

    const [waitingOpen, setWaitingOpen] = useState(true)
    const [requestsOpen, setRequestsOpen] = useState(true)
    const [presentsOpen, setPresentsOpen] = useState(true)
    const [absentsOpen, setAbsentsOpen] = useState(true)

    const call = useDaily()
    const participants = useParticipantIds({
        filter: 'remote', sort: ((p1, p2) => {

            if (p1?.owner && !p2?.owner) return -1
            if (!p1?.owner && p2?.owner) return 1
            if (p1?.owner && p2?.owner) return 0

            if (!p1?.owner && !p2?.owner) {
                if (p1?.tracks.audio.state === 'playable' && p2?.tracks.audio.state !== 'playable') return -1
                if (p1?.tracks.audio.state !== 'playable' && p2?.tracks.audio.state === 'playable') return 1
                if (p1?.tracks.audio.state === 'playable' && p2?.tracks.audio.state === 'playable') {
                    if (remoteAudioLevels[p1] > remoteAudioLevels[p2] * 1.5) return -1
                    if (remoteAudioLevels[p2] > remoteAudioLevels[p1] * 1.5) return 1
                    return 0
                }
                if (p1?.tracks.audio.state !== 'playable' && p2?.tracks.audio.state !== 'playable') {
                    if (p1?.userData?.handRaised && !p2?.userData?.handRaised) return -1
                    if (!p1?.userData?.handRaised && p2?.userData?.handRaised) return 1
                    return 0
                }
            }
            return 0
        })
    })
    const handRaisedParticipants = useParticipantIds({ filter: (p) => { return p?.userData?.handRaised } })
    const [filteredParticipants, setFilteredParticipants] = useState(participants)
    const { waitingParticipants, grantAccess } = useWaitingParticipants()
    const { data: sessionData } = useMeetingSessionState()
    const [pinned, _setPinned] = useState([])
    const pinnedRef = useRef(pinned)
    const setPinned = (data) => {
        pinnedRef.current = data
        _setPinned(data)
    }
    const [spotlighted, _setSpotlighted] = useState([])
    const spotlightedRef = useRef(spotlighted)
    const setSpotlighted = (data) => {
        spotlightedRef.current = data
        _setSpotlighted(data)
    }
    const [remoteAudioLevels, setRemoteAudioLevels] = useState({})
    const audioRef = useRef(null)
    const { screens, isSharingScreen } = useScreenShare()
    const activeParticipantId = useActiveSpeakerId({ ignoreLocal: true })
    const [activeParticipant, setActiveParticipant] = useState(null)
    const [screenSharingRequests, setScreenSharingRequests] = useState([])
    const meetingState = useMeetingState()

    const [activeTab, setActiveTab] = useState(0)
    const [tabs, setTabs] = useState([
        {
            name: "Utenti",
            icon: <UsersIcon />,
            badge: 0
        },
        {
            name: "Chat",
            icon: <ChatIcon />,
            badge: 0
        },
        {
            name: "Lista",
            icon: <ListIcon />,
            badge: 0
        },
        {
            name: "Impostazioni",
            icon: <SettingsIcon />,
            badge: 0
        }
    ])
    const tabSplideRef = useRef(null)

    const [audioErrors, _setAudioErrors] = useState([])
    const audioErrorsRef = useRef(audioErrors)
    const setAudioErrors = (data) => {
        audioErrorsRef.current = data
        _setAudioErrors(data)
    }


    const onRecordingError = (error) => {
        setRecordingError(error)
    }

    const { isRecording, startRecording } = useRecording({ onRecordingError: onRecordingError })

    const [participantToRemove, setParticipantToRemove] = useState(null)
    const [lessonEnding, setLessonEnding] = useState(false)
    const [filteredPresents, setFilteredPresents] = useState(students.filter(s => s.present))
    const [filteredAbsents, setFilteredAbsents] = useState(students.filter(s => !s.present))
    const [filteredWaiting, setFilteredWaiting] = useState(waitingParticipants)
    const [filteredRequests, setFilteredRequests] = useState(screenSharingRequests)

    const [recordingError, setRecordingError] = useState(null)
    const [showStudentView, setShowStudentView] = useState(false)

    useEffect(() => {
        if (activeParticipantId) {
            let all = call.participants()
            let participantArray = Object.values(all)
            let active = participantArray.find(p => p.session_id === activeParticipantId)
            let audioLevel = parseInt(remoteAudioLevels[activeParticipantId] * 1000)
            if (audioLevel > 5) {
                setActiveParticipant(active)
            }
        }
        else {
            setActiveParticipant(null)
        }
    }, [activeParticipantId, remoteAudioLevels])

    useEffect(() => {
        call.on('participant-left', (e) => {
            setPinned(pinnedRef.current.filter(p => p !== e.participant.session_id) ?? [])
            setSpotlighted(spotlightedRef.current.filter(s => s.session_id !== e.participant.session_id))
            if (activeParticipant?.session_id === e.participant.session_id) {
                setActiveParticipant(null)
            }
        })

        const startRemoteAudioObserver = async () => {
            try {
                if (!call.isRemoteParticipantsAudioLevelObserverRunning()) {
                    await call.startRemoteParticipantsAudioLevelObserver(100);
                }
            } catch (e) {
                console.error("Error starting audio observer: ", e)
            }
        }

        startRemoteAudioObserver()

        call.on('remote-participants-audio-level', async (event) => {
            setRemoteAudioLevels(event.participantsAudioLevel)
        })

    }, [])

    useEffect(() => {
        const getRoomInfo = async (roomName) => {
            try {
                let roomInfo = await api.get(`/teacher/rooms/${roomName}`)
                setRoomInfo(roomInfo)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (roomName) {
            getRoomInfo(roomName)
        }
    }, [roomName])

    useEffect(() => {
        if (tabSplideRef.current) {
            tabSplideRef.current.splide.on('move', (newIndex) => {
                setActiveTab(newIndex)
                if (newIndex === 1) {
                    setTabs(tabs.map((tab) => {
                        if (tab.name === "Chat") {
                            tab.badge = 0
                        }
                        return tab
                    }))
                }
            })
        }
    }, [tabSplideRef.current])

    useEffect(() => {
        let all = call.participants()

        let participantArray = Object.values(all)

        let newStudents = students.map(s => {
            s.present = false
            for (let p of participantArray) {
                if (s.id === p.userData?.id && !p.owner) {
                    s.sessionId = p.session_id
                    s.present = true
                }
            }
            return s
        })
        onStudentsChange(newStudents)
        setFilteredParticipants(participants)
        setFilteredPresents(newStudents.filter(s => s.present))
        setFilteredAbsents(newStudents.filter(s => !s.present))
    }, [participants])

    useEffect(() => {
        // Mappa waitingParticipants in un array di oggetti con id, name e user_id
        const parsedWaiting = waitingParticipants.map(wp => {
            const parts = wp.name.includes('-') ? wp.name.split('-') : [null, wp.name];
            return { id: wp.id, name: (parts[1] ?? '').trim() || 'Studente', user_id: parts[0] ? parseInt(parts[0]) : null };
        });

        // Aggiorna il badge della tab "Lista"
        setTabs(tabs.map((tab) => {
            if (tab.name === "Lista") {
                tab.badge = parsedWaiting.length + screenSharingRequests.length;
            }
            return tab;
        }));

        setFilteredWaiting(parsedWaiting); // Usa la versione parsata dei waiting participants
        setFilteredRequests(screenSharingRequests);
    }, [waitingParticipants, screenSharingRequests]);

    useEffect(() => {
        setTabs(tabs.map((tab) => {
            if (tab.name === "Utenti") {
                tab.badge = handRaisedParticipants.length
            }
            return tab
        }))
    }, [handRaisedParticipants])

    
    useEffect(() => {
        if(localSessionId){
            addParticipantId()
        }
        if (call && !['left-meeting', 'error'].includes(meetingState)) {
            if (sessionData) {
                setPinned(sessionData?.pinned ?? [])
                setScreenSharingRequestsFromDict(sessionData.screenSharingRequests ?? {})
                if (sessionData.pinned && sessionData.pinned.length > 0) {
                    setSpotlighted([...sessionData.pinned.map(p => { return { session_id: p, pinned: true } })])
                }
                else {
                    setSpotlighted([{ session_id: localSessionId, pinned: false }])
                }
            }
            else {
                let sessionState = call.meetingSessionState()
                if (sessionState.data) {
                    let all = call.participants()
                    setPinned(sessionState.data?.pinned?.filter(p => Object.keys(all).includes(p)) ?? [])
                    setScreenSharingRequestsFromDict(sessionState.data?.screenSharingRequests ?? {})
                }
            }
        }
    }, [sessionData, localSessionId, roomName, call, participants])

    useEffect(() => {
        if (pinned && pinned.length > 0) {
            let all = Object.values(call.participants())
            let pinnedXSpotlighted = pinned.filter(p => all.find(a => a.session_id === p))
            setSpotlighted(pinnedXSpotlighted.map(p => { return { session_id: all.find(a => a.session_id === p).session_id, pinned: true } }))
        }
        else {
            setSpotlighted([{ session_id: localSessionId, pinned: false }])
        }
    }, [pinned])

    const setScreenSharingRequestsFromDict = async (dict) => {
        let requests = []
        let all = call.participants()
        for (let session_id in dict) {
            if (dict[session_id] === 'pending' && all[session_id]) {
                requests.push(all[session_id])
            }
        }
        setScreenSharingRequests(requests)
    }

    const addParticipantId = async () => {
        try {
            await api.put(`/teacher/lessons/${lesson_id}/participant`, { participant_id: localSessionId })
        }
        catch (e) {
            console.error(e)
        }
    }

    const getTabContentRadius = () => {
        switch (activeTab) {
            case 0:
                return '0 12px 12px 12px'
            case 1:
                return '12px'
            case 2:
                return '12px'
            case 3:
                return '12px 0 12px 12px'
            default:
                return '12px'
        }
    }

    const onPlayFailed = useCallback((event) => {
        console.error(event)
        setAudioErrors([...audioErrorsRef.current, event.target])
    }, [])

    const notifyAbsents = async (absents) => {
        try {
            await api.post(`/teacher/rooms/${roomName}/notify-absents`, { absents: absents })
            let newStudents = students.map(s => {
                for (let a of absents) {
                    if (s.id === a) {
                        s.notified = true
                    }
                }
                return s
            })
            onStudentsChange(newStudents)
        }
        catch (e) {
            console.error(e)
        }
    }

    const onSearch = (query) => {
        let all = call.participants()
        let participantArray = Object.values(all)

        let filtered = participantArray.filter(p => {
            return `${p.userData?.name}${p.userData?.surname}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()) ||
                `${p.userData?.surname}${p.userData?.name}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase())
        })

        setFilteredParticipants(participants.filter(p => filtered.map(f => f.session_id).includes(p)))
    }

    const onListSearch = (query) => {
        if (query.replaceAll(' ', '').length === 0) {
            setFilteredPresents(students.filter(s => s.present))
            setFilteredAbsents(students.filter(s => !s.present))
            setFilteredWaiting(waitingParticipants)
            setFilteredRequests(screenSharingRequests)
            return
        }
        setFilteredPresents(students.filter(s => s.present && (`${s.name}${s.surname}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()) || `${s.surname}${s.name}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()))))
        setFilteredAbsents(students.filter(s => !s.present && (`${s.name}${s.surname}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()) || `${s.surname}${s.name}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()))))
        setFilteredWaiting(waitingParticipants.filter(p => (`${p.name}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()))))
        setFilteredRequests(screenSharingRequests.filter(p => (`${p.userData?.name}${p.userData?.surname}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()) || `${p.userData?.surname}${p.userData?.name}`.toLowerCase().includes(query.replaceAll(' ', '').toLowerCase()))))
    }

    const retryAudioErrors = () => {
        for (let audioElm of audioErrors) {
            try {
                audioElm.play()
                setAudioErrors(audioErrors.filter(a => a !== audioElm))
            }
            catch {
                //
            }
        }
    }

    const lockRoom = async (locked) => {
        try {
            const body = { locked: locked }
            await api.put(`teacher/rooms/${roomName}/lock`, body)
        }
        catch (e) {
            console.error(e)
        }
    }

    const acceptBackendParticipant = async (participant_id) => {
        try {
            await api.post(`/teacher/lessons/${lesson_id}/participants/${participant_id}/accept`)
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            {!['left-meeting', 'error'].includes(meetingState) &&
                <div className={styles.container}>
                    <div className={styles.section} style={{ maxWidth: '390px', flex: 1, flexBasis: 0, gap: '.5rem' }}>
                        <div className={styles.sectionInner}>
                            {isRecording && !recordingError &&
                                <LiveBadge label={"REGISTRAZIONE IN CORSO"} />
                            }
                            {!isRecording && recordingError &&
                                <div className={styles.audioErrors}>
                                    <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                                        Sembrano esserci stati dei problemi con la registrazione cloude della lezione. Prova a risolvere il problema cliccando qui:
                                        <Button appearance="text" accentColor={'var(--sf-red)'} onClick={() => { startRecording() }}>riprova</Button>
                                    </div>
                                </div>
                            }
                            <DailyAudio maxSpeakers={200} onPlayFailed={onPlayFailed} ref={audioRef} playLocalScreenAudio={true} />
                            {audioErrors.length > 0 &&
                                <div className={styles.audioErrors}>
                                    <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                                        Sembrano esserci stati dei problemi con l'audio di alcuni partecipanti. Prova a risolvere il problema cliccando qui:
                                        <Button appearance="text" accentColor={'var(--sf-red)'} onClick={retryAudioErrors}>riprova</Button>
                                    </div>
                                </div>
                            }
                            <DailyParticipant
                                key={localSessionId}
                                pinned={pinned}
                                session_id={localSessionId}
                                isLocal={true}
                                style={{ width: "100%", height: "auto", minHeight: 'unset', aspectRatio: '16/9', minWidth: 'unset' }}
                                onClick={() => {
                                    if (spotlighted && spotlighted.length < 4 && !spotlighted.find(s => s.session_id === localSessionId)) {
                                        setSpotlighted([...spotlighted, { session_id: localSessionId, pinned: false }])
                                    }
                                }}
                                onPin={() => {
                                    if (!pinned?.includes(localSessionId) && pinned?.length < 4) {
                                        setPinned([...pinned, localSessionId])
                                        call.setMeetingSessionData({ pinned: [...pinned, localSessionId] }, 'shallow-merge')
                                        setSpotlighted([...spotlighted, { session_id: localSessionId, pinned: true }])
                                    }
                                    else {
                                        setPinned(pinned.filter(p => p !== localSessionId))
                                        call.setMeetingSessionData({ pinned: pinned.filter(p => p !== localSessionId) }, 'shallow-merge')
                                        setSpotlighted(spotlighted.map(s => {
                                            return { session_id: s.session_id, pinned: s.session_id === localSessionId ? false : s.pinned }
                                        }))
                                    }
                                }}
                                onlyVideo={true}
                            />
                            <DailyControls
                                localSessionId={localSessionId}
                                muted={muted}
                                pinned={pinned}
                                onMutedChange={(value) => {
                                    setMuted(value)
                                }}
                                hiddenControls={["chat", "participants", "settings", "fullscreen", "list", "pdf"]}
                            />
                            <div className={typo.caption} style={{ paddingTop: '.5rem' }}>ULTIMO PARTECIPANTE ATTIVO</div>
                            <div className={styles.activeParticipant}>
                                {activeParticipant &&
                                    <>
                                        <img src={activeParticipant.userData?.picture} alt="" className={styles.activeParticipantAvatar} />
                                        <div className={styles.activeParticipantInfo}>
                                            {activeParticipant.userData?.name} {isSharingScreen ? `${activeParticipant.userData?.surname?.[0]}.` : activeParticipant.userData?.surname}
                                        </div>
                                        {remoteAudioLevels[activeParticipant.session_id] !== null &&
                                            <Audio level={remoteAudioLevels[activeParticipant.session_id]} />
                                        }
                                    </>
                                }
                                {!activeParticipant &&
                                    <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                                        Nessun partecipante attivo
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.sectionInner} style={{ gap: 0, flex: 1 }}>
                            <IconsTabSwitch
                                style={{ '--accentColor': 'rgba(0, 0, 0, 0.3)' }}
                                tabs={tabs}
                                activeTab={activeTab}
                                onTabChange={(index) => {
                                    setActiveTab(index)
                                    if (tabSplideRef.current) {
                                        tabSplideRef.current.go(index)
                                    }
                                }}
                                showNames={width > 390}
                            />
                            <div className={styles.tabContent} style={{ borderRadius: getTabContentRadius() }}>
                                <Splide
                                    className={styles.tabSplide}
                                    hasTrack={false}
                                    ref={tabSplideRef}
                                    options={{
                                        pagination: false,
                                        drag: false
                                    }}
                                >
                                    <SplideTrack className={styles.tabTrack}>
                                        <SplideSlide className={styles.tabSlide}>
                                            <div className={styles.participants}>
                                                <TextInput type="search" placeholder="Cerca" onKeyUp={onSearch} inputStyle={{ color: 'var(--background-color)', padding: '.25rem', paddingLeft: '2.5rem', borderRadius: '6px' }} style={{ color: 'white' }} />
                                                <div className={styles.participantsScrollView}>
                                                    <div className={styles.participantsInner}>
                                                        {pinned.length > 0 && (!(pinned.length === 1 && pinned[0] === localSessionId)) &&
                                                            <>
                                                                <div className={[typo.caption, styles.pinnedLabel].join(' ')}> <PinIcon style={{ maxHeight: '12px', maxWidth: '12px' }} /> FISSATI </div>
                                                                <Each of={pinned} render={(pnd) => {
                                                                    if (pnd === localSessionId) return null
                                                                    return (
                                                                        <DailyParticipant
                                                                            key={`pinned-${pnd}`}
                                                                            session_id={pnd}
                                                                            isLocal={false}
                                                                            style={{ width: '100%', height: 'auto', minHeight: 'unset', aspectRatio: '16/9', minWidth: 'unset' }}
                                                                            audioRef={audioRef}
                                                                            pinned={pinned}
                                                                            muted={muted}
                                                                            onClick={() => {
                                                                                if (spotlighted && spotlighted.length < 4 && !spotlighted.find(s => s.session_id === pnd)) {
                                                                                    setSpotlighted([...spotlighted, { session_id: pnd, pinned: false }])
                                                                                }
                                                                            }}
                                                                            onPin={() => {
                                                                                setPinned(pinned.filter(p => p !== pnd))
                                                                                call.setMeetingSessionData({ pinned: pinned.filter(p => p !== pnd) }, 'shallow-merge')
                                                                                setSpotlighted(spotlighted.map(s => {
                                                                                    return { session_id: s.session_id, pinned: s.session_id === pnd ? false : s.pinned }
                                                                                }))
                                                                            }}
                                                                            onParticipantRemove={(participant) => {
                                                                                setParticipantToRemove(participant)
                                                                            }}
                                                                        />
                                                                    )
                                                                }} />
                                                                <div className={styles.divider} />
                                                            </>
                                                        }

                                                        <div className={[typo.caption, styles.pinnedLabel].join(' ')}>
                                                            <UsersIcon style={{ maxHeight: '12px', maxWidth: '12px' }} />
                                                            TUTTI
                                                        </div>
                                                        <Each of={filteredParticipants} render={(participant, index) => {
                                                            if (!pinned.includes(participant)) {
                                                                return (
                                                                    <DailyParticipant
                                                                        key={participant}
                                                                        session_id={participant}
                                                                        pinned={pinned}
                                                                        isLocal={false}
                                                                        style={{ width: '100%', height: 'auto', minHeight: 'unset', aspectRatio: '16/9', minWidth: 'unset' }}
                                                                        audioRef={audioRef}
                                                                        muted={muted}
                                                                        onPin={() => {
                                                                            if (!pinned?.includes(participant) && pinned?.length < 4) {
                                                                                setPinned([...pinned, participant])
                                                                                call.setMeetingSessionData({ pinned: [...pinned, participant] }, 'shallow-merge')
                                                                                setSpotlighted([...spotlighted, { session_id: participant, pinned: true }])
                                                                            }
                                                                            else {
                                                                                setPinned(pinned.filter(p => p !== participant))
                                                                                call.setMeetingSessionData({ pinned: pinned.filter(p => p !== participant) }, 'shallow-merge')
                                                                                setSpotlighted(spotlighted.map(s => {
                                                                                    return { session_id: s.session_id, pinned: s.session_id === participant ? false : s.pinned }
                                                                                }))
                                                                            }
                                                                        }}
                                                                        onParticipantRemove={(participant) => {
                                                                            setParticipantToRemove(participant)
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide className={styles.tabSlide}>
                                            <div className={styles.chat}>
                                                <DailyChat
                                                    popup={true}
                                                    unread={unreadMessages}
                                                    onUnreadChange={(unread) => {
                                                        setUnreadMessages(unread)
                                                        setTabs(tabs.map((tab) => {
                                                            if (tab.name === "Chat") {
                                                                tab.badge = unread
                                                            }
                                                            return tab
                                                        }))
                                                    }}
                                                    lesson_id={lesson_id}
                                                    sideBySide={true} />
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide className={styles.tabSlide}>
                                            <div className={styles.list}>
                                                <div className={styles.listInner}>
                                                    <TextInput type="search" placeholder="Cerca" onKeyUp={onListSearch} inputStyle={{ color: 'var(--background-color)', padding: '.25rem', paddingLeft: '2.5rem', borderRadius: '6px' }} style={{ color: 'white' }} />
                                                    <div className={styles.listSection}>
                                                        <div className={`${styles.listHeader} ${!waitingOpen ? styles.closed : ''}`} onClick={() => { setWaitingOpen(!waitingOpen) }}>
                                                            <div className={styles.listHeaderTitle}>In Attesa <span>{filteredWaiting.length}</span></div>
                                                            <ChevronIcon className={`${styles.chevron} ${!waitingOpen ? styles.closed : ''}`} />
                                                        </div>
                                                        <div className={`${styles.listContent} ${!waitingOpen ? styles.closed : ''}`}>
                                                            <Each of={filteredWaiting} render={(participant, index) => {
                                                                return (
                                                                    <div className={styles.waitingParticipant}>
                                                                        {isSharingScreen ? `${participant.name.split(' ')?.[0]} ${participant.name.split(' ')?.[1][0]}.` : participant.name}
                                                                        <Button style={{ padding: '0.5rem', fontSize: '.75rem' }} appearance="default" onClick={async () => {
                                                                            grantAccess(participant.id)
                                                                            call.updateParticipant(participant.id, { setAudio: false, setVideo: false })
                                                                            await acceptBackendParticipant(participant.id)
                                                                        }}>ACCETTA</Button>
                                                                    </div>
                                                                )
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.listSection}>
                                                        <div className={`${styles.listHeader} ${!requestsOpen ? styles.closed : ''}`} onClick={() => { setRequestsOpen(!requestsOpen) }}>
                                                            <div className={styles.listHeaderTitle}>Richieste <span>{filteredRequests.length}</span></div>
                                                            <ChevronIcon className={`${styles.chevron} ${!requestsOpen ? styles.closed : ''}`} />
                                                        </div>
                                                        <div className={`${styles.listContent} ${!requestsOpen ? styles.closed : ''}`}>
                                                            <Each of={filteredRequests} render={(request, index) => {
                                                                return (
                                                                    <div className={styles.screenSharingRequest}>
                                                                        <img src={request.userData?.picture ?? UserPlaceholder} alt="" className={styles.absentAvatar} />
                                                                        <div className={styles.requestInfo}>
                                                                            {isSharingScreen ? `${request.userData?.name} ${request.userData?.surname?.[0]}.` : `${request.userData?.name} ${request.userData?.surname}`}
                                                                            <div className={styles.requestActions}>
                                                                                <TooltipWrapper text="Rifiuta">
                                                                                    <div className={styles.declineButton} onClick={() => {
                                                                                        call.setMeetingSessionData({ screenSharingRequests: { ...sessionData?.screenSharingRequests, [request.session_id]: null } }, 'shallow-merge')
                                                                                    }}>
                                                                                        <CloseIcon className={styles.requestIcon} />
                                                                                    </div>
                                                                                </TooltipWrapper>

                                                                                <TooltipWrapper text="Accetta">
                                                                                    <div className={`${styles.acceptButton} ${pinned.includes(request.session_id) && styles.rounded}`} onClick={() => {
                                                                                        call.setMeetingSessionData({ screenSharingRequests: { ...sessionData?.screenSharingRequests, [request.session_id]: 'accepted' } }, 'shallow-merge')
                                                                                    }}>
                                                                                        <CheckIcon className={styles.requestIcon} />
                                                                                    </div>
                                                                                </TooltipWrapper>
                                                                                {!pinned.includes(request.session_id) &&
                                                                                    <TooltipWrapper text="Accetta e Fissa">
                                                                                        <div className={styles.acceptPinButton} onClick={() => {
                                                                                            call.setMeetingSessionData({ screenSharingRequests: { ...sessionData?.screenSharingRequests, [request.session_id]: 'accepted' } }, 'shallow-merge')
                                                                                            if (!pinned?.includes(request.session_id) && pinned?.length < 4) {
                                                                                                setPinned([...pinned, request.session_id])
                                                                                                call.setMeetingSessionData({ pinned: [...pinned, request.session_id] }, 'shallow-merge')
                                                                                                setSpotlighted([...spotlighted, { session_id: request.session_id, pinned: true }])
                                                                                            }
                                                                                        }}>
                                                                                            <PinIcon className={styles.requestIcon} />
                                                                                        </div>
                                                                                    </TooltipWrapper>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.listSection}>
                                                        <div className={`${styles.listHeader} ${!presentsOpen ? styles.closed : ''}`} onClick={() => { setPresentsOpen(!presentsOpen) }}>
                                                            <div className={styles.listHeaderTitle}>Presenti <span>{filteredPresents.length}</span></div>
                                                            <ChevronIcon className={`${styles.chevron} ${!presentsOpen ? styles.closed : ''}`} />
                                                        </div>
                                                        <div className={`${styles.listContent} ${!presentsOpen ? styles.closed : ''}`}>
                                                            <Each of={filteredPresents.sort((a, b) => {
                                                                if (a.surname && b.surname) {
                                                                    return a.surname.localeCompare(b.surname)
                                                                }
                                                                return 0
                                                            })} render={(present, index) => {
                                                                let participant = call.participants()[present.sessionId]
                                                                if (!participant) return
                                                                return (
                                                                    <div className={styles.presentParticipant}>
                                                                        <img src={present.picture ?? UserPlaceholder} alt="" className={styles.absentAvatar} />
                                                                        <div className={styles.presentInfo}>
                                                                            <div className={styles.presentName}>{`${isSharingScreen ? present.surname[0] + '.' : present.surname} ${present.name}`}</div>
                                                                            <div className={styles.presentActions}>
                                                                                <TooltipWrapper text={pinned.includes(present.sessionId) ? "Rimuovi Fissato" : "Fissa"}>
                                                                                    <div className={`${styles.presentActionButton} ${pinned.includes(present.sessionId) ? styles.pinActive : ''}`} onClick={() => {
                                                                                        if (!pinned?.includes(present.sessionId) && pinned?.length < 4) {
                                                                                            setPinned([...pinned, present.sessionId])
                                                                                            call.setMeetingSessionData({ pinned: [...pinned, present.sessionId] }, 'shallow-merge')
                                                                                        }
                                                                                        else {
                                                                                            setPinned(pinned.filter(p => p !== present.sessionId))
                                                                                            call.setMeetingSessionData({ pinned: pinned.filter(p => p !== present.sessionId) }, 'shallow-merge')
                                                                                        }
                                                                                    }}>

                                                                                        <PinIcon className={styles.actionIcon} />

                                                                                    </div>
                                                                                </TooltipWrapper>

                                                                                {screens.find(s => s.session_id === present.sessionId) &&
                                                                                    <TooltipWrapper text="Interrompi Condivisione">
                                                                                        <div className={styles.presentActionButton} style={{ backgroundColor: 'var(--sf-green)' }} onClick={() => {
                                                                                            call.updateParticipant(present.sessionId, { setScreenShare: false })
                                                                                        }}>
                                                                                            <ScreenStopIcon className={styles.actionIcon} />
                                                                                        </div>
                                                                                    </TooltipWrapper>
                                                                                }

                                                                                <TooltipWrapper text={participant?.audio ? "Disattiva Microfono" : "Attiva Microfono"}>
                                                                                    <div className={`${styles.presentActionButton} ${!participant?.audio ? styles.micDisabled : ''}`} onClick={() => {
                                                                                        call.updateParticipant(present.sessionId, { setAudio: !(participant?.audio ?? false) })
                                                                                    }}>
                                                                                        {participant.audio ?
                                                                                            <MicIcon className={styles.actionIcon} /> : <MicOffIcon className={styles.actionIcon} />
                                                                                        }
                                                                                    </div>
                                                                                </TooltipWrapper>

                                                                                <TooltipWrapper text={participant?.video ? "Disattiva Videocamera" : "Attiva Videocamera"}>
                                                                                    <div className={`${styles.presentActionButton} ${!participant?.video ? styles.camDisabled : ''}`} onClick={() => {
                                                                                        call.updateParticipant(present.sessionId, { setVideo: !(participant?.video ?? false) })
                                                                                    }}>
                                                                                        {participant.video ?
                                                                                            <CameraIcon className={styles.actionIcon} /> : <CameraOffIcon className={styles.actionIcon} />
                                                                                        }
                                                                                    </div>
                                                                                </TooltipWrapper>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.listSection}>
                                                        <div className={`${styles.listHeader} ${!absentsOpen ? styles.closed : ''}`} onClick={() => { setAbsentsOpen(!absentsOpen) }}>
                                                            <div className={styles.listHeaderTitle}>Assenti <span>{filteredAbsents.length}</span></div>
                                                            <ChevronIcon className={`${styles.chevron} ${!absentsOpen ? styles.closed : ''}`} />
                                                        </div>
                                                        <div className={`${styles.listContent} ${!absentsOpen ? styles.closed : ''}`}>
                                                            <Each of={filteredAbsents.sort((a, b) => {
                                                                if (a.surname && b.surname) {
                                                                    return a.surname.localeCompare(b.surname)
                                                                }
                                                                return 0
                                                            })} render={(absent, index) => {
                                                                return (
                                                                    <div className={styles.absentParticipant}>
                                                                        <img src={absent.picture ?? UserPlaceholder} alt="" className={styles.absentAvatar} />
                                                                        <div className={styles.absentName}>{`${isSharingScreen ? absent.surname[0] + '.' : absent.surname} ${absent.name}`}</div>
                                                                        <Button disabled={absent.notified} appearance="default" fullWidth accentColor={'#FDB323'} onClick={async () => {
                                                                            await notifyAbsents([absent.id])
                                                                        }} style={{ padding: '.5rem 0', minWidth: '32px', width: '32px', height: '32px' }}> <NotificationIcon /> </Button>
                                                                    </div>
                                                                )
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide className={styles.tabSlide}>
                                            <div className={styles.settings}>
                                                <div className={styles.settingsInner}>
                                                    <DailySettings open={true} roomName={roomName} sideBySide={true} onLessonEnding={() => {
                                                        setLessonEnding(true)
                                                    }} />
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    </SplideTrack>
                                </Splide>

                            </div>
                        </div>
                    </div>
                    <div className={styles.section} style={{ width: '100%' }}>
                        {width > 390 &&
                            <div className={typo.subtitle} style={{ padding: '.5rem 0' }}>Visuale Studente</div>
                        }
                        {width <= 390 &&
                            <Button
                                appearance="text"
                                onClick={() => { setShowStudentView(!showStudentView) }}
                                className={width < 390 ? typo.caption : typo.subtitle}
                                style={{ padding: '.5rem 0' }}>
                                {width < 390 ? 'Visuale Studente'.toUpperCase() : 'Visuale Studente'}
                            </Button>
                        }
                        {((spotlighted && showStudentView) || (spotlighted && width > 390)) &&
                            <DailySpotlighted
                                pinned={pinned}
                                spotlighted={spotlighted}
                                onRemoveSpotlighetd={(session_id) => {
                                    setSpotlighted(spotlighted.filter(s => s.session_id !== session_id))
                                }}
                                sideBySide={true}
                            />
                        }
                    </div>
                    <AlertDialog
                        style={{ backgroundColor: '#212121' }}
                        open={lessonEnding}
                        title={`Terminare la lezione ?`}
                        text={`Tutti i partecipanti saranno rimossi. Se hai sbloccato la stanza, questa sarà bloccata nuovamente.`}
                        onClose={() => {
                            setLessonEnding(false)
                        }}
                        actions={[
                            {
                                label: "Termina",
                                accentColor: 'var(--sf-red)',
                                onClick: async () => {
                                    await lockRoom(true)
                                    let updateList = {};
                                    for (let id in call.participants()) {
                                        if (id === 'local') {
                                            continue;
                                        }
                                        updateList[id] = { eject: true };
                                    }
                                    call.updateParticipants(updateList);
                                    call.leave()
                                }
                            }
                        ]}
                    />
                    <AlertDialog
                        style={{ backgroundColor: '#212121' }}
                        open={participantToRemove}
                        title={`Rimuovere ${participantToRemove?.name} ?`}
                        text={`${participantToRemove?.name} sarà rimosso dalla lezione e avvisato. Potrà comunque rientrare immediatamente.`}
                        onClose={() => {
                            setParticipantToRemove(null)
                        }}
                        actions={[
                            {
                                label: "Rimuovi",
                                accentColor: 'var(--sf-red)',
                                onClick: async () => {
                                    call.updateParticipant(participantToRemove?.id, { eject: true })
                                    setParticipantToRemove(null)
                                }
                            }
                        ]}
                    />
                </div >
            }
            {meetingState === 'left-meeting' &&
                <div className={styles.container}>
                    <div className={styles.section} style={{ height: '100%', display: 'flex', flex: 1 }}>
                        <div className={styles.full}>
                            <div className={typo.headline} style={{ color: 'var(--background-color)' }}>La lezione è terminata. Puoi chiudere la finestra.</div>
                        </div>
                    </div>
                </div>
            }
            {meetingState === 'error' &&
                <div className={styles.container}>
                    <div className={styles.section} style={{ height: '100%', display: 'flex', flex: 1 }}>
                        <div className={styles.full}>
                            <div className={typo.headline} style={{ color: 'var(--background-color)' }}>Si è verificato un errore. Puoi chiudere la finestra e riprovare.</div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}

export default DailyRoomSide