import { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { ReactComponent as EmptyCourses } from "../assets/images/illustrations/3D/empty-courses.svg";
import { CourseType, EditionStatus } from "../common/constants";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import TeacherCourseCard from "../components/cards/TeacherCourseCard";
import TeacherCourseCardSmall from '../components/cards/TeacherCourseCardSmall';
import EmptyContent from '../components/EmptyContent';
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import LessonsCarousel from '../components/LessonsCarousel';
import Loader from "../components/Loader";
import MultiStateSwitch from "../components/MultiStateSwitch";
import typo from "../typography.module.css";
import styles from "./Courses.module.css";

const Courses = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [lessons, setLessons] = useState([]);
  const [lessonsLoading, setLessonLoading] = useState(false);
  const [editions, setEditions] = useState([]);
  const [editionsLoading, setEditionsLoading] = useState(false);
  const [filterEditions, setFilterEditions] = useState([]);
  const [filterState, setFilterState] = useState(0);

  useEffect(() => {
    const getLessons = async () => {
      setLessonLoading(true);
      try {
        let lessons = await api.get(`/teacher/lessons?ends_after=${new Date().toISOString()}`);
        setLessons(lessons.sort((a, b) => {
          return new Date(a.starts_at) - new Date(b.starts_at)
        }))
      } catch (e) {
        console.error(e);
      }
      setLessonLoading(false);
    };

    const getCourses = async () => {
      setEditionsLoading(true);
      try {
        const modules = await api.get("/teacher/modules");
        const editions = {}
        for (const module of modules) {
          const { edition, ...moduleData } = module
          if (!editions[module.edition.id]) {
            editions[module.edition.id] = module.edition
            editions[module.edition.id].modules = [moduleData]
          } else {
            editions[module.edition.id].modules.push(moduleData)
          }
        }

        setEditions(Object.values(editions));
        setFilterEditions(Object.values(editions).filter((e) => e.status === EditionStatus.Public));
        setFilterState(0)

      } catch (e) {
        console.error(e);
      }
      setEditionsLoading(false);
    };

    getLessons();
    getCourses();
  }, []);

  const handleEditionFilterChange = useCallback(
    (index) => {
      let filtered = [];
      switch (index) {
        case 0:
          filtered = editions.filter((e) => e.status === EditionStatus.Public);
          setFilterEditions(filtered);
          break;
        case 1:
          filtered = editions.filter((e) => e.course.type === CourseType.Masterclass && e.status === EditionStatus.Public);
          setFilterEditions(filtered);
          break;
        case 2:
          filtered = editions.filter((e) => e.course.type === CourseType.Professional && e.status === EditionStatus.Public);
          setFilterEditions(filtered);
          break;
        case 3:
          filtered = editions.filter((e) => [EditionStatus.Ended, EditionStatus.Locked].includes(e.status));
          setFilterEditions(filtered);
          break;
        default:
          setFilterEditions(editions);
      }
      setFilterState(index);
    },
    [editions]
  );

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>I miei corsi</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={typo.title}>{t("courses.title")}</div>

            <LessonsCarousel lessons={lessons} loading={lessonsLoading} />

            {/* ALL COURSES */}
            <MultiStateSwitch
              selected={filterState}
              states={[
                t("courses.all").toUpperCase(),
                t("courses.masterclassCourses").toUpperCase(),
                t("courses.professionalCourses").toUpperCase(),
                t("courses.archive").toUpperCase(),
              ]}
              onStateChange={handleEditionFilterChange}
            />
            {editionsLoading && (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            )}

            {!editionsLoading && (
              <>
                {filterEditions.length > 0 && (
                  <Each
                    of={filterEditions}
                    render={(edition) => {
                      return (
                        <>
                          {width <= 540 && (
                            <TeacherCourseCardSmall edition={edition} />
                          )}
                          {width > 540 && <TeacherCourseCard edition={edition} />}
                        </>
                      );
                    }}
                  />
                )}
                {filterEditions.length === 0 && (
                  <EmptyContent
                    Illustration={EmptyCourses}
                    message={
                      <>
                        {filterState === 0 && `Non hai ancora un corso in programma, contatta la segreteria per scoprire come puoi aggiungerli`}
                        {filterState === 1 && `Non hai ancora una masterclass in programma, contatta la segreteria per scoprire come puoi aggiungerla`}
                        {filterState === 2 && `Non hai ancora un corso professionalizzante in programma, contatta la segreteria per scoprire come puoi aggiungerlo`}
                        {filterState === 3 && `Non ci sono ancora corsi conculusi.`}
                      </>
                    }
                    imageStyle={{ width: "192px" }}
                    action={{
                      label: "CONTATTA",
                      onClick: () => navigate("/messages"),
                      buttonProps: {
                        style: { padding: "0.75rem 3rem" }
                      }
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </HeaderFooterLayout>
  );
};

export default Courses;
