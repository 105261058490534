import { useCallback, useMemo, useState } from "react"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { calcLastUpdate } from "../../utils"
import Card from "./Card"
import styles from "./CommunicationFeedCard.module.css"
import { useTranslation } from "react-i18next"
import AlertDialog from "../dialogs/AlertDialog"
import { DialogStatus } from "../../enums"
import api from "../../api"
import MeatBallsMenu from "../MeatBallsMenu"
import Markdown from "react-markdown"

const CommunicationFeedCard = ({ activity, onEdit = () => { }, onDelete = () => { } }) => {

    const { width } = useWindowDimensions()
    const { t } = useTranslation()

    // delete
    const [openAlert, setOpenAlert] = useState(false)
    const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
    const [closeTimeout, setCloseTimeout] = useState(null)

    const menuActions = useMemo(() => {
        if (!activity) return []

        const actions = [
            { label: t("delete"), icon: DeleteIcon, style: { color: "var(--secondary)" }, onClick: () => setOpenAlert(true) }
        ]

        return actions
    }, [activity])

    const deleteCommunication = useCallback(async () => {
        try {
            setAlertStatus(DialogStatus.Loading)
            await api.delete(`/teacher/activities/${activity.id}`)
            onDelete(activity.id)
            setAlertStatus(DialogStatus.Success)
            setCloseTimeout(setTimeout(() => {
                setOpenAlert(false)
                setAlertStatus(DialogStatus.Default)
            }, 3000))
        } catch (e) {
            console.error(e)
            setAlertStatus(DialogStatus.Error)

            setCloseTimeout(setTimeout(() => {
                setOpenAlert(false)
                setAlertStatus(DialogStatus.Default)
            }, 3000))
        }
    }, [activity])

    return (
        <>
            <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div className={styles.cardHeader}>
                    <div className={`${styles.icon} ${styles.communication}`}>
                        <img src={activity.profile.picture} alt={activity.profile.surname} />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            {activity.profile.name} {activity.profile.surname}
                        </div>
                        <div className={styles.subtitle}>
                            Comunicazione del docente
                        </div>
                    </div>
                    <div className={styles.date}>
                        {calcLastUpdate(activity.created_at, width > 768 ? false : true, true)}
                        <ClockIcon className={styles.clockIcon} />
                    </div>
                    <MeatBallsMenu actions={menuActions} />
                </div>
                <div className={styles.content}>
                    <div className={`${styles.description} ${styles.communication}`}>
                        <Markdown>
                            {activity.info.message}
                        </Markdown>
                    </div>
                </div>
            </Card>
            <AlertDialog
                open={openAlert}
                title={t("communications.deleteTitle")}
                text={t("communications.deleteText")}
                status={alertStatus}
                onClose={() => {
                    setOpenAlert(false)
                    setAlertStatus(DialogStatus.Default)
                    if (closeTimeout) {
                        clearTimeout(closeTimeout)
                    }
                }}
                actions={[
                    {
                        label: t("communications.deleteConfirm").toUpperCase(),
                        onClick: deleteCommunication,
                    }
                ]}
            />
        </>
    )
}

export default CommunicationFeedCard