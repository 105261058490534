import { useAppMessage, useScreenShare } from "@daily-co/daily-react";
import { useContext, useEffect, useRef, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/ic-download.svg";
import { ReactComponent as FileIcon } from "../../assets/images/icons/ic-file.svg";
import { ReactComponent as NewWindowIcon } from "../../assets/images/icons/ic-new-window.svg";
import { ReactComponent as SendIcon } from "../../assets/images/icons/ic-send.svg";
import { ReactComponent as TutorIcon } from "../../assets/images/icons/ic-tutor.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/illustrations/il-play-button.svg";

import { AnimatePresence, motion } from "framer-motion";
import { Each } from "../../common/Each";
import MainContext from "../../common/MainContext";
import typo from "../../typography.module.css";
import { formatFileSize, formatTimeV2, getLocaleISOString } from "../../utils";
import AlertDialog from "../dialogs/AlertDialog";
import styles from "./DailyChat.module.css";
import ReactPlayer from "react-player";
import { useParams } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import api from "../../api";
import useClickOutside from "../../common/hooks/useClickOutside";
import { DialogStatus } from "../../enums";
import Linkify from "../Linkify";
import Loader from "../Loader";
import Spinner from "../Spinner";

const DailyChat = ({ call, open, unread, onUnreadChange = () => {}, lesson_id, sounds, notifications, popup = false, token, sideBySide=false }) => {

    const { roomName } = useParams()
    const context = useContext(MainContext)
    const [message, setMessage] = useState({ sender: null, sender_type: 'teacher', created_at: null, body: '', type: null })
    const [messages, _setMessages] = useState([]);
    const messagesRef = useRef(messages)
    const setMessages = data => {
        messagesRef.current = data;
        _setMessages(data);
    }
    const [sendDisabled, setSendDisabled] = useState(true)
    const messageInputRef = useRef(null)

    const fileRef = useRef(null)
    const [file, setFile] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)
    const [fullScreenImage, setFullScreenImage] = useState(null)
    const [sending, setSending] = useState(false)
    const [alert, setAlert] = useState(false)
    const { isSharingScreen } = useScreenShare()

    const onAppMessage = (ev) => {
        if (messagesRef.current) {
            const exist = messagesRef.current.find(m => m.body === ev.data.body && m.created_at === ev.data.created_at)
            if (exist) {
                return
            }
        }
        if (ev.data.body.trim() === '' && !ev.data.media) {
            return
        }

        const newMessage = {
            ...ev.data,
            type: ev.data.sender.id === context.user.id && ev.data.sender_type === 'teacher' ? 'sent' : 'received'
        }

        setMessages([newMessage, ...messagesRef.current])
        if (!call) {
            if (!open) {
                onUnreadChange(unread + 1)
            }
            else {
                onUnreadChange(0)
            }
        }
    }

    useEffect(() => {
        if (call) {
            call.on('app-message', onAppMessage)
        }
    }, [])

    const sendAppMessage = useAppMessage({
        onAppMessage: !call ? onAppMessage : () => { console.log('Disabled onAppMessageHook') }
    });

    const handleOnChange = async (event) => {
        if (message.length > event.target.value.length) {
            setMessage((prev) => {
                prev.sender_type = 'teacher'
                prev.sender = context.user
                prev.created_at = getLocaleISOString()
                prev.body = event.target.value
                return { ...prev }
            })
            if ((event.target.value.length > 0 && event.target.value.trim().length > 0) || file) {
                setSendDisabled(false)
            }
            return
        }
        setMessage((prev) => {
            prev.sender_type = 'teacher'
            prev.sender = context.user
            prev.created_at = getLocaleISOString()
            prev.body = event.target.value

            return { ...prev }
        })
        if ((event.target.value.length > 0 && event.target.value.trim().length > 0) || file) {
            setSendDisabled(false)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            if (!sendDisabled) {
                sendMessage()
            }
            messageInputRef.current.focus()
        }
    };

    const sendMessage = async () => {
        setSending(true)
        setSendDisabled(true)
        let mediaUrl = null

        if (file) {
            let uploaded = await uploadMessageFile(file, uuidv4())
            if (uploaded && uploaded.url) {
                mediaUrl = uploaded.url
            }
        }

        let msgObj = {
            sender: message.sender ?? context.user,
            sender_type: message.sender_type ?? 'teacher',
            created_at: message.created_at ?? getLocaleISOString(),
            body: message.body,
            media: mediaUrl && file ? { url: mediaUrl, name: file.name, size: file.size } : null,
            type: 'sent'
        }

        call ? call.sendAppMessage(msgObj, '*') : sendAppMessage(msgObj, '*')

        await saveBackendMessage(lesson_id, msgObj)
        setMessages([msgObj, ...messagesRef.current])
        setMessage({ sender: null, created_at: null, body: '' })
        messageInputRef.current.focus()
        setSendDisabled(false)
        setSending(false)
        setFile(null)
        setFileUrl(null)
    }

    const saveBackendMessage = async (lesson_id, message) => {
        try {
            await api.post(`/lessons/${lesson_id}/messages`, { message })
        }
        catch (e) {
            console.error(e)
            setSendDisabled(false)
            setSending(false)
        }
    }

    useEffect(() => {
        const getLessonMessages = async (lesson_id) => {
            try {
                let lesson_messages = await api.get(`/teacher/lessons/${lesson_id}/messages`)
                let messagesData = lesson_messages.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at)
                }).map(lm => {
                    lm.message.type = lm.message.sender.id === context.user.id ? 'sent' : 'received'
                    return lm.message
                })
                setMessages(messagesData)
            }
            catch (e) {
                console.error(e)
            }
        }

        getLessonMessages(lesson_id)

    }, [])

    useEffect(() => {
        if (message) {
            if (message.body.trim().length === 0 && !file) {
                setSendDisabled(true)
            }
            else {
                setSendDisabled(false)
            }
        }
    }, [message, file])

    useEffect(() => {
        if (open && messageInputRef.current) {
            messageInputRef.current.focus()
        }
    }, [open])

    const uploadMessageFile = async (file, upload_id) => {
        const formData = new FormData();
        formData.append("upload_id", upload_id)
        formData.append("file", file, file.name);


        return await api.post("/teacher/messages/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    }

    const handleFileChange = (event) => {
        let file = event.target.files[0]
        if (file.size > 16 * 1024 * 1024) { // 16 MB
            setAlert(true)
            event.target.value = null
            return;
        }

        setFile(file)
        setFileUrl(URL.createObjectURL(file))
        setMessage((prev) => {
            prev.sender_type = 'teacher'
            prev.sender = context.user
            prev.created_at = getLocaleISOString()
            prev.body = event.target.value
            return { ...prev }
        })
        event.target.value = null
    }

    const getFileMediaType = (file) => {
        const parts = file.name.split('.');
        const extension = parts[parts.length - 1].toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'webp']
        const videoExtensions = []
        if (imageExtensions.includes(extension)) {
            return 'image'
        }
        else if (videoExtensions.includes(extension)) {
            return 'video'
        }
        else {
            return 'file'
        }
    }

    const getUrlMediaType = (url) => {
        const parts = url.split('/');
        const fileName = parts[parts.length - 1]
        const file_parts = fileName.split('.')
        const extension = file_parts[file_parts.length - 1].toLowerCase()
        const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'webp']
        const videoExtensions = []
        if (imageExtensions.includes(extension)) {
            return 'image'
        }
        else if (videoExtensions.includes(extension)) {
            return 'video'
        }
        else {
            return 'file'
        }
    }

    return (
        <div className={`${styles.container} ${(open || call || sideBySide) ? styles.open : ''} ${popup ? styles.full : ''} ${sideBySide ? styles.sideBySide : ''}`}>
            <div className={typo.subtitle}
                style={{
                    color: 'white',
                    paddingBottom: '.5rem',
                    borderBottom: '1px solid rgba(var(--background-color-rgb), 12%)',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                Chat
                {!call && !sideBySide &&
                    <NewWindowIcon style={{ cursor: 'pointer' }} onClick={() => {
                        window.open(
                            `/lessons/${roomName}/chat?token=${token}&sounds=${sounds}&notifications=${notifications}`, // URL della finestra da aprire
                            "_blank", // Apre in una nuova finestra
                            "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,width=800,height=600"
                        );
                    }} />
                }
            </div>
            <div className={styles.messages}>
                <Each of={messages} render={(message) => {
                    return (
                        <div className={`${styles.message} ${message.type === 'sent' ? styles.sent : styles.received}`}>
                            <div
                                style={{
                                    '--accent': message.sender?.role ? message.sender?.color ?? 'var(--text-color)' : 'var(--background-color)',
                                    color: message.sender?.role ? 'white' : 'black'
                                }}
                                className={`${styles.messageBody} ${message.type === 'sent' ? styles.sent : styles.received} ${message.sender.role === 'tutor' ? styles.tutor : ''}  ${message.sender.role === 'teacher' ? styles.teacher : ''} ${message.sender.role === 'main' ? styles.admin : ''}`}>
                                {message.media &&
                                    <>
                                        {getUrlMediaType(message.media?.name) === 'image' &&
                                            <ImageLoader src={message.media?.url} onClick={() => {
                                                setFullScreenImage(message.media?.url)
                                            }} />
                                        }
                                        {getUrlMediaType(message.media?.name) === 'video' &&
                                            <div className={styles.playerWrapper} style={{ maxWidth: '350px' }}>
                                                <ReactPlayer
                                                    url={message.media?.url}
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                    className={styles.player}
                                                    playIcon={<PlayIcon />}
                                                    light={true}
                                                    config={
                                                        {
                                                            youtube: {
                                                                playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }
                                        {getUrlMediaType(message.media?.name) === 'file' &&
                                            <div className={[styles.file, styles.light].join(' ')}>
                                                <div className={[styles.fileInfo, styles.light].join(' ')}>
                                                    <div className={[styles.fileName, styles.light].join(' ')}>
                                                        {message.media?.name}
                                                    </div>
                                                    <div className={[styles.fileSize, styles.light].join(' ')}>
                                                        {formatFileSize(message.media?.size)}
                                                    </div>
                                                </div>
                                                <button className={styles.downloadFile} onClick={async () => {
                                                    const outsideRes = await fetch(message.media?.url);
                                                    const blob = await outsideRes.blob();
                                                    const url = URL.createObjectURL(blob);

                                                    const link = document.createElement("a");
                                                    link.href = url;
                                                    link.download = message.media?.name;
                                                    document.body.appendChild(link);
                                                    // Simula il clic sul link
                                                    link.click();
                                                    // Rimuovi il link dal corpo del documento
                                                    document.body.removeChild(link);
                                                }}>
                                                    <DownloadIcon className={styles.downloadIcon}></DownloadIcon>
                                                </button>
                                            </div>
                                        }
                                    </>
                                }

                                <Linkify>{message.body}</Linkify>
                            </div>
                            <div className={styles.messageDate}>
                                {message.type === 'sent' &&
                                    formatTimeV2(message.created_at, null, 'it', false)
                                }
                                {message.type === 'received' &&
                                    <>
                                        <span style={{ color: message.sender?.color }}>
                                            {['teacher', 'admin'].includes(message.sender_type) && <TutorIcon className={styles.tutorIcon} />} 
                                            {message.sender?.name} {isSharingScreen ? message.sender?.surname?.[0] + '.'  : message.sender?.surname } - {formatTimeV2(message.created_at, null, 'it', false)}
                                        </span>
                                    </>
                                }
                            </div>
                        </div>
                    )
                }} />
            </div>

            <div className={styles.messageInputContainer}>
                {file &&
                    <div className={styles.innerFile}>

                        {getFileMediaType(file) === 'image' &&
                            <>
                                <button className={styles.closeFile} onClick={() => {
                                    setFile(null)
                                    setFileUrl(null)

                                }}>
                                    <CloseIcon style={{ width: '16px', height: '16px', minHeight: '16px', minWidth: '16px' }} />
                                </button>
                                <img src={fileUrl} alt="" className={styles.fileImage} />
                            </>
                        }

                        {getFileMediaType(file) === 'video' &&
                            <>
                                <button className={styles.closeFile} onClick={() => {
                                    setFile(null)
                                    setFileUrl(null)
                                }}>
                                    <CloseIcon style={{ width: '16px', height: '16px', minHeight: '16px', minWidth: '16px' }} />
                                </button>
                                <div className={styles.playerWrapper}>
                                    <ReactPlayer
                                        url={fileUrl}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                        playIcon={<PlayIcon />}
                                        light={false}
                                        config={
                                            {
                                                youtube: {
                                                    playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </>
                        }

                        {getFileMediaType(file) === 'file' &&
                            <div className={styles.file}>
                                <div className={styles.fileInfo}>
                                    <div className={styles.fileName}>
                                        {file.name}
                                    </div>
                                    <div className={styles.fileSize}>
                                        {formatFileSize(file.size)}
                                    </div>
                                </div>
                                <button className={styles.deleteFile} onClick={() => {
                                    setFile(null)
                                    setFileUrl(null)
                                }}>
                                    <CloseIcon className={styles.deleteIcon}></CloseIcon>
                                </button>
                            </div>
                        }
                    </div>
                }
                <div className={styles.innerInput}>
                    <input disabled={sending} type='file' id='file' ref={fileRef} style={{ display: 'none' }} onChange={handleFileChange} multiple={false} />
                    <button className={`${styles.fileButton} ${styles.sideBySide}`} disabled={sending} onClick={() => {
                        if (fileRef && fileRef.current) {
                            fileRef.current.click()
                        }
                    }}>
                        <FileIcon />
                    </button>
                    <TextareaAutosize
                        ref={messageInputRef}
                        value={message.body}
                        minRows={1}
                        maxRows={message.body !== '' ? 5 : 1}
                        type="text"
                        className={`${styles.messageInput} ${sideBySide ? styles.sideBySide : ''}`}
                        placeholder="messaggio..."
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown} />

                    <button className={styles.send} onClick={sendMessage} disabled={sendDisabled}>
                        {sending ? <Spinner color="white" /> : <SendIcon />}
                    </button>
                </div>
            </div>
            {fullScreenImage &&
                <FullScreenImage src={fullScreenImage} onClose={() => { setFullScreenImage(null) }} />
            }
            <AlertDialog
                open={alert}
                errorMessage="Dimensione del file troppo grande, la dimensione massima è 16Mb."
                status={DialogStatus.Error}
                onClose={() => { setAlert(false) }}
            />

        </div>
    )

}

export default DailyChat


function ImageLoader({ src, onClick }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleImageLoaded = () => {
        setIsLoading(false); // Imposta lo stato su false quando l'immagine è stata caricata
    };

    const handleLoadStart = () => {
        setIsLoading(true)
    }

    return (
        <>
            {isLoading &&
                <div className={styles.imageLoader}>
                    <Loader />
                </div>
            }
            <motion.img layoutId={src} src={src} alt="" className={styles.messageImage} onLoadStart={handleLoadStart} onLoad={handleImageLoaded} onClick={onClick} />
        </>
    );
}

const FullScreenImage = ({ src, onClose }) => {

    const imageRef = useRef(null)
    useClickOutside(imageRef, onClose)


    return (
        <div className={styles.fullScreenImageContainer}>
            <button className={styles.closeFile} onClick={onClose}>
                <CloseIcon style={{ width: '16px', height: '16px', minHeight: '16px', minWidth: '16px' }} />
            </button>
            <AnimatePresence>
                <motion.div layoutId={src} className={styles.fullImageContainer}>
                    <img ref={imageRef} src={src} alt="" className={styles.fullScreenImage} />
                </motion.div>
            </AnimatePresence>
        </div>
    )
}