import styles from "./Error.module.css"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import Button from "../components/Button"

const Error = () => {

    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.error}>
                            <div className={typo.title}>Ops, sembra ci sia stato un problema!</div>
                            <div>
                                Siamo spiacenti, sembra ci sia stato un errore.<br></br>
                            </div>
                            <a href={`mailto:support@velv.academy?subject=Segnalazione Errore&body=Salve,%0Aho ricevuto un errore utilizzando Velv.%0A%0Ascrivi qui cosa stavi facendo quando hai ottenuto l'errore...`}>
                                <Button>
                                    CONTATTA IL SUPPORTO
                                </Button>
                            </a>

                            <Button onClick={() => { 
                                localStorage.clear()
                                window.location.href = '/login' 
                            }}>
                                TORNA ALLA HOME
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )
}

export default Error