import styles from "./Slider.module.css";

const Slider = ({ level, icon, onChange = () => { } }) => {


    const handleChange = (event) => {
        event.stopPropagation()
        let value = event.target.value
        onChange(value)
    }

    return (
        <label className={styles.slider} onClick={(e) => { e.stopPropagation() }}>
            <input type="range" className={styles.level} onChange={handleChange} value={level} />
            <div className={styles.icon}>
                {icon}
            </div>
        </label>

    )

}

export default Slider