import { useDaily, useDailyError, useDevices, useMediaTrack, useMeetingSessionState, useParticipantProperty, useRoom, useScreenShare, useWaitingParticipants } from "@daily-co/daily-react";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as ListIcon } from "../../assets/images/icons/ic-list.svg";
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg";
import { ReactComponent as ChatIconOff } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg";
import { ReactComponent as WhiteboardIcon } from "../../assets/images/icons/ic-hand-draw.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/images/icons/ic-screen-maximize.svg";
import { ReactComponent as FullScreenOffIcon } from "../../assets/images/icons/ic-screen-minimize.svg";
import { ReactComponent as ScreenIcon } from "../../assets/images/icons/ic-screenshare.svg";
import { ReactComponent as ScreenOffIcon } from "../../assets/images/icons/ic-screenshare-stop.svg";
import { ReactComponent as GearIcon } from "../../assets/images/icons/ic-settings.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/ic-users.svg";
import { ReactComponent as DocumentIcon } from "../../assets/images/icons/ic-document.svg";
import { ReactComponent as SpeakerIcon } from "../../assets/images/icons/ic-speaker.svg"
import { ReactComponent as SpeakerIcon0 } from "../../assets/images/icons/ic-speaker0.svg"
import { ReactComponent as ScreenAudioIcon } from "../../assets/images/icons/ic-screen-audio.svg"
import { ReactComponent as ScreenAdioOffIcon } from "../../assets/images/icons/ic-screen-audio-off.svg"

import { Each } from "../../common/Each";
import useSpeechDetection from "../../common/hooks/useSpeechDetection";
import { DialogStatus } from "../../enums";
import AlertDialog from "../dialogs/AlertDialog";
import DailyControl from "./DailyControl";
import styles from "./DailyControls.module.css";
import DailySpinner from "./DailySpinner";
import { isMobile } from "react-device-detect";
import MainContext from "../../common/MainContext";
import api from "../../api";
import { UAParser } from "ua-parser-js";


const DailyControls = ({
    localSessionId,
    showParticipants,
    onShowParticipantsChange,
    fullScreen,
    onFullScreenChange,
    whiteboard,
    onWhiteboardChange,
    chat,
    onChatChange,
    unread,
    handsRaised,
    overlayMenu,
    onOverlayMenuChange,
    pdf,
    onPdfChange,
    muted,
    onMutedChange,
    hiddenControls = [] }) => {

    const context = useContext(MainContext)
    const call = useDaily()
    const localParticipant = useParticipantProperty(localSessionId, ['audio', 'video', 'user_name', 'userData', 'audioTrack', 'tracks'])
    const devices = useDevices()
    const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare()
    const audioTrack = useMediaTrack(localSessionId, 'audio')
    const [loadingMic, setLoadingMic] = useState(null)
    const [loadingCam, setLoadingCam] = useState(null)
    const [loadingSpeaker, setLoadingSpeaker] = useState(null)
    const { waitingParticipants } = useWaitingParticipants()

    const audioLevel = useSpeechDetection(audioTrack.track)
    const [permissionError, setPermissionError] = useState({ show: false, title: 'Errore', message: '' })
    const { nonFatalError, meetingError } = useDailyError();
    const { data: sessionData } = useMeetingSessionState()
    const [screenSharingRequests, setScreenSharingRequests] = useState(0)

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .catch(error => {
                console.error(error)
            })
    }, [])

    useEffect(() => {

        if (meetingError || nonFatalError) {
            console.log('meetingError:', meetingError)
            console.log('nonFatalError:', nonFatalError)
            saveError(meetingError ?? nonFatalError, JSON.stringify(meetingError ?? nonFatalError, null, 2))
        }

        if (nonFatalError && nonFatalError.type) {
            console.log('nonFatalError:', nonFatalError)
            if (nonFatalError.type === 'screen-share-error') {
                setPermissionError({ show: true, title: 'Permessi Condivisione Schermo', message: `Sembra che tu non abbia acconsentito alla condivisione schermo. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            }
        }
    }, [meetingError, nonFatalError])

    useEffect(() => {
        let count = 0
        if (sessionData) {
            if (sessionData.screenSharingRequests) {
                for (const key in sessionData.screenSharingRequests) {
                    if (sessionData.screenSharingRequests[key] === 'pending') {
                        count++
                    }
                }
            }
        }
        else {
            let sessionState = call.meetingSessionState()
            if (sessionState.data) {
                if (sessionState.data.screenSharingRequests) {
                    for (const key in sessionState.data.screenSharingRequests) {
                        if (sessionState.data.screenSharingRequests[key] === 'pending') {
                            count++
                        }
                    }
                }
            }
        }
        setScreenSharingRequests(count)
    }, [sessionData])

    useEffect(() => {
        if (devices.cameras.length > 0 && !devices.currentCam) {
            devices.setCamera(devices.cameras[0].device.deviceId)
        }
        if (devices.speakers.length > 0 && !devices.currentSpeaker) {
            devices.setSpeaker(devices.speakers[0].device.deviceId)

        }
        if (devices.microphones.length > 0 && !devices.currentMic) {
            devices.setMicrophone(devices.microphones[0].device.deviceId)
        }
    }, [devices.cameras, devices.speakers, devices.microphones])

    async function checkPermissions(type) {
        try {
            if (!['camera', 'microphone'].includes(type)) {
                throw new Error("Wrong permission type.")
            }

            const permission = await navigator.permissions.query({ name: type });
            const isGranted = permission.state === 'granted';

            if (!isGranted) {
                await requestPermissions()
            }

        } catch (error) {
            console.error('Error checking permissions:', error);
            await saveError(error.message, JSON.stringify(error, null, 2))
        }
    }

    async function requestPermissions() {
        try {
            // Richiede l'accesso alla fotocamera e al microfono
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            console.log('Permessi concessi per fotocamera e microfono');
        } catch (error) {
            console.error('Permessi negati o errore nel richiedere i permessi:', error);
            setPermissionError({ show: true, title: 'Permessi Fotocamera e Microfono', message: `Sembra che tu non abbia acconsentito l'accesso alla fotocamera e al microfono. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            await saveError(error.message, JSON.stringify(error, null, 2))
            throw error
        }
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        onPdfChange(file)
        let fileInput = document.querySelector('#pdf-input')
        document.body.removeChild(fileInput);
    };

    const saveError = async (message, stack) => {

        const parser = new UAParser();
        const result = parser.getResult();

        const errorDetails = {
            message: message,
            stack: stack,
            user_id: context.user ? context.user.id : null, // Se non loggato, usa "Guest"
            user_name: context.user ? `${context.user.name} ${context.user.surname}` : null,
            platform: "teachers",
            page: window.location.pathname,
            device: result
        };

        try {
            await api.post("/error", errorDetails);
        } catch (err) {
            console.error("Failed to send error report:", err);
        }
    }

    return (
        <>
            <div className={styles.container}>
                {!hiddenControls.includes('microphones') &&
                    <DailyControl
                        id="microphones"
                        active={localParticipant[0]}
                        icons={{
                            normal: <MicOffIcon className={styles.toolIcon} />,
                            active: <MicIcon className={styles.toolIcon} />
                        }}
                        onClick={() => {
                            checkPermissions('microphone')
                            if (devices.cameras.length > 0) {
                                call.setLocalAudio(!localParticipant[0])
                            }
                            else {
                                context.setError({ title: 'Nessun Microfono', message: 'Non è stata trovato alcun microfono. Assicurati che sia collegato e funzionante.' })
                            }
                        }}
                        hasOption={devices.microphones.length > 1}
                        level={localParticipant[0] ? audioLevel : 0}
                    >
                        <div className={styles.devices}>
                            <Each of={devices.microphones} render={(microphone) => (
                                <div className={styles.device} onClick={async (e) => {
                                    e.stopPropagation()
                                    setLoadingMic(microphone.device.deviceId)
                                    await devices.setMicrophone(microphone.device.deviceId)
                                    setLoadingMic(null)
                                }}>
                                    <div className={styles.deviceSelected}>
                                        {microphone.selected &&
                                            <CheckIcon style={{ width: '12px', height: '12px' }} />
                                        }
                                        {loadingMic === microphone.device.deviceId &&
                                            <DailySpinner />
                                        }
                                    </div>
                                    <div className={styles.deviceLabel}>
                                        {microphone.device.label}
                                    </div>
                                </div>
                            )} />
                        </div>
                    </DailyControl>
                }

                {!hiddenControls.includes('cameras') &&
                    <DailyControl
                        id="cameras"
                        active={localParticipant[1]}
                        icons={{
                            normal: <CameraOffIcon className={styles.toolIcon} />,
                            active: <CameraIcon className={styles.toolIcon} />
                        }}
                        onClick={() => {
                            checkPermissions('camera')
                            if (devices.cameras.length > 0) {
                                call.setLocalVideo(!localParticipant[1])
                            }
                            else {
                                context.setError({ title: 'Nessuna Videocamera', message: 'Non è stata trovata alcuna videocamera. Assicurati che sia collegata e funzionante.' })
                            }
                        }}
                        hasOption={devices.cameras.length > 1}
                    >
                        <div className={styles.devices}>
                            <Each of={devices.cameras} render={(camera) => (
                                <div className={styles.device} onClick={async (e) => {
                                    e.stopPropagation()
                                    setLoadingCam(camera.device.deviceId)
                                    await devices.setCamera(camera.device.deviceId)
                                    setLoadingCam(null)
                                }}>
                                    <div className={styles.deviceSelected}>
                                        {camera.selected &&
                                            <CheckIcon style={{ width: '12px', height: '12px' }} />
                                        }
                                        {loadingCam === camera.device.deviceId &&
                                            <DailySpinner />
                                        }
                                    </div>
                                    <div className={styles.deviceLabel}>
                                        {camera.device.label}
                                    </div>
                                </div>
                            )} />
                        </div>
                    </DailyControl>
                }

                {!hiddenControls.includes('speakers') &&
                    <DailyControl
                        id="speakers"
                        active={!muted}
                        icons={{
                            normal: <SpeakerIcon0 className={styles.toolIcon} />,
                            active: <SpeakerIcon className={styles.toolIcon} style={{ color: 'var(--primary)' }} />
                        }}
                        onClick={() => {
                            onMutedChange(!muted)
                        }}
                        hasOption={devices.speakers.length > 1}
                    >
                        <div className={styles.devices}>
                            <Each of={devices.speakers} render={(speaker) => (
                                <div className={styles.device} onClick={async (e) => {
                                    e.stopPropagation()
                                    setLoadingSpeaker(speaker.device.deviceId)
                                    await devices.setSpeaker(speaker.device.deviceId)

                                    setLoadingSpeaker(null)
                                }}>
                                    <div className={styles.deviceSelected}>
                                        {speaker.selected && !loadingSpeaker &&
                                            <CheckIcon style={{ width: '12px', height: '12px' }} />
                                        }
                                        {loadingSpeaker === speaker.device.deviceId &&
                                            <DailySpinner />
                                        }
                                    </div>
                                    <div className={styles.deviceLabel}>
                                        {speaker.device.label}
                                    </div>
                                </div>
                            )} />
                        </div>
                    </DailyControl>
                }

                {call.meetingState() === "joined-meeting" &&
                    <>
                        {!hiddenControls.includes('participants') &&
                            <DailyControl
                                active={false}
                                icons={{
                                    normal: <UsersIcon className={`${styles.toolIcon}`} />,
                                    active: <UsersIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    onShowParticipantsChange()
                                }}
                                tooltip={'Utenti'}
                                badgeNumber={handsRaised}
                            />
                        }

                        {/* {waitingParticipants.length > 0 && */}
                        {!hiddenControls.includes('list') &&
                            <DailyControl
                                active={overlayMenu.open && overlayMenu.mode === 'list'}
                                icons={{
                                    normal: <ListIcon className={`${styles.toolIcon}`} />,
                                    active: <ListIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    onOverlayMenuChange('list')
                                }}
                                tooltip={`Lista`}
                                badgeNumber={waitingParticipants.length + screenSharingRequests}
                            />
                        }
                        {/* } */}

                        {!hiddenControls.includes('chat') &&
                            <DailyControl
                                active={chat}
                                icons={{
                                    normal: <ChatIconOff className={`${styles.toolIcon}`} />,
                                    active: <ChatIconOff className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    onChatChange()
                                }}
                                tooltip="Chat"
                                badgeNumber={unread}
                            />
                        }

                        {!isMobile &&
                            <>
                                {!hiddenControls.includes('screenshare') &&
                                    <DailyControl
                                        id="screenshare"
                                        active={localParticipant[5]?.screenVideo.state === 'playable'}
                                        icons={{
                                            normal: <ScreenIcon className={`${styles.toolIcon}`} />,
                                            active: <ScreenOffIcon className={`${styles.toolIcon} ${styles.active}`} />
                                        }}
                                        onClick={() => {
                                            localParticipant[5]?.screenVideo.state === 'playable' ? stopScreenShare() : startScreenShare({ screenAudio: true })
                                        }}
                                        // tooltip={isSharingScreen ? "Interrompi condivisione" : "Condividi schermo"}
                                        hasOption={localParticipant[5]?.screenVideo.state === 'playable'}
                                    >
                                        <div className={styles.toggleOption}>
                                            {!['off', 'playable'].includes(localParticipant[5]?.screenAudio.state) &&
                                                <div className={styles.option}>
                                                    Nessuna fonte audio<br></br>
                                                    da condividere.
                                                </div>
                                            }
                                            {localParticipant[5]?.screenAudio.state === "off" &&
                                                <div className={styles.option} onClick={() => {
                                                    call.updateScreenShare({ screenAudio: { enabled: true } })
                                                }}>
                                                    <ScreenAudioIcon />
                                                    Condividi Audio
                                                </div>
                                            }
                                            {localParticipant[5]?.screenAudio.state === 'playable' &&
                                                <div className={styles.option} onClick={() => {
                                                    call.updateScreenShare({ screenAudio: { enabled: false } })
                                                }}>
                                                    <ScreenAdioOffIcon style={{ color: '#F43A3A' }} />
                                                    Interrompi Audio
                                                </div>
                                            }
                                        </div>
                                    </DailyControl>
                                }
                            </>
                        }

                        {/* <DailyControl
                            active={whiteboard}
                            icons={{
                                normal: <WhiteboardIcon className={`${styles.toolIcon}`} />,
                                active: <WhiteboardIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={async () => {
                                onWhiteboardChange()
                            }}
                            tooltip={whiteboard ? "Chiudi" : 'Lavagna'}
                        /> */}

                        {!isMobile && !hiddenControls.includes('fullscreen') &&
                            <DailyControl
                                active={fullScreen}
                                icons={{
                                    normal: <FullScreenIcon className={`${styles.toolIcon}`} />,
                                    active: <FullScreenOffIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    onFullScreenChange()
                                }}
                                tooltip={fullScreen ? "Riduci" : "Espandi"}
                            />
                        }

                        {!hiddenControls.includes('pdf') &&
                            <DailyControl
                                active={pdf}
                                icons={{
                                    normal: <DocumentIcon className={`${styles.toolIcon}`} />,
                                    active: <DocumentIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={async () => {
                                    if (pdf) {
                                        onPdfChange(null)
                                        call.stopScreenShare()
                                    }
                                    else {
                                        const input = document.createElement('input');
                                        input.type = 'file';
                                        input.id = "pdf-input"
                                        input.style.display = 'none';
                                        input.accept = 'application/pdf';
                                        input.addEventListener('change', handleFileInputChange);
                                        document.body.appendChild(input);
                                        input.click();
                                    }
                                }}
                                tooltip={pdf ? "Chiudi" : 'PDF'}
                            />
                        }

                        {!hiddenControls.includes('settings') &&
                            <DailyControl
                                active={overlayMenu.open && overlayMenu.mode === 'settings'}
                                icons={{
                                    normal: <GearIcon className={`${styles.toolIcon}`} />,
                                    active: <GearIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => { onOverlayMenuChange('settings') }}
                                tooltip={'Impostazioni'}
                            />
                        }
                    </>
                }
            </div>

            <AlertDialog
                open={permissionError.show}
                errorMessage={permissionError.title}
                text={permissionError.message}
                status={DialogStatus.Error}
                onClose={() => {
                    setPermissionError({ show: false, title: 'Errore', message: '' })
                }}
            />
        </>
    )

}

export default DailyControls