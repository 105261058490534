
import { useCallback, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { v4 } from "uuid"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as EmptyMaterials } from "../assets/images/illustrations/3D/empty-materials.svg"
import { ReactComponent as InsertDocumentIllustration } from "../assets/images/illustrations/il-insert-document.svg"
import { Each } from "../common/Each"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import MaterialCard from "../components/cards/MaterialCard"
import Dialog from "../components/dialogs/Dialog"
import DropdownSelection from "../components/DropdownSelection"
import EmptyContent from "../components/EmptyContent"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Loader from "../components/Loader"
import MaterialButton from "../components/MaterialButton"
import Skeleton from "../components/Skeleton"
import TextInput from "../components/TextInput"
import typo from "../typography.module.css"
import styles from "./Materials.module.css"

const Materials = () => {

  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const navigate = useNavigate()

  const [showAddDialog, setShowAddDialog] = useState(false)
  const [materials, setMaterials] = useState([])
  const [filteredMaterials, setFilteredMaterials] = useState([])
  const [materialsPerModules, setMaterialsPerModules] = useState([])
  const [modules, setModules] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModules, setLoadingModules] = useState(false)
  const [waitingAdd, setWaitingAdd] = useState(false)
  const [lessons, setLessons] = useState([])
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedLesson, setSelectedLesson] = useState(null)
  const [files, setFiles] = useState([])
  const [disableAdd, setDisableAdd] = useState(true)

  const dropzoneRef = useRef(null)

  useEffect(() => {
    const getMaterials = async () => {
      setLoading(true)
      try {
        const materials = await api.get("/teacher/materials");
        setMaterials(materials)
        setFilteredMaterials(materials)
      } catch (e) {
        console.error(e);
      }
      setLoading(false)
    };

    const getLessons = async () => {
      setLoadingModules(true)
      try {
        const lessons = await api.get("/teacher/lessons");
        const lessonsPerModule = {}

        // group lessons by moduleId
        for (const lesson of lessons) {
          if (lesson.module.id in lessonsPerModule) {
            lessonsPerModule[lesson.module.id].lessons.push(lesson)
          } else {
            lessonsPerModule[lesson.module.id] = {
              id: lesson.module.id,
              label: lesson.module.name,
              subtitle: `${lesson.edition?.course?.name} - ${lesson.edition?.name}`,
              lessons: [lesson]
            }
          }
        }
        const modules = Object.values(lessonsPerModule)
        setModules(modules)
      } catch (e) {
        console.error(e);
      }
      setLoadingModules(false)
    }
    getMaterials()
    getLessons()
  }, []);

  useEffect(() => {
    const mpm = {}
    for (const material of filteredMaterials) {
      const { module } = material;

      if (module.id in mpm) {
        mpm[module.id].materials.push(material)
      } else {
        mpm[module.id] = {
          moduleId: module.id,
          moduleName: module.name,
          courseName: module.course?.name,
          editionName: module.edition?.name,
          materials: [material]
        }
      }
    }
    const flattedMaterials = Object.values(mpm)
    setMaterialsPerModules(flattedMaterials)
  }, [filteredMaterials])

  useEffect(() => {
    if (selectedModule >= 0 && modules[selectedModule]) {
      const { lessons } = modules[selectedModule]
      setLessons(lessons.map((l) => {
        return {
          ...l,
          label: l.name
        }
      }))
    }
  }, [selectedModule])

  useEffect(() => {
    setDisableAdd(selectedModule === null || files.length === 0 || waitingAdd === true)
  }, [selectedModule, files, waitingAdd])

  useEffect(() => {
    if (!showAddDialog) {
      setSelectedModule(null)
      setSelectedLesson(null)
      setLessons([])
      setFiles([])
    }
  }, [showAddDialog])

  const onSearch = useCallback((text) => {
    if (materials && materials.length > 0) {
      if (text) {
        const filter = materials.filter((s) => {
          const target = `${s.name}`.toLowerCase().replaceAll(" ", "");
          const query = text ? text.toLowerCase().replaceAll(" ", "") : text
          return target.includes(query);
        });
        setFilteredMaterials(filter);
      } else {
        setFilteredMaterials(materials);
      }
    }
  }, [materials])

  const addMaterial = useCallback(() => {
    setWaitingAdd(true)
    const form = new FormData()
    if (selectedLesson !== null) {
      form.append("lesson_id", lessons[selectedLesson].id)
    }
    form.append("module_id", modules[selectedModule].id)

    for (let i = 0; i < files.length; i++) {
      form.append(`files`, files[i])
    }

    setLoading(true)
    api.post("teacher/materials", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      const { materials } = response
      setShowAddDialog(false)
      setMaterials((prev) => {
        return [...prev, ...materials]
      })
      setFilteredMaterials((prev) => {
        return [...prev, ...materials]
      })
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      setLoading(false)
      setWaitingAdd(false)
    })

  }, [files, selectedModule, selectedLesson, modules, lessons])


  const onDelete = useCallback((materialId) => {
    setLoading(true)
    const removeIdx = materials.findIndex((m) => m.id === materialId)
    if (removeIdx === -1) {
      console.error("Cannot find material")
      return;
    }
    materials.splice(removeIdx, 1)
    setMaterials([...materials])
    setLoading(false)
  }, [materials])

  const onEdit = useCallback((material) => {
    setLoading(true)
    const { id } = material
    const removeIdx = materials.findIndex((m) => m.id === id)
    if (removeIdx === -1) {
      console.error("Cannot find material")
      return;
    }
    materials.splice(removeIdx, 1)
    materials.push(material)
    setMaterials([...materials])
    setLoading(false)
  }, [materials])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Materiali didattici</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={typo.title}>{t("materials.title")}</div>


                <div className={styles.add}>
                  <Button
                    disabled={loading}
                    style={{
                      padding: "1rem 2rem",
                    }}
                    onClick={() => setShowAddDialog(true)}
                  >
                    {t("materials.add").toUpperCase()}
                    <AddIcon />
                  </Button>
                </div>
              </div>
              <TextInput
                onKeyUp={onSearch}
                type="search"
                placeholder={"Ricerca"}
                style={{
                  backgroundColor: "var(--background-secondary-color)",
                }} />
              {
                loading &&
                <Card>
                  <div className={styles.module}>
                    <Skeleton type="rect" width={"256px"} height={"32px"} borderRadius={"8px"} />
                    <Each
                      of={[1, 2, 3]}
                      render={() => (
                        <MaterialCard loading={true} />
                      )}
                    />
                  </div>
                </Card>
              }

              {
                loading === false &&
                <Each
                  of={materialsPerModules}
                  render={(moduleMaterials) => (
                    <Card>
                      <div className={styles.module}>
                        <div className={styles.moduleTitle}>
                          <div className={typo.subheadline}>{moduleMaterials.moduleName}</div>
                          <div className={typo.headline}>{moduleMaterials.courseName} - {moduleMaterials.editionName}</div>
                        </div>
                        <Each
                          of={moduleMaterials.materials}
                          render={(material) => (
                            <MaterialCard modules={modules} material={material} onDelete={onDelete} onEdit={onEdit} loadingModules={loadingModules} />
                          )}
                        />
                      </div>
                    </Card>
                  )

                  }
                />
              }

              {
                loading === false && materialsPerModules.length === 0 &&
                <EmptyContent
                  Illustration={EmptyMaterials}
                  message="Non ci sono ancora materiali didattici, aggiungine uno per cominciare!"
                  imageStyle={{ width: "192px" }}
                />
              }
              <Dialog
                open={showAddDialog}
                title={t("materials.addFile")}
                style={{ maxWidth: "512px" }}
                onClose={() => setShowAddDialog(false)}
                action={
                  <Button onClick={addMaterial} disabled={disableAdd}>
                    {t("materials.add").toUpperCase()}
                  </Button>
                }
              >
                <div className={styles.dialog}>
                  <DropdownSelection
                    disabled={modules.length === 0 || waitingAdd}
                    placeholder={"Associa un modulo"}
                    defaultOptionIndex={selectedModule}
                    options={modules}
                    appereance="transparent"
                    onSelect={(index) => {
                      setSelectedModule(index)
                      setSelectedLesson(null)
                    }} />

                  <DropdownSelection
                    disabled={lessons.length === 0 || waitingAdd}
                    placeholder={"Associa una lezione"}
                    defaultOptionIndex={selectedLesson}
                    options={lessons}
                    appereance="transparent"
                    onSelect={(index) => setSelectedLesson(index)} />

                  <div className={styles.dropzoneContainer}>
                    <div className={styles.subtitle} >
                      Materiali
                      {files.length > 0 &&
                        <AddIcon style={{ cursor: "pointer", color: "var(--tertiary)" }} onClick={() => {
                          if (dropzoneRef && dropzoneRef.current) {
                            dropzoneRef.current.click();
                          }
                        }} />
                      }
                    </div>
                    {
                      files.length > 0 && waitingAdd === false &&
                      <div className={styles.filesContainer}>
                        <div className={styles.files}>
                          <Each
                            of={files}
                            render={(file) => (
                              <div className={styles.file}>
                                <MaterialButton
                                  material={file}
                                  onClick={() => {
                                    setFiles((prevFiles) => {
                                      const idx = prevFiles.findIndex(f => f.id === file.id)
                                      if (idx > -1) prevFiles.splice(idx, 1)
                                      return [...prevFiles]
                                    })
                                  }}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    }
                    <div style={{
                      display: files.length === 0 && waitingAdd === false ? "flex" : "none",
                    }}>
                      <Dropzone
                        onDrop={(newFiles) => {
                          newFiles.forEach(f => f.id = v4())
                          setFiles([...files, ...newFiles])
                        }}>
                        {({ getRootProps, getInputProps }) => (
                          <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                            <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                              <input {...getInputProps()} />
                              <div className={styles.dropzone}>
                                <InsertDocumentIllustration />
                                <div className={styles.dropzoneLabel}>
                                  {t("materials.dragAndDrop")}
                                </div>
                                <Button
                                  style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                  additionalClass={styles.blueButton}
                                  onClick={getInputProps.onClick}
                                >
                                  {t("materials.selectFile").toUpperCase()}
                                </Button>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    {
                      waitingAdd === true &&
                      <div className={styles.loader}>
                        <Loader />
                      </div>
                    }
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>

    </HeaderFooterLayout>
  )
}

export default Materials
